import api from "../../../service/api";

const getPatientLedgerList = async (practiceId, pageSize, page) => {
  let path = `patient/patients-ledger-list/?page_size=${pageSize}&page=${page}&practice_pk=${practiceId}&payment_plan=true`;
  return api.get(path);
};
const getPatientLedgerListById = async (
  practiceId,
  patientId,
  pageSize,
  page
) => {
  let path = `patient/patients-ledger-list/?page_size=${pageSize}&page=${page}&practice_pk=${practiceId}&patient_pk=${patientId}`;
  return api.get(path);
};
const getPaymentPlansSummary = async (patientId) => {
  let path = `payments/payment-plans-summary/?patient_pk=${patientId}`;
  return api.get(path);
};
const getPatientProcedures = async (patientId, practiceId) => {
  let path = `payments/patient-procedures/?patient_pk=${patientId}&practice_pk=${practiceId}`;
  return api.get(path);
};
const getPaymentPlanById = async (paymentId) => {
  let path = `payments/payment-plan-detail/${paymentId}`;
  return api.get(path);
};
const deletePaymentPlan = async (paymentId) => {
  let path = `payments/payment-plan-detail/${paymentId}`;
  return  api.destroy(path);
};
const updatePaymentPlan = async (paymentId, body) => {
  let path = `payments/payment-plan-detail/${paymentId}`;
  return api.put(path, body);
};

const generateInstallments = async (data) => {
  let path = `payments/generate-installment/`;
  return api.post(path, data);
};

const generatePaymentPlan = async (data) => {
  let path = `payments/generate-payment-plan/`;
  return api.post(path, data);
};

export default {
  getPatientLedgerList,
  getPatientLedgerListById,
  getPaymentPlansSummary,
  getPatientProcedures,
  generateInstallments,
  generatePaymentPlan,
  getPaymentPlanById,
  deletePaymentPlan,
  updatePaymentPlan,
};
