import React, { useState, useEffect, useRef, memo } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import './style.css';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const NavItems = () => {
	const history = useHistory();
	const location = useLocation();
	const theme = useTheme();
	const [moduleMenuSet, setModuleMenuSet] = useState([]);
	const isAdminModule = JSON.parse(localStorage.getItem("isAdminModule"));
	const [isPopoverOpen, setIsPopoverOpen] = useState(false);
	const [isPopoverOpenMore, setIsPopoverOpenMore] = useState(false);
	const [moreMenuList, setMoreMenuList] = useState([]);
	const [mainBarSelected, setMainBarSelected] = useState('');
	const [subMenu, setSubMenu] = useState([]);
	const popoverRef = useRef(null);
	const popoverMoreRef = useRef(null);
	const buttonRefs = useRef([]);
	const [activeItem, setActiveItem] = useState('');
	const [activeItemMore, setActiveItemMore] = useState('');
	const [popoverPosition, setPopoverPosition] = useState({ top: 0, left: 0 });
	const [popoverMorePosition, setPopoverMorePosition] = useState({ top: 0, left: 0 });
	const [currentIndex, setCurrentIndex] = useState(8);
	const isXLargeScreen = useMediaQuery(theme.breakpoints.up('xl'));
	const isLargeScreen = useMediaQuery(theme.breakpoints.between('lg', 'xl'));
	const isMediumScreen = useMediaQuery(theme.breakpoints.between('md', 'lg'));
	const isSmallScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));
	const miniScreen = useMediaQuery(theme.breakpoints.down('sm'));
	const [closeTimeout, setCloseTimout] = useState(null);
	/**
	 * It is the mouse over function to show the sub_menu of the main navigation on the top bar
	 * if main navigation item contains the sub_menu it will open a div that contains sub_menu list by a boolen value
	 * @param {*} event //the event param here used to get the current position of the button that hovered then only the pop up will come center of the button
	 * @param {*} page //page contains data of hovered nav item such as name and sub_menu list if it has a sub_menu
	 */
	const handleMouseOverNavItem = (event, page) => {
		clearPopoverTimeout();
		const buttonRect = event.currentTarget.getBoundingClientRect();
		let topPosition = buttonRect.bottom + 25;

		if (window.innerHeight - topPosition < 300) {
			topPosition = window.innerHeight - 300 - 10;
		}
		//setting position of the pop over with button position
		setPopoverPosition({
			top: topPosition,
			left: buttonRect.left
		});
		//if sub_menu exist storing the sub_menu list and opening the pop over by setting the boolen state @setIsPopoverOpen
		//else case on mouse over the nav contains no submenu resetting the already store sub_menu and closing the pop over using the boolen state @setIsPopoverOpen
		if (page?.sub_menu?.length > 0) {
			setSubMenu(page?.sub_menu)
			setIsPopoverOpen(true);
			setIsPopoverOpenMore(false);
			resetPopoverTimeout();
		} else {
			setSubMenu([])
			setIsPopoverOpen(false);
			setIsPopoverOpenMore(false);
		}
	};

	/**
	 * This function is for getting clicked sub_menu item 
	 * @param {*} item // item contains clicked nav item such as claim or patient and also route to go
	 * here we check current location and route in the item object is the same if the same it will do nothing if it is not it will route to new page
	 */
	const handleItemSubMenuClick = (item) => {
		//checking route is not the current
		const isDifferentRoute = location.pathname !== item.route;
		setIsPopoverOpen(false);
		setIsPopoverOpenMore(false)
		//is isDiffrent return true rerouting to new page
		if (isDifferentRoute) {
			history.push(item.route);
		}
	};

	/**
	 * In this function we are controlling the more item navigation that is created if the menu list more than 7
	 * it is the hover function to show the remaining menu or nav list after the showing the 7 nav list
	 * @param {*} event //the event param here used to get the current position of the button that hovered then only the pop up will come center of the button
	 * @param {*} item //page contains data of hovered nav item such as name and sub_menu list if it has a sub_menu
	 */
	const handleMoreItemHover = (event, item) => {
		clearPopoverTimeout();
		if (item?.sub_menu?.length > 0) {
			const buttonRect = event.currentTarget.getBoundingClientRect();
			//setting position of the pop over with button position
			setPopoverPosition({
				top: buttonRect.bottom - 20,
				left: (buttonRect.right + 23)
			});
			//if sub_menu exist storing the sub_menu list and opening the pop over by setting the boolen state @setIsPopoverOpen
			setSubMenu(item?.sub_menu)
			setIsPopoverOpen(true);
			resetPopoverTimeout();
		}
		//else case on mouse over the nav contains no submenu resetting the already store sub_menu and closing the pop over using the boolen state @setIsPopoverOpen
		else {
			setSubMenu([])
			setIsPopoverOpen(false);
		}
	}

	/**
	 * this function is used to listen the click of navigation item in the More main nav if it is has no sub_menu
	 * @param {*} item //item contains nav item clicked inside more
	 */
	const handleItemClickMore = (item) => {
		if (item?.sub_menu?.length === 0) {
			//closing the pop over for showing more list after clicking 
			setIsPopoverOpenMore(false);
			const isDifferentRoute = location.pathname !== item.route;
			if (isDifferentRoute) {
				history.push(item.route);
			}
		}
	}

	const handleMouseOverMore = (event) => {
		const buttonRect = event.currentTarget.getBoundingClientRect();
		setPopoverMorePosition({
			top: buttonRect.bottom + 25,
			left: buttonRect.left
		});
		setIsPopoverOpenMore(true);
		setIsPopoverOpen(false);
		if (currentIndex === -1) {
			setMoreMenuList(moduleMenuSet);
		} else {
			const MoreItemList = moduleMenuSet.slice(currentIndex);
			setMoreMenuList(MoreItemList)
		}
		resetPopoverTimeout();
	}

	/**
	 * This function is used to getting clicked item if the item contain no sub_menu
	 * @param {*} event 
	 * @param {*} page 
	 */
	const handleNavItemClick = (event, page) => {
		if (page?.sub_menu?.length === 0) {
			setMainBarSelected(page.name)
			const isDifferentRoute = location.pathname !== page.route;
			if (isDifferentRoute) {
				history.push(page.route);
			}
		}
		else {
			setSubMenu(page?.sub_menu)
			setIsPopoverOpen(true);
			setIsPopoverOpenMore(false);
		}
	}

	useEffect(() => {
		if (isAdminModule == false) {
			setModuleMenuSet(JSON.parse(localStorage.getItem('practiceModuleMenuSet') || "[]"));
		} else {
			setModuleMenuSet(JSON.parse(localStorage.getItem('adminModuleMenuSet') || "[]"));
		}

		//this is the listener for listen clicking out side from the nav bar or pop over
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [])

	/**
	 * This use effect will adjust or make popover scrollable when ever the popOver bottom exceed screen height or go beyond screen height
	 */
	useEffect(() => {
		const popoverEl = popoverRef.current;
		if (popoverEl) {
			const { bottom } = popoverEl.getBoundingClientRect();
			if (bottom > window.innerHeight) {
				// Adjust the height to fit within the viewport
				popoverEl.style.maxHeight = `${window.innerHeight - popoverPosition.top - 20}px`;
			}
		}
	}, [isPopoverOpen, popoverPosition]);


	/**
	 * This use effect will auto adjust header with screen sizing
	 */
	useEffect(() => {
		if (isXLargeScreen) {
			setCurrentIndex(7);
			setIsPopoverOpen(false);
			setIsPopoverOpenMore(false);
		}
		else if (isLargeScreen) {
			setCurrentIndex(5);
			setIsPopoverOpen(false);
			setIsPopoverOpenMore(false);
		}
		else if (isMediumScreen) {
			setCurrentIndex(3);
			setIsPopoverOpen(false);
			setIsPopoverOpenMore(false);
		}
		else if (isSmallScreen) {
			setCurrentIndex(2);
			setIsPopoverOpen(false);
			setIsPopoverOpenMore(false);
		}
		else if (miniScreen) {
			setCurrentIndex(-1)
			setIsPopoverOpen(false);
			setIsPopoverOpenMore(false);
		}
		else {
			setCurrentIndex(-1)
			setIsPopoverOpen(false);
			setIsPopoverOpenMore(false);
		}
	}, [isLargeScreen, isMediumScreen, isSmallScreen, isXLargeScreen, miniScreen])

	const handleClickOutside = (event) => {
		if (popoverRef.current && !popoverRef.current.contains(event.target) &&
			!buttonRefs.current.some(ref => ref && ref.contains(event.target))) {
			setIsPopoverOpen(false);
			setIsPopoverOpenMore(false);
		}
	};

	useEffect(() => {
		let parentMenuName;
		let routedSubMenuName;
		for (let index = 0; index < moduleMenuSet.length; index++) {
			const item = moduleMenuSet[index];

			if (item?.route === location.pathname) {
				parentMenuName = item?.name;
				if (!item.sub_menu?.length >= 1 && item.sub_menu[0] !== null) {
					break;
				}
			}

			const subMenuItem = item.sub_menu?.find(subMenu => subMenu?.route === location.pathname);
			if (subMenuItem) {
				parentMenuName = item.name;
				routedSubMenuName = subMenuItem.name;
				break;
			}
		}

		if (moduleMenuSet.length > 7) {
			const MoreItemList = moduleMenuSet.slice(7);
			if (MoreItemList.length > 0) {
				const selectedMenu = MoreItemList?.find(item => item?.name == parentMenuName);
				if (selectedMenu) {
					setMainBarSelected("More");
					setActiveItemMore(parentMenuName);
					setActiveItem(routedSubMenuName);
				}
				else {
					setMainBarSelected(parentMenuName);
					setActiveItem(routedSubMenuName);
					setActiveItemMore("");
				}
			}
			else {
				setMainBarSelected(parentMenuName);
				setActiveItem(routedSubMenuName);
				setActiveItemMore("");
			}
		}
		else {
			setMainBarSelected(parentMenuName);
			setActiveItem(routedSubMenuName);
			setActiveItemMore("");
		}
	}, [location.pathname, moduleMenuSet])

	// Handle focus on buttons
	const handleFocus = (event, page, index) => {
		if (page?.sub_menu?.length > 0) {
			const buttonRect = buttonRefs.current[index].getBoundingClientRect();
			setPopoverPosition({
				top: buttonRect.bottom + 25,
				left: buttonRect.left
			});
			setSubMenu(page?.sub_menu);
			setIsPopoverOpen(true);
			setIsPopoverOpenMore(false);
		} else {
			setSubMenu([]);
			setIsPopoverOpen(false);
			setIsPopoverOpenMore(false);
		}
	};

	const handleBlur = () => {
		setTimeout(() => {
			setIsPopoverOpen(false);
			setIsPopoverOpenMore(false);
		}, 200);
	};

	const resetPopoverTimeout = () => {
		if (closeTimeout) {
			clearTimeout(closeTimeout);
		}
		setCloseTimout(setTimeout(() => {
			setIsPopoverOpen(false);
			setIsPopoverOpenMore(false);
		}, 3000)); // Auto close after 3 seconds
	};

	const clearPopoverTimeout = () => {
		if (closeTimeout) {
			clearTimeout(closeTimeout);
			setCloseTimout(null);
		}
	};

	const handlePopoverMouseEnter = () => {
		clearPopoverTimeout();
	};

	const handlePopoverMouseLeave = () => {
		resetPopoverTimeout();
	};

	/**
	 * it is used to render main naviagtion item in navBar
	 * @param {*} page // page conatins current menu items
	 * @param {*} index index is used for menu_list more tham 7 to render more nav item in the nav bar
	 * @returns 
	 */
	const renderModuleSet = (page, index) => {
		if (index < currentIndex && currentIndex != 0) {
			return (
				<button
					ref={el => buttonRefs.current[index] = el}
					className={mainBarSelected == page.name ? 'active' : 'non-active'}
					onMouseOver={(event) => handleMouseOverNavItem(event, page)} //button mousover function
					onClick={(event) => handleNavItemClick(event, page)} // nav item click function it it contains no sub_menu
					onFocus={(event) => handleFocus(event, page, index)}
					onBlur={() => { setTimeout(() => handleBlur(), 200); }}
				>
					<span className='button-text'>{page.name}</span>
					{page?.sub_menu?.length > 0 && //showing more icon if nav item contains sub_menu
						<KeyboardArrowDownIcon/>
					}
				</button>
			);
		}
		else if (index === currentIndex) {
			return (
				<button
					ref={el => buttonRefs.current[index] = el}
					className={mainBarSelected == "More" ? 'active' : 'non-active'}
					onMouseOver={(event) => handleMouseOverMore(event)} //button mousover function
					onClick={(event) => handleMouseOverMore(event)}
					onFocus={(event) => handleMouseOverMore(event)}
					onMouseEnter={() => handlePopoverMouseEnter()}
					onMouseLeave={() => handlePopoverMouseLeave()}
					onBlur={() => { setTimeout(() => handleBlur(), 200); }}
				>
					<span className='button-text'>{"More"}</span>
					<KeyboardArrowDownIcon/>
				</button>
			);
		}
		else if (index === 0 && index - 1 == currentIndex) {
			return (
				<button
					ref={el => buttonRefs.current[index] = el}
					className={mainBarSelected == "More" ? 'active' : 'non-active'}
					onMouseOver={(event) => handleMouseOverMore(event)} //button mousover function
					onClick={(event) => handleMouseOverMore(event)}
					onFocus={(event) => handleMouseOverMore(event)}
					onMouseEnter={() => handlePopoverMouseEnter()}
					onMouseLeave={() => handlePopoverMouseLeave()}
					onBlur={() => { setTimeout(() => handleBlur(), 200); }}
				>
					<span className='button-text'>{"Menu"}</span>
					<KeyboardArrowDownIcon/>
				</button>
			)
		}
	}

	return (
		<>
			<div className='moduleset-container'>
				{moduleMenuSet.map((page, index) => renderModuleSet(page, index))}
			</div>

			{isPopoverOpen && (
				<div ref={popoverRef} className="popover"
					style={{
						display: isPopoverOpen ? 'block' : 'none',
						position: 'absolute',
						top: `${popoverPosition.top}px`,
						left: `${popoverPosition.left}px`,
						border: 'unset'
					}}>
					{subMenu.map(item => (
						
						<button
							key={item.name}
							className={item.name === activeItem ? 'active' : 'non-active'}
							onClick={() => handleItemSubMenuClick(item)}
						>
							<span className='dot-element'></span>
							<span className='nav-items'>{item.name}</span>
						</button>
					))}
				</div>
			)}

			{isPopoverOpenMore && (
				<div ref={popoverMoreRef} className="popover"
					style={{
						display: isPopoverOpenMore ? 'block' : 'none',
						position: 'absolute',
						top: `${popoverMorePosition.top}px`,
						left: `${popoverMorePosition.left}px`,
						border: 'unset'
					}}>
					{moreMenuList.map(item => (
						<button
							key={item.name}
							className={item.name === activeItemMore ? 'active' : 'non-active'}
							onMouseOver={(event) => handleMoreItemHover(event, item)}
							onClick={() => handleItemClickMore(item)}
						>
							<span className='dot-element'></span>
							<span className='nav-items'>{item.name}</span>
						</button>
					))}
				</div>
			)}
		</>
	);
}

export default memo(NavItems);
