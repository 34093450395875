import React, { useState, useEffect } from 'react';
import i18n from '../../utilities/i18n';
import SelectInput from '../commons/input/SelectInput';
import CustomizedDialogs from '../modalWindowComponent/CustomizedDialogs';
import { getStorage } from '../../utilities/browserStorage';
import service from "./service";
import Pagination from '../commons/pagination';
import {PAGING_END_INDEX, DEFAULT_PAGING_SIZE} from '../../utilities/staticConfigs';
import claimService from '../ClaimModule/services/claim_service';
import Table from '../commons/Table/Table';
import { PayerNameTable } from './PayerNameTable';
import { checkPermission, commonTableBody } from '../../utilities/commonUtilities';
import { permission_key_values } from '../../utilities/permissions';
import Notify from '../commons/notify';

function PayerNameMatch() {
    const practicePK = getStorage('practice');
    const [showAssignModal, setShowAssignModal] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [inputValueList, setInputValueList] = useState([]);
    const [matchingValue, setMatchingValue] = useState('');
    const [matchingValueList, setMatchingValueList] = useState([]);
    const [matchingPayerList, setMatchingPayerList] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [editPK, setEditPK] = useState('');

    // notify
    const [showNotify, setShowNotify] = useState('hide');
    const [notifyDescription, setNotifyDescription] = useState('');
    const [notifyType, setNotifyType] = useState('success');

    //Pagination start
    const [totalPage, setTotalPage] = useState(1);
    const [activePage, setActivePage] = useState(1);
    const [startIndex, setStartIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(PAGING_END_INDEX);
    const [headerPrayerNameMatch, setHeaderPrayerNameMatch] = useState("");

    useEffect(() => {
        ListMatchingValues(DEFAULT_PAGING_SIZE, 1);
        getPayerData(DEFAULT_PAGING_SIZE);
    }, [])

    function onPagePrevious() {
      let previousPage = startIndex+1-PAGING_END_INDEX;
      setActivePage(previousPage);

      if(startIndex!==0){
          setStartIndex(startIndex - PAGING_END_INDEX);
          setEndIndex(endIndex - PAGING_END_INDEX);
      }
      ListMatchingValues(DEFAULT_PAGING_SIZE,previousPage);          
    }

    function onPageUp(e){
        let page = Number(e.target.id)
        setActivePage(page);
        ListMatchingValues(DEFAULT_PAGING_SIZE,page); 
    }

    function onPageNext() {
        let nextPage = startIndex+1+PAGING_END_INDEX;
        if(endIndex===totalPage || totalPage<=PAGING_END_INDEX){
            setActivePage(nextPage);
            setStartIndex(startIndex);
            setStartIndex(endIndex);
          
        }else{
          setActivePage(nextPage);
          setStartIndex(startIndex + PAGING_END_INDEX);
          setEndIndex(endIndex + PAGING_END_INDEX);

        }
        ListMatchingValues(DEFAULT_PAGING_SIZE,nextPage);
                
    }
    //Pagination ends

    function showNotifyWindow(action, type, desc, age = 3000) {
        if (action == 'show') {
            setTimeout(() => {
                setShowNotify('hide');
            }, age)
        }
        setShowNotify(action);
        setNotifyType(type);
        setNotifyDescription(desc);
    }


    function showAssign() {
        setHeaderPrayerNameMatch(i18n.t('interface.providerNameMatch.new_match')); 
        setEditPK('');
        setInputValue('');
        setMatchingValue('');
        listInputPayer();
        setIsEdit(false);
        setShowAssignModal(true);
    }

    function onHandleChange(e) {
        let name = e.target.name;
        let value = e.target.value;
        if (name === 'inputValue') setInputValue(value);
        if (name === 'matchingValue') setMatchingValue(value);
    }

    function ListMatchingValues(pageSize,page){
        const result = service.GetListMatchingPayer(pageSize,page, practicePK);
        result.then((response) => {
            if(response.data.results !== undefined){
                setTotalPage(Math.ceil(response.data.count/response.data.page_size));
            }
            const rowArray = commonTableBody(response.data.results, PayerNameTable.tableBodyData[0]);
            PayerNameTable.tableBodyData = rowArray;
            setMatchingPayerList(response.data.results);
        })
    }

    function getPayerData(pageSize) {
        const result = claimService.ListInsuranceNames(pageSize, 0, practicePK); // PASS ZERO FOR NO PAGINATION  
        result.then(response => {
            setMatchingValueList(response.data);
        });
    }

    function addAssigned(){
        if (!inputValue || !matchingValue) return;
        let data = {'practice': practicePK, 'practice_insurance': matchingValue, 'external_insurance': inputValue};
        let result = '';
        if (!isEdit) {
            result = service.SaveNewMatchingPayer(data);
        } else {
            result = service.UpdateMatchingPayer(editPK, data);
        }
        if(result){
            result.then((response) => {
                if(response.status === 200|| response.status === 201){
                    ListMatchingValues(DEFAULT_PAGING_SIZE, activePage);
                    setEditPK('');
                    setIsEdit(false);
                    setShowAssignModal(false);
                }
                else if(response.status === 400){
                    if(response.data.message)
                        showNotifyWindow('show', 'error', i18n.t('errorMessages.'+response.data.message));
                    else(Object.keys(response.data))
                        showNotifyWindow('show', 'error', i18n.t('errorMessages.'+Object.keys(response.data)[0]));
                }
            });
        }
        setShowAssignModal(false);
    }

    const onEditPayerMatching = (payerPK) => {
        setHeaderPrayerNameMatch(i18n.t('interface.providerNameMatch.edit_match')); 
        listInputPayer(payerPK);
        setIsEdit(true);
        setEditPK(payerPK);
        const result = service.GetMatchingPayer(payerPK);
        result.then((response) => {
            setInputValue(response.data.external_insurance);
            setMatchingValue(response.data.practice_insurance);
            setShowAssignModal(true);
        });
    }

    const listInputPayer = (payerPK) => {
        const result = service.ListInputPayer(practicePK, payerPK);
        result.then((response) => {
            setInputValueList(response.data.inputs);
        });
    }

    function dropDownFunction(id, name) {
        if (name === 'Edit'){
            onEditPayerMatching(id);
        }
    }

   
    return (
        <div className='col-md-12'>
             <Notify showNotify={showNotify} setShowNotify={setShowNotify} notifyDescription={notifyDescription} setNotifyType={setNotifyType} setNotifyDescription={setNotifyDescription} notifyType={notifyType} />
            <div className='box basic-info-padding'>
                <div className='d-flex justify-content-between mb-2'>
                    <div className=''>
                        <h4>{i18n.t("interface.payerNameMatch.matched_list")}</h4>
                    </div>
                    {/* permission checking */}
                    {checkPermission(permission_key_values.interface_payer_name_matching_add)&&
                    <button type='button' onClick={showAssign} className='btn btn-primary-blue px-4'>
                        {i18n.t("interface.payerNameMatch.add_new")}
                    </button>
                    }
                </div>
                <div className=''>
                    <Table tableObject={PayerNameTable} onLinkClick={dropDownFunction} list={matchingPayerList} />
                    <Pagination totalPage={totalPage} activePage={activePage} startIndex={startIndex} endIndex={endIndex} onPagePrevious={onPagePrevious} onPageUp={onPageUp} onPageNext={onPageNext}/>
                </div>
                <div className=''>
                    <CustomizedDialogs header={headerPrayerNameMatch} showModal={showAssignModal} setShowModalWindow={setShowAssignModal}
                        type='assign' addAssigned={addAssigned} >
                        <div className='row justify-content-between'>
                            <div className='col mr-2'>
                                <SelectInput data={inputValueList} name={'inputValue'} value={inputValue} onValueChange={onHandleChange} label={i18n.t('interface.providerNameMatch.input')} className={inputValue ? '' : 'input-error'} />
                            </div>
                            <div className='col ml-2'>
                                <SelectInput data={matchingValueList} name={'matchingValue'} value={matchingValue} onValueChange={onHandleChange} label={i18n.t('interface.providerNameMatch.matching_name')}  className={matchingValue ? '' : 'input-error'} />
                            </div>
                        </div>
                    </CustomizedDialogs>
                </div>
            </div>
        </div>
    )
}

export default PayerNameMatch