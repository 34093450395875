import React, { useRef, useState } from "react";
import { AddCircle } from "@material-ui/icons";
import { styled } from '@mui/material/styles';
import { TableCell, TableRow, TextField } from "@mui/material";
import CurrencyInputs from "../../commons/input/CurrencyInput";
import i18n from "../../../utilities/i18n";
import { YMD_TO_MDY } from '../../../utilities/commonUtilities'

const CustomTextField = styled(TextField)({
  '& .MuiInputBase-root': {
    height: '35px',
  },
});
const NewRowCard = ({ addNew, showNotification, effectiveDate }) => {
  const proCodeRef = useRef(null);
  const [proFeeRef, setProFeeRef] = useState("");
  const buttonClickHandler = () => {
    const procedureCode = proCodeRef.current.value;
    const professionalFee = proFeeRef;

    if (procedureCode && professionalFee) {
      addNew({ procedureCode, professionalFee });
      proCodeRef.current.value = null;
      setProFeeRef("");
    } else {
      showNotification("show", "error", i18n.t("customerSetup.feeSchedule.customSchedule.emptyField"));
    }
  };

  return (
    <TableRow>
      <TableCell>
        <CustomTextField 
          inputRef={proCodeRef}
          name={"procedure_code"}
          type={"text"}
          id="procedurecode"
        />
      </TableCell>
      <TableCell>
          <CurrencyInputs
            value={proFeeRef}
            name={"professional_fee"}
            type={"number"}
            onValueChange={(e) => {
              const match = e.target.value.match(/^\d*\.?\d{0,2}/);
              setProFeeRef(match ? match[0] : "");
            }}
            divClassName={"margin-bottom0"} 
          />
      </TableCell>
      <TableCell>{YMD_TO_MDY(effectiveDate)}</TableCell>
      <TableCell>
        <button className="btn btn-primary-blue" onClick={buttonClickHandler}>
          <div className="justify-center">
            <div className="margin-right5"> {i18n.t("customerSetup.feeSchedule.customSchedule.AddButton")}</div>
            <AddCircle />
          </div>
        </button>
      </TableCell>
    </TableRow>
  );
};

export default NewRowCard;
