import React, { memo, useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";

// MUI Components
import Chip from '@mui/material/Chip';
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";

// Custom Components
import ExportIconButton from "../../commons/exportIconButton";
import Table from "../../commons/Table/Table";
import Notify from "../../commons/notify";
import FilterIconRoundButton from "../../commons/FilterIconRoundButton";
import Pagination from "../../commons/pagination";
import ReportFilter from "../ReportFilter/ReportFilter";
import TableHeaderSelector from "../../commons/TableHeaderSelector/TableHeaderSelector";

// Table Data and Constants
import { ReportTableData } from "./ClaimLevelReportTable";

// Utilities
import { commonTableBody } from "../../../utilities/commonUtilities";
import workerInstance from "../ReportWorker/workerInstance";
import { URL_BASE_API } from "../../../utilities/urlConfigs";
import { PAGING_END_INDEX } from "../../../utilities/staticConfigs";
import { getStorage } from "../../../utilities/browserStorage";
import i18n from "../../../utilities/i18n";

// Redux Actions
import { updateSortField } from "./ClaimLevelReportSlice";

// Assets
import FilterIcon from "../../../assets/images/filterIcon.png";

// Styles
import { getChipStyle } from "./constants";

// Caching the report table to avoid un-necessary table render
const MemoizedTable = memo(({ tableObject, sortingFunction }) => {
    return (
        <Table
            tableObject={tableObject}
            onLinkClick={() => { }}
            sortingFunction={sortingFunction}
            tblRadiusClass={" table-border-radius12 "}
        />
    );
});
MemoizedTable.displayName = "MemoizedTable";



const ClaimLevelReport = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const memoizedDispatch = useCallback((sortField, sortOrder) => { dispatch(updateSortField({ sortField, sortOrder })); }, []);

    const reportData = useSelector(state => state.claimLevelReport);
    const mainReportStatus = useSelector((state) => state.mainReportStatus); // A Common Report state to keep the api call status, eg: isReportRunning || isError etc..
    const setFilters = (updatedFilterState) => {
        dispatch({ type: 'claimLevelReport/updateFieldValues', payload: { state: 'mainState', field: 'filters', value: updatedFilterState }, })
    }

    /** STATES TO PROPERLY RESET THE REPORT TABLE AND FILTER INPUTS */
    const [resetFlag, setResetFlag] = useState(false);
    const [resetTrigger, setResetTrigger] = useState(false);
    /********************************************** */

    /**Header data for report api call */
    const path = "reports/claim-level-report/"
    const userGroupData = JSON.parse(getStorage("userData") || '{}');
    const userGroupToken = userGroupData.accessToken || "";
    const unid = userGroupData.unid || "";
    const practicePK = getStorage("practice");
    /*********************************************** */

    const [hideFilter, setHideFilter] = useState(true); // Filter toggle - Open & Close

    const onReset = () => {
        dispatch({ type: "claimLevelReport/onResetData" });
        setResetTrigger((prevResetTrigger) => !prevResetTrigger);
    };

    useEffect(() => {
        // Whenever resetTrigger changes, update the resetFlag state to force re-render component
        setResetFlag(prevResetFlag => !prevResetFlag);
    }, [resetTrigger]);


    /** TABLE HEADER ADD & REMOVE FUNCTIONALITY BEGINS HERE  */
    /***********************************************************/
    const [reportTableSelector, setReportTableSelector] = useState(ReportTableData.tableHeadings.map(headerItem => headerItem.name))

    async function MaterialMultiselectHandleChange(e, resetToInitial = false) {
        const reportFieldsArray = ReportTableData.tableHeadings.map(item => item.name)

        let value = [...reportTableSelector];

        if (Array.isArray(e)) {
            value = [...e].length == 0 ? reportFieldsArray.slice(0, 8).map(item => item) : [...e]
        } else {
            if (value.find(item => item === e)) {
                const index = value.indexOf(e);
                value.splice(index, 1);
            } else {
                value = [...value, e];
            }
        }

        let anArray = [];
        reportFieldsArray.forEach((item) => {

            //selection and unselection of table fields
            let itemObj = value.filter((field) => {
                if (field === item) return item;
            });
            if (itemObj.length > 0) {
                anArray.push(itemObj[0]);
            }
        });
        await onSelectFieldNames(anArray, resetToInitial);
        setReportTableSelector(anArray);
    }


    async function onSelectFieldNames(pTableSelecter, resetToInitial = false) {
        // THE FIELD SELECTION CONTROL IS SAME FOR BOTH 'LAST10' & 'ADV. SEARCH RESULTS',
        let tmpTblData = [];
        tmpTblData = ReportTableData;
        let tblHead = [];
        let tblData = [];
        let dataColHideStatus = [];

        // Reset tableheading to initial state if the flag is true
        if (resetToInitial) {
            pTableSelecter = [...reportTableSelector];
        }

        tmpTblData.tableHeadings.map((item) => {
            if (pTableSelecter.find((str) => str === item.name)) {
                // if table heading is available in pTableSelecter then its not hidden
                tblHead.push({ ...item, hideValue: false });
                dataColHideStatus.push(false);
            } else {
                // if table heading is not available in pTableSelecter then its not hidden
                tblHead.push({ ...item, hideValue: true });
                dataColHideStatus.push(true);
            }
        });

        let newRow = [];
        tmpTblData.tableBodyData.map((rowItem) => {
            newRow = [];
            rowItem.map((colItem, colIndex) => {
                colItem.hideValue = dataColHideStatus[colIndex];
                newRow.push(colItem);
            });
            tblData.push(newRow);
        });
        ReportTableData.tableHeadings = tblHead;
        ReportTableData.tableBodyData = tblData;
    }
    /** TABLE HEADER ADD & REMOVE FUNCTIONALITY ENDS HERE  */
    /***********************************************************/



    /********************************************/
    /*** SORTING IMPLEMENTATION STARTS HERE */
    /************************************ */
    const sortField = reportData.sortField; // Current SortField
    const orderType = reportData.orderType; // Current Sort order - Asc or Dsc
    const allSortFieldStates = reportData.allSortFieldStates; // All the sort required fields in Redux

    // Manipulating the Table Header object for desired sort order
    ReportTableData.tableHeadings[0].initialOrdering = allSortFieldStates.custom_claim_id;
    ReportTableData.tableHeadings[0].orderType = orderType;
    ReportTableData.tableHeadings[3].initialOrdering = allSortFieldStates.custom_patient_id;
    ReportTableData.tableHeadings[3].orderType = orderType;
    ReportTableData.tableHeadings[17].initialOrdering = allSortFieldStates.service_from_date;
    ReportTableData.tableHeadings[17].orderType = orderType;
    ReportTableData.tableHeadings[18].initialOrdering = allSortFieldStates.service_to_date;
    ReportTableData.tableHeadings[18].orderType = orderType;


    // Helper Object to map and find out the correct key for the sortfield as from the table
    // When user clicks on the sort icon its passing the header as argument like "Payment Id" instead of custom_claim_id
    // Below object is used to identify the same
    const fieldToSortFieldMap = {
        "Claim ID": "custom_claim_id",
        "Patient ID": "custom_patient_id",
        "Date of Service From": "service_from_date",
        "Date of Service To": "service_to_date"
    };

    /**
     * 
     * @param {*} e - html native button click event - Not in use
     * @param {*} name  - Name of the field requesting be "Sorted by"
     */
    const sortingFunction = useCallback((e, name) => {
        if (checkAnyReportAlreadyRunning() !== 'proceed' || !name) {
            return;
        }

        const sortField = fieldToSortFieldMap[name];

        /**
         * Reset the paging states
         * On each sorting action regardless of ascending or descending, routing the user to first page if user not in the first page
         */

        /**
         * Dispatch current sortField to redux, so when user leaves the report module and returns 
         * the current sort state can be retained
         */
        memoizedDispatch(sortField, !orderType ? "-" : "");
        /**
         * param(1) Requesting page 1 to be fetched
         * param(sortField) filed to be sorted
         * param(orderType) ascending or descending
         */
        executeReportDataFetch(activePage, sortField, !orderType ? "-" : "asc");
    }, [allSortFieldStates, orderType, sortField, mainReportStatus]);
    /*** SORTING IMPLEMENTATION ENDS HERE */
    /************************************ */


    // /** NOTIFY CONFIG */
    const [showNotify, setShowNotify] = useState('hide');
    const [notifyDescription, setNotifyDescription] = useState('');
    const [notifyType, setNotifyType] = useState('success');

    function showNotifyWindow(action, type, desc, age = 3000) {
        if (action === 'show') {
            setShowNotify(action);
            setNotifyType(type);
            setNotifyDescription(desc);
            setTimeout(() => {
                setShowNotify('hide');
            }, age);
        }
    }
    // /** NOTIFY CONFIG ENDS HERE */
    // /****************************/


    useEffect(() => {
        if (mainReportStatus.isError === true) showNotifyWindow("show", "error", i18n.t("reports.reportErrorMessage.errorFetchingReport"));
    }, [mainReportStatus.isError]);


    useEffect(() => {
        const rowArray = commonTableBody(reportData.tableData, ReportTableData.tableBodyData[0])
        ReportTableData.tableBodyData = rowArray;
        // Update the pagination states
        setTotalPage(reportData.totalPage);

        MaterialMultiselectHandleChange(null, true);
    }, [reportData.tableData])


    function checkAnyReportAlreadyRunning() {
        if (mainReportStatus.isReportRunning === true) {
            showNotifyWindow("show", "error", 'Another report is already running in the background, Please wait till it complete!');
            return 'abort';
        } else {
            return 'proceed'
        }
    }



    /*** PAGINATION FUNCTIONALITY STARTS HERE */
    /**************************************** */
    const [totalPage, setTotalPage] = useState(reportData.totalPage || 0);
    const [activePage, setActivePage] = useState(reportData.activePage || 1);

    const [startIndex, setStartIndex] = useState(() => {
        const currentPage = reportData.activePage || 1;
        return Math.floor((currentPage - 1) / PAGING_END_INDEX) * PAGING_END_INDEX;
    });

    const [endIndex, setEndIndex] = useState(() => {
        const currentPage = reportData.activePage || 1;
        const calculatedEnd = (Math.floor((currentPage - 1) / PAGING_END_INDEX) + 1) * PAGING_END_INDEX;
        return calculatedEnd || PAGING_END_INDEX;
    });

    function onPagePrevious() {
        const flag = checkAnyReportAlreadyRunning();
        if (flag !== 'proceed')
            return;

        const previousPage = startIndex - PAGING_END_INDEX + 1;
        setActivePage(previousPage);

        if (startIndex !== 0) {
            setStartIndex(startIndex - PAGING_END_INDEX);
            setEndIndex(endIndex - PAGING_END_INDEX);
        }
        executeReportDataFetch(previousPage);
    }

    function onPageNext() {
        const flag = checkAnyReportAlreadyRunning();
        if (flag !== 'proceed')
            return;

        const nextPage = startIndex + PAGING_END_INDEX + 1;
        const canIncrementIndexes = endIndex === totalPage || totalPage <= PAGING_END_INDEX;

        setActivePage(nextPage);
        setStartIndex(canIncrementIndexes ? startIndex : startIndex + PAGING_END_INDEX);
        setEndIndex(canIncrementIndexes ? startIndex : endIndex + PAGING_END_INDEX);

        executeReportDataFetch(nextPage);
    }

    function onPageUp(e) {
        const flag = checkAnyReportAlreadyRunning();
        if (flag !== 'proceed')
            return;

        const page = Number(e.target.id);
        setActivePage(page);
        executeReportDataFetch(page);
    }
    /*** PAGINATION FUNCTIONALITY ENDS HERE */
    /**************************************** */
    /*********************************/


    // Report export function - only xls download allowed, NO CSV
    const OnExportMenuItemClicked = (e, type) => {
        const flag = checkAnyReportAlreadyRunning();
        if (flag === 'proceed') {
            let query = buildReportQuery();
            query += `&export=true&export_type=${type}`;
            const path = "reports/claim-level-report/?";

            if (type !== "") {
                workerInstance.getXLS(URL_BASE_API, path, query, userGroupToken, unid);
                dispatch({ type: 'report/setReportRunning', payload: { isRunning: true, report: 'Claim Level' } });
            }
        }
    };


    const onSearch = () => {
        const flag = checkAnyReportAlreadyRunning();
        if (flag === 'proceed') {
            const query = buildReportQuery();
            const queryWithPagination = `?page_size=${reportData.pageSize}&page=1&${query}`;
            workerInstance.getReportData(URL_BASE_API, path, queryWithPagination, userGroupToken, unid);
            dispatch({ type: 'report/setReportRunning', payload: { isRunning: true, report: 'Claim Level', search: true } });
            setActivePage(1)
        }
    };


    function executeReportDataFetch(page, sortField, orderType, newPageSize) {
        const flag = checkAnyReportAlreadyRunning();
        if (flag === 'proceed') {
            const query = buildReportQuery(sortField, orderType);
            const queryWithPagination = `?page_size=${newPageSize ?? reportData.pageSize}&page=${page}&${query}`;

            workerInstance.getReportData(URL_BASE_API, path, queryWithPagination, userGroupToken, unid);
            dispatch({ type: 'report/setReportRunning', payload: { isRunning: true, report: 'Claim Level', search: true } });
        }
    }

    const onShowFilter = () => {
        setHideFilter(!hideFilter);
    };

    // HELPER FUNCTION TO GENERATE QUERY PARAMS
    const buildReportQuery = (sortFieldParam, orderTypeParam) => {
        const filters = reportData.filters;
        const sort = sortFieldParam || sortField;
        const order = typeof orderTypeParam === "undefined" ? orderType : (orderTypeParam === "asc" ? "" : "-");

        let query = `practice_pk=${practicePK}`;

        // Date Range Filters
        if (filters.service_from_date_start) query += `&service_from_date_start=${filters.service_from_date_start}`;
        if (filters.service_from_date_end) query += `&service_from_date_end=${filters.service_from_date_end}`;
        if (filters.first_billed_date_start) query += `&first_billed_date_start=${filters.first_billed_date_start}`;
        if (filters.first_billed_date_end) query += `&first_billed_date_end=${filters.first_billed_date_end}`;
        if (filters.last_billed_date_start) query += `&last_billed_date_start=${filters.last_billed_date_start}`;
        if (filters.last_billed_date_end) query += `&last_billed_date_end=${filters.last_billed_date_end}`;
        if (filters.date_of_transaction_from) query += `&date_of_transaction_from=${filters.date_of_transaction_from}`;
        if (filters.date_of_transaction_to) query += `&date_of_transaction_to=${filters.date_of_transaction_to}`;

        // Multi-Select Lists filters.patientIds.map((item) => item.id)
        if (filters.patient_ids?.length) query += `&patient_ids=${filters.patient_ids.map((item) => item.id).join(',')}`;
        if (filters.payer_type_ids?.length) query += `&payer_type_ids=${filters.payer_type_ids.join(',')}`;
        if (filters.insurance_company_ids?.length) query += `&insurance_company_ids=${filters.insurance_company_ids.join(',')}`;
        if (filters.claim_status?.length) query += `&claim_status=${filters.claim_status.join(',')}`;
        if (filters.claim_sub_status?.length) query += `&claim_sub_status=${filters.claim_sub_status.join(',')}`;
        if (filters.rendering_provider_ids?.length) query += `&rendering_provider_ids=${filters.rendering_provider_ids.join(',')}`;
        if (filters.referring_provider_ids?.length) query += `&referring_provider_ids=${filters.referring_provider_ids.join(',')}`;
        if (filters.billing_provider_ids?.length) query += `&billing_provider_ids=${filters.billing_provider_ids.join(',')}`;
        if (filters.service_location_ids?.length) query += `&service_location_ids=${filters.service_location_ids.join(',')}`;
        if (filters.cpt_code_ids?.length) query += `&cpt_code_ids=${filters.cpt_code_ids.join(',')}`;
        if (filters.icd_ids?.length) query += `&icd_ids=${filters.icd_ids.join(',')}`;
        if (filters.transaction_type?.length) query += `&transaction_type=${filters.transaction_type.join(',')}`;
        if (filters.transaction_entered_by_user?.length) query += `&transaction_entered_by_user=${filters.transaction_entered_by_user.join(',')}`;

        // Amount Range Filters
        if (filters.balanceAmountTotalsType) {
            query += `&balance_amount_condition=${filters.balanceAmountTotalsType}`;
            query += `&balance_amount_start=${filters.balanceAmountTotalsFrom || ''}`;
            if (filters.balanceAmountTotalsType === 'Between') {
                query += `&balance_amount_end=${filters.balanceAmountTotalsTo || ''}`;
            }
        }

        // Other Filters
        if (filters.aging) query += `&aging=${filters.aging}`;

        // Sorting
        if (sort) {
            const sortPrefix = order ? order : '';
            query += `&column=${sortPrefix}${sort}`;
        }

        return query;
    };

    /********************************************/

    /**
     * Updates the page size in Redux store and triggers search after state update
     * @param {string} newPageSize - The new page size selected by user
     * @returns {void}
     */
    const handlePageSizeChange = (newPageSize) => {
        const parsedPageSize = Number(newPageSize);
        if (parsedPageSize) {
            dispatch({
                type: 'claimLevelReport/updateFieldValues',
                payload: {
                    state: 'mainState',
                    field: 'pageSize',
                    value: parsedPageSize
                }
            });
            executeReportDataFetch(1, undefined, undefined, newPageSize)
            setStartIndex(0);
            setEndIndex(PAGING_END_INDEX);
            setActivePage(1);
        }
    };

    return (
        <>
            <Notify
                showNotify={showNotify}
                setShowNotify={setShowNotify}
                notifyDescription={notifyDescription}
                setNotifyType={setNotifyType}
                setNotifyDescription={setNotifyDescription}
                notifyType={notifyType}
            />
            <div className="col-md-12">
                <div className="box pb-5 basic-info-padding">
                    <div className="margin-right15">
                        {mainReportStatus.isReportRunning && mainReportStatus.currentRunningReport == "Claim Level" && (
                            <Box sx={{ width: '100%', padding: '5px', margin: '0 auto', border: '1px solid #90a3bd', borderRadius: '10px', textAlign: 'center' }}>
                                <LinearProgress />
                            </Box>
                        )}
                        {hideFilter && (!mainReportStatus.isReportRunning || mainReportStatus.currentRunningReport !== "Claim Level") &&
                            <ReportFilter
                                filterConfig={reportData.filterConfig}
                                filterValues={reportData.filters}
                                filterValueSetter={setFilters}
                                onReset={onReset}
                                onSearch={onSearch}
                                reportType="claimLevelReport"
                                onPageSizeChange={handlePageSizeChange}
                                pageSize={reportData.pageSize}
                            />
                        }

                    </div>
                    <div className='row justify-content-end mr-3 mt-2'>
                        <div className="mt-3">
                            <div className="row">
                                <div className="col-12">
                                    <div className="d-flex flex-wrap">
                                        <Chip
                                            label={`${i18n.t("reports.reportCount")}: ${reportData.count}`}
                                            variant="outlined"
                                            sx={{
                                                ...getChipStyle(theme.palette.primary.light),
                                                paddingLeft: "5px",
                                                paddingRight: "5px"
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="mt-3 ml-2" id="export-dropdown">
                            <ExportIconButton
                                disabled={!reportData.tableData?.length}
                                onlyXLS={true}
                                format={"xlsx"}
                                onExportXls={(e) => OnExportMenuItemClicked(e, 'xls')} />
                        </div>
                        <div className="mt-3" id="table-header-selector">
                            <TableHeaderSelector
                                selected={reportTableSelector}
                                options={ReportTableData.tableHeadings.map(headerItem => headerItem.name)}
                                MaterialMultiselectHandleChange={(e) => MaterialMultiselectHandleChange(e)}
                            />
                        </div>
                        <div className="mt-3 ml-1" id="export-dropdown">
                            <FilterIconRoundButton
                                ToolTip={"FIlter"}
                                ImageSrc={FilterIcon}
                                IconName={"fa-filter"}
                                ImgAlt={'filter'}
                                OnClick={() => onShowFilter()}
                            />
                        </div>
                    </div>

                    <div
                        style={{ overflowX: "auto", width: "100%" }}
                        className="mb-4">
                        <MemoizedTable
                            tableObject={ReportTableData}
                            sortingFunction={sortingFunction}
                            reportTableSelector={reportTableSelector}
                            resetFlag={resetFlag}
                            key={resetFlag.toString()}
                        />
                    </div>
                    {/* The Pagination Component will be rendered only when the page count is greater than default page size i.e 20 */}
                    <Pagination
                        totalPage={totalPage}
                        activePage={activePage}
                        startIndex={startIndex}
                        endIndex={endIndex}
                        onPagePrevious={onPagePrevious}
                        onPageUp={onPageUp}
                        onPageNext={onPageNext}
                    />
                </div>
            </div>
        </>
    );
};

export default ClaimLevelReport;
