import React, { useEffect, useState, useCallback, memo } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setReportDataReceived, setError } from "../../reports/reportStatusManagerSlice";
import { updateChargeDebitReportData } from '../../reports/ChargesDebit/ChargesDebitSlice';
import { updatePaymentAdjustmentReport } from "../../reports/PaymentAdjustment/PaymentAdjustmentSlice";
import { updateOpenArReport } from "../../reports/OpenARReport/openArSlice";
import { updateTransactionReportData } from "../../reports/TransactionReport/TransactionReportSlice";
import { updateCheckLevelReport } from "../../reports/CheckLevelReport/CheckLevelReportSlice"
import { updateDenialReport } from "../../reports/DenialReport/DenialReportSlice";
import { updateClaimLevelReport } from "../../reports/ClaimLevelReport/ClaimLevelReportSlice";


import Notify from '../../commons/notify';
import workerInstance from '../../reports/ReportWorker/workerInstance';
import {
    ROUTE_USER_REPORTS_CHARGES_DEBIT_REPORT,
    ROUTE_USER_REPORTS_PAYMENT_ADJUSTMENTS_REPORT,
    ROUTE_USER_REPORTS_OPENAR_REPORT,
    ROUTE_USER_REPORTS_TRANSACTION_REPORT,
    ROUTE_USER_REPORTS_CHECK_LEVEL_REPORT,
    ROUTE_USER_REPORTS_DENIAL_REPORT,
    ROUTE_USER_REPORTS_CLAIM_LEVEL_REPORT
} from "../../../utilities/staticConfigs"
import service from './service';
import { getStorage } from "../../../utilities/browserStorage";

// Memoized Notify Component
const NotifyComponent = memo(({ showNotify, setShowNotify, notifyDescription, setNotifyType, setNotifyDescription, notifyType, notifyRedirectLink }) => {
    return <Notify showNotify={showNotify} setShowNotify={setShowNotify} notifyDescription={notifyDescription} setNotifyType={setNotifyType} setNotifyDescription={setNotifyDescription} notifyType={notifyType} notifyRedirectLink={notifyRedirectLink} />;
});
NotifyComponent.displayName = 'NotifyComponent';
const ReportHandle = () => {
    // eslint-disable-next-line no-undef
    const fileDownload = require("js-file-download"); // For Excel Report Download
    const dispatch = useDispatch();

    // When report is running and if user is switching the components then updating the current path so..
    // Once the report is generated and if user not at the report component the in the Notify an addition to the success message
    // a button also rendering and onClick routing the user back to the generated report component.
    // Storing the path in the state to match whether user is in the respective report module or not
    const location = useLocation();
    const [path, setPath] = useState(null);
  

    useEffect(() => {
        setPath(location.pathname);
    }, [location]);

    const mainReportStatus = useSelector((state) => state.mainReportStatus);

    const updateChargeDebitReportDataMemoized = useCallback((data) => dispatch(updateChargeDebitReportData(data)));
    const updatePaymentAdjustmentReportMemoized = useCallback((data) => dispatch(updatePaymentAdjustmentReport(data)));
    const updateOpenArReportMemoized = useCallback((data) => dispatch(updateOpenArReport(data)));
    const updateTransactionReportMemoized = useCallback((data) => dispatch(updateTransactionReportData(data)));
    const updateCheckLevelReportMemoized = useCallback((data) => dispatch(updateCheckLevelReport(data)));
    const updateDenialReportMemoized = useCallback((data) => dispatch(updateDenialReport(data)));
    const updateClaimLevelReportMemoized = useCallback((data) => dispatch(updateClaimLevelReport(data)));

    // Notify states
    const [showNotify, setShowNotify] = useState('hide');
    const [notifyDescription, setNotifyDescription] = useState('');
    const [notifyType, setNotifyType] = useState('success');
    const [notifyRedirectLink, setNotifyRedirectLink] = useState(null);
    // Notify config
    const showNotifyWindow = useCallback((action, type, desc, age = 3000, reportRedirectPath) => {
        if (action === 'show') {
            setShowNotify(action);
            setNotifyType(type);
            setNotifyDescription(desc);
            if (reportRedirectPath) setNotifyRedirectLink(reportRedirectPath); else { setNotifyRedirectLink(undefined) }// Report specific
            setTimeout(() => {
                setShowNotify('hide');
            }, age);
        }
    }, []);

    async function tryTokenRenewal() {
        try {
            // Calling a private api so the if refresh token is valid, it will call and renew the access token, so the retport can be called again from worker thread
            let query = "?&page=1&pageSize=1";
            const response = await service.ListPracticesForSwitching(query);
            if (response.status === 200) {
                return "success";
            } else {
                return "error";
            }
        } catch (error) {
            return "error";
        }
    }

    // Function to handle Web-worker messages - api responses
    const handleMessage = useCallback(async (message) => {
        if (message.data?.type === "success" && message.data?.isJson === true) {
            let reportRedirectPath;
            switch (mainReportStatus.currentRunningReport) {
                case 'Charges-Debit':
                    reportRedirectPath = ROUTE_USER_REPORTS_CHARGES_DEBIT_REPORT;
                    updateChargeDebitReportDataMemoized({ type: "success", data: message.data });
                    break;
                case 'Payment-Adjustment':
                    reportRedirectPath = ROUTE_USER_REPORTS_PAYMENT_ADJUSTMENTS_REPORT;
                    updatePaymentAdjustmentReportMemoized({ type: "success", data: message.data });
                    break;
                case 'Open-AR':
                    reportRedirectPath = ROUTE_USER_REPORTS_OPENAR_REPORT;
                    updateOpenArReportMemoized({ type: "success", data: message.data });
                    break;
                case 'Transaction':
                        reportRedirectPath =  ROUTE_USER_REPORTS_TRANSACTION_REPORT;
                        updateTransactionReportMemoized({ type: "success", data: message.data });
                        break;
                case 'Check-Level':
                    reportRedirectPath = ROUTE_USER_REPORTS_CHECK_LEVEL_REPORT;
                    updateCheckLevelReportMemoized({ type: "success", data: message.data });
                    break;
                case 'Denial':
                    reportRedirectPath = ROUTE_USER_REPORTS_DENIAL_REPORT;
                    updateDenialReportMemoized({ type: "success", data: message.data });
                    break;
                case 'Claim Level':
                    reportRedirectPath = ROUTE_USER_REPORTS_CLAIM_LEVEL_REPORT;
                    updateClaimLevelReportMemoized({ type: "success", data: message.data });
                    break;
                default:
                    break;
            }
            if (mainReportStatus.isSearch) {
                showNotifyWindow(
                    "show",
                    "success",
                    `Your ${mainReportStatus.currentRunningReport} Report is ready!`,
                    // If user in the respective report component then show the notify for 3 seconds, if user is elsewhere then show notify for 1O seconds
                    // So uer will have time to decide and click on the button to return to the report
                    reportRedirectPath.includes(path) ? 3000 : 20000,
                    // If user in any component other than the currently generated reports path then pass the route to Notify for the respective report
                    // Using includes method to match current path and report path as === not working due to referential issue, need some debug
                    reportRedirectPath.includes(path)
                        ? undefined
                        : reportRedirectPath
                );
            }
            // Update the main report redux state as report received
            dispatch(setReportDataReceived());
        }

        // Handle the xls download the same as above except, directly download the file to clients system regardless of the path
        if (
            message.data?.result?.type === "success" &&
            message.data?.result?.blob.type === 'application/ms-excel' &&
            !message.data?.isJson
        ) {
            // Create a custom response header to pass to the file download library
            const header = {
                'content-disposition': message.data.result.contentDisposition,
                'content-length': message.data.result.blob.size,
                'content-type': message.data.result.blob.type
            };
            // Utilizing external library to download the file in client system, passing result blob && custom header
            fileDownload(message.data.result.blob, header["content-disposition"]);
            showNotifyWindow(
                "show",
                "success",
                `Your ${mainReportStatus.currentRunningReport} Report is ready!`,
                10000
            );

            // Update the main report redux state as report received
            dispatch(setReportDataReceived());
        }
        // Handle error, just render the error notify but most importantly dispatching setError function to main report slice,
        // So isReportRunning State can be toggled back to false, this is necessary as report loading bars are dependent of this state.
        if (message.data?.type === "error") {
            if (message.data?.error === "Session Out Error") {
                handleSessionError(message, workerInstance);
            } else {
                showNotifyWindow(
                    "show",
                    "error",
                    "An unexpected error occurred while fetching the report!"
                );
                dispatch(setError(true));
            }
        }
        
        
    }, [dispatch, mainReportStatus.currentRunningReport, path, showNotifyWindow]);

    // Side effect to listen for messages / callbacks from Web-worker function
    useEffect(() => {
        workerInstance.addEventListener('message', handleMessage);
        return () => {
            workerInstance.removeEventListener('message', handleMessage);
        };
    }, [handleMessage]);


    // Side effect to restrict immediate tab or browser closing when any report is getting generated,
    // Utilizing javascript beforeunload api to listen for browser or close tab click and only if user confirms
    // close then proceeding with browser close, else aborting the close call
    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (mainReportStatus.isReportRunning) {
                event.preventDefault();
                event.returnValue = '';
                return '';
            }
        };

        const handleUnloadConfirmation = (event) => {
            if (mainReportStatus.isReportRunning) {
                const confirmationMessage = 'A Report is getting generated, Are you sure you want to leave?';
                event.returnValue = confirmationMessage;
                return confirmationMessage;
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);
        window.addEventListener('unload', handleUnloadConfirmation);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
            window.removeEventListener('unload', handleUnloadConfirmation);
        };
    }, [mainReportStatus.isReportRunning]);


    const handleSessionError = async (message, workerInstance) => {
        if (message.data?.error === "Session Out Error") {
            const tokenRenewalStatus = await tryTokenRenewal();

            if (tokenRenewalStatus === "success") {
                const userGroupData = JSON.parse(getStorage("userData") || "{}");
                const userGroupToken = userGroupData.accessToken || "";
                const unid = userGroupData.unid || "";
                const { URL_BASE_API, path, queryWithPagination } = message.data;

                if (queryWithPagination?.includes("export=true")) {
                    workerInstance.getXLS(URL_BASE_API, path, queryWithPagination, userGroupToken,unid);
                } else {
                    workerInstance.getReportData(URL_BASE_API, path, queryWithPagination, userGroupToken,unid);
                }
            } else {
                document.cookie = 'isSessionOut=true; path=/;';
                window.location.href = '/';  // route user back login screen

            }
        }
    };

    return (
        <>
            <NotifyComponent
                showNotify={showNotify}
                setShowNotify={setShowNotify}
                notifyDescription={notifyDescription}
                setNotifyType={setNotifyType}
                setNotifyDescription={setNotifyDescription}
                notifyType={notifyType}
                notifyRedirectLink={notifyRedirectLink}
            />
        </>
    );
};

export default ReportHandle;