import React, { useCallback, useRef, useState } from 'react';
import { useSelector } from "react-redux";
import { Row } from "./RowItems";
import i18n from "../../../../utilities/i18n";
import { useDispatch } from 'react-redux';
import { addAdjustmentAmount, addAdjustmentCode1, addAdjustmentCode2, movedToCreditValueChange, onAddRemarkCodes, onFieldBlurApply, procedureValueChange, removeAdjustmentCode, removeAdjustmentCodeList, removeProcedureFromTable, reversePaymentData,setToggleState } from '../StateManagement/paymentSlice';
import { getAdjustmentCodeList } from '../StateManagement/asyncThunkAPI';
import CustomizedSmallDialogs from '../../../modalWindowComponent/CustomisedSmallDialog';

const ProcedureList = (props) => {
    const { adjustment_1_codes_list, adjustment_2_codes_list, prTotal,claimStatusList,claimSubStatusList,procedureError,procedureData, togglePopUpState} = useSelector((state) => state.paymentData);
    const dispatch = useDispatch();
    const typeHeadAdjustment1Ref = useRef([]);
    const typeHeadAdjustment2Ref = useRef([]);
    const [maxResults, setMaxResults] = useState(5);
    const debounce = (func, delay) => {
        let timeoutId;
        return function (input) {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => func(input), delay);
        };
    };

    const onChange = useCallback((id, value, field) => {
        dispatch(procedureValueChange({ id: id, value: value, field: field}))
    }, []);

    const handleSearchAdjustmentReason_1_code = debounce(query => {
        if (query) {
            dispatch(getAdjustmentCodeList({ query: query, field: "adjustment1" }));
        }
    }, 500);

    const selectOnTab = (e) => {
        if (e.key === "Tab") {
            document.querySelector("a.dropdown-item.active")?.click();
        }
    }

    function handleOPtions(e, name) {
        if (!e) {
            if (name == "adjustment_1_codes_list") {
                dispatch(removeAdjustmentCodeList("adjustment_1_codes_list"));
            } else if (name == "adjustment_2_codes_list") {
                dispatch(removeAdjustmentCodeList("adjustment_2_codes_list"));
            }
        }
    }

    const onHandleAdjustment_1_code = useCallback((e, id, index) => {
        if (e.length > 0) {
            dispatch(addAdjustmentCode1({ e: e, id: id }))
            clearTypeHeadSearchValue("adjustment_code1_data", index)
        }
    }, []);

    function clearTypeHeadSearchValue(adjustment1or2, index) {
        // Clear the input value from async type head when an item is selected by the user
        if (adjustment1or2 === 'adjustment_code1_data') {
            if (adjustment1or2 === 'adjustment_code1_data') {
                if (typeHeadAdjustment1Ref.current[index] && typeof typeHeadAdjustment1Ref.current[index].clear === 'function') {
                    typeHeadAdjustment1Ref.current[index].clear();
                }
            }
        } else if (adjustment1or2 === 'adjustment_code2_data') {
            if (adjustment1or2 === 'adjustment_code2_data') {
                if (typeHeadAdjustment2Ref.current[index] && typeof typeHeadAdjustment2Ref.current[index].clear === 'function') {
                    typeHeadAdjustment2Ref.current[index].clear();
                }
            }
        }
    }

    const handleDeleteAdjustment = (item, removeIndex,posIndex, id, field) => {
        dispatch(removeAdjustmentCode({ item: item, index: removeIndex, id: id, field: field }));
        dispatch(onFieldBlurApply(({id:id,field:field,index:posIndex,item:item})));
    }

    const handleAdjustmentAmountChange = useCallback((e, adjustment, id, field) => {
        let value = String(e.target.value).trim().replace(/[^\-\d|\d(.\d+)]/g, '').substring(0, 9);
        // Convert to two decimal points when the input has more than 2 decimal points
        if (!isNaN(Number(value))) {
            let parts = String(value).split('.');
            if (parts.length > 1 && parts[1].length > 2) {
                value = parseFloat(value)?.toFixed(2);
            }
        }

        dispatch(addAdjustmentAmount({ value: value, adjustment: adjustment, id: id, field: field }));
    }, []);


    const handleSearchAdjustmentReason_2_code = debounce(query => {
        if (query) {
            dispatch(getAdjustmentCodeList({ query: query, field: "adjustment2" }));
        }
    }, 500);

    const onHandleAdjustment_2_code = useCallback((e, id, index) => {
        if (e.length > 0) {
        dispatch(addAdjustmentCode2({ e: e, id: id }))
        clearTypeHeadSearchValue("adjustment_code2_data", index)
        }
    }, []);

    const onHandleMultiSelectSearchForRemarkCodes = useCallback((e, id) => {
        dispatch(onAddRemarkCodes({ value: e, id: id }))
    }, []);


    const onBlurApply = useCallback((id,field,index) => {
        dispatch(onFieldBlurApply({id:id,field:field,index:index}));
    }, []);

    const removeProcedure=(id)=>{
        dispatch(removeProcedureFromTable(id))
    }

    const reversePayment=(id,index)=>{
        dispatch(reversePaymentData({id:id,index:index}))
    }


    const onMovedTocreditChange=(id,status)=>{
        dispatch(movedToCreditValueChange({ id, status }))
    }

    const handleModalClose = () => {
        dispatch(setToggleState({value: false,popValue:"no"}));    
    };

    const onAlertClick = () => {
        dispatch(setToggleState({value: false,popValue:"yes"}));
    };

    return (
        <div>
            <div style={{ marginTop: 15 }}>
                <table className='table' id='overflow_auto'>
                    <tbody>
                        {
                            (Object.keys(procedureData).length === 0) && (
                                <tr>
                                    <td align="center" colSpan={20}>
                                        {i18n.t("commons.noRecords")}{" "}
                                    </td>
                                </tr>
                            )
                        }
                        {
                            (Object.keys(procedureData).length > 0) && (
                                Object.entries(procedureData).map(([id], index) => (
                                    <Row key={id} id={id} index={index} item={procedureData[id]} adjustment_1_codes_list={adjustment_1_codes_list} prTotal={prTotal} removeProcedure={(id) => removeProcedure(id)}
                                        maxResults={maxResults} setMaxResults={setMaxResults} onChange={(id, e, field, toggledState) => onChange(id, e, field, toggledState)} handleOPtions={(e, name) => handleOPtions(e, name)}
                                        handleSearchAdjustmentReason_1_code={(query) => handleSearchAdjustmentReason_1_code(query)} selectOnTab={(e) => selectOnTab(e)} onBlurApply={(id, field, index) => onBlurApply(id, field, index)}
                                        handleDeleteAdjustment={(item, removeIndex, posIndex, id, field) => handleDeleteAdjustment(item, removeIndex, posIndex, id, field)} procedureError={procedureError}
                                        handleAdjustmentAmountChange={(e, adjustment, id, field) => handleAdjustmentAmountChange(e, adjustment, id, field)} typeHeadAdjustment1Ref={typeHeadAdjustment1Ref}
                                        onHandleAdjustment_1_code={(e, id, index) => onHandleAdjustment_1_code(e, id, index)} typeHeadAdjustment2Ref={typeHeadAdjustment2Ref} adjustment_2_codes_list={adjustment_2_codes_list}
                                        handleSearchAdjustmentReason_2_code={(query) => handleSearchAdjustmentReason_2_code(query)} onHandleAdjustment_2_code={(e, id, index) => onHandleAdjustment_2_code(e, id, index)}
                                        onHandleMultiSelectSearchForRemarkCodes={(e, id) => onHandleMultiSelectSearchForRemarkCodes(e, id)} claimStatusList={claimStatusList} claimSubStatusList={claimSubStatusList}
                                        reversePayment={(id, index) => reversePayment(id, index)} updatePayment={props.updatePayment} onMovedTocreditChange={(id) => onMovedTocreditChange(id, procedureData[id]?.moved_to_credit)}

                                    />
                                ))
                            )
                        }
                    </tbody>
                </table>
            </div>
            <CustomizedSmallDialogs
                type="confirmation"
                showModal={togglePopUpState}
                goBack={handleModalClose}
                setShowModalWindow={()=>handleModalClose()}
                alertOK={onAlertClick}
                header={i18n.t("commons.alert")}
            >
                {"Do you want to move this payment to credit."}
            </CustomizedSmallDialogs>
        </div>
    )

}

export default ProcedureList;