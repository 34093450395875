import i18n from '../../../utilities/i18n';
import { isValidFilterConfig } from '../../../utilities/commonUtilities';
import { DEFAULT_PAGING_SIZE } from '../../../utilities/staticConfigs';

export const claimLevelReportInitialState = {
    tableData: [],
    loading: false,
    pageSize: DEFAULT_PAGING_SIZE,
    count: 0,
    totalPage: 0,
    activePage: 1,
    sortField: null,
    orderType: null,
    filterConfig: [
        // Service Date Range
        {
            fieldName: "service_from_date_start",
            type: "dateField",
            label: i18n.t("reports.filters.dateOfServiceFrom")
        },
        {
            fieldName: "service_from_date_end",
            type: "dateField",
            label: i18n.t("reports.filters.dateOfServiceTo"),
            minDateField: "service_from_date_start"
        },

        // First Billed Date Range
        {
            fieldName: "first_billed_date_start",
            type: "dateField",
            label: i18n.t("reports.filters.firstBilledDateFrom")
        },
        {
            fieldName: "first_billed_date_end",
            type: "dateField",
            label: i18n.t("reports.filters.firstBilledDateTo"),
            minDateField: "first_billed_date_start"
        },

        // Last Billed Date Range
        {
            fieldName: "last_billed_date_start",
            type: "dateField",
            label: i18n.t("reports.filters.lastBilledDateFrom")
        },
        {
            fieldName: "last_billed_date_end",
            type: "dateField",
            label: i18n.t("reports.filters.lastBilledDateTo"),
            minDateField: "last_billed_date_start"
        },

        // Multi-Select Lists
        {
            fieldName: 'patient_ids',
            label: i18n.t("reports.filters.patientId"),
            type: 'multiSearchSelect',
            dropDownOptionName: 'patientIdList',
        },

        {
            fieldName: 'payer_type_ids',
            label: i18n.t("reports.filters.payerType"),
            type: 'multiSelect',
            dropDownOptionName: 'payerTypes'
        },

        {
            fieldName: 'insurance_company_ids',
            label: i18n.t("reports.filters.insuranceCompanies"),
            type: 'multiSelect',
            dropDownOptionName: 'insuranceCompanyList'
        },

        {
            fieldName: 'claim_status',
            label: i18n.t("reports.filters.claimStatus"),
            type: 'multiSelect',
            dropDownOptionName: 'claimStatusList'
        },
        {
            fieldName: 'claim_sub_status',
            label: i18n.t("reports.filters.claimSubStatus"),
            type: 'multiSelect',
            dropDownOptionName: 'claimSubStatusList'
        },
        {
            fieldName: 'rendering_provider_ids',
            label: i18n.t("reports.filters.renderingProvider"),
            type: 'multiSelect',
            dropDownOptionName: 'renderingProviderList'
        },
        {
            fieldName: 'referring_provider_ids',
            label: i18n.t("reports.filters.refferingProvider"),
            type: 'multiSelect',
            dropDownOptionName: 'referringProviderList'
        },
        {
            fieldName: 'billing_provider_ids',
            label: i18n.t("reports.filters.billingProvider"),
            type: 'multiSelect',
            dropDownOptionName: 'renderingProviderList'
        },
        {
            fieldName: 'service_location_ids',
            label: i18n.t("reports.filters.serviceLocation"),
            type: 'multiSelect',
            dropDownOptionName: 'serviceLocationList'
        },
        {
            fieldName: 'cpt_code_ids',
            label: i18n.t("reports.filters.cptList"),
            type: 'multiSelect',
            dropDownOptionName: 'cptList'
        },
        {
            fieldName: 'icd_ids',
            label: i18n.t("reports.filters.ICD"),
            type: 'multiSelect',
            dropDownOptionName: 'icdList'
        },
        // Transaction Date Range
        {
            fieldName: 'date_of_transaction_from',
            label: i18n.t("reports.filters.transactionFrom"),
            type: 'dateField',
        },
        {
            fieldName: 'date_of_transaction_to',
            label: i18n.t("reports.filters.transactionTo"),
            type: 'dateField',
            minDateField: "date_of_transaction_from"
        },
        {
            fieldName: 'transaction_type',
            label: i18n.t("reports.filters.transaction_type"),
            type: 'multiSelect',
            dropDownOptionName: 'transactionTypes'
        },
        {
            fieldName: 'transaction_entered_by_user',
            label: i18n.t("reports.filters.transactionEnteredUser"),
            type: 'multiSelect',
            dropDownOptionName: 'praticeUserList'
        },
        {
            fieldName: 'aging',
            label: i18n.t("reports.filters.aging"),
            type: 'select',
            dropDownOptionName: 'agingList'
        },

        // Amount Range Filter
        {
            fieldName: 'balanceAmount',
            label: i18n.t("reports.filters.balanceAmountTotals"),
            type: 'amountFilter',
            fieldPrefix: 'balanceAmountTotals',
        }
    ].map(item => {
        if (!isValidFilterConfig(item)) {
            throw new Error(`Invalid filter config for ${item.fieldName}`);
        }
        return item;
    }),
    filters: {
        // Date Range Filters
        service_from_date_start: "",
        service_from_date_end: "",
        first_billed_date_start: "",
        first_billed_date_end: "",
        last_billed_date_start: "",
        last_billed_date_end: "",
        date_of_transaction_from: "",
        date_of_transaction_to: "",

        // Multi-Select Lists
        patient_ids: [],
        payer_type_ids: [],
        insurance_company_ids: [],
        claim_status: [],
        claim_sub_status: [],
        rendering_provider_ids: [],
        referring_provider_ids: [],
        billing_provider_ids: [],
        service_location_ids: [],
        cpt_code_ids: [],
        icd_ids: [],
        transaction_type: [],
        transaction_entered_by_user: [],

        // Amount Range Filters (using the fieldPrefix from config)
        balanceAmountTotalsType: "",
        balanceAmountTotalsFrom: "",
        balanceAmountTotalsTo: "",

        // Other Filters
        aging: ""
    },
    allSortFieldStates: {
        "custom_claim_id": true,
        "custom_patient_id": true,
        "service_from_date": true,
        "service_to_date": true
    },
    isError: "not-set",
};

const chipBaseStyle = {
    margin: '3px',
    fontSize: '1rem',
    backgroundColor: '#F5F5F5',
    cursor: 'default',
};

export const getChipStyle = (color) => ({
    ...chipBaseStyle,
    border: `1px solid ${color} !important`,
    color: `${color} !important`,
});