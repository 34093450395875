import { createSlice } from '@reduxjs/toolkit'
import { denialReportInitialState } from './constants';
import { DEFAULT_PAGING_SIZE } from '../../../utilities/staticConfigs';


export const DenialReportSlice = createSlice({
    name: "denialReport",
    initialState: denialReportInitialState,
    reducers: {
        onResetData: () => denialReportInitialState,
        updateDenialReport: (state, action) => {
            const { type, data } = action.payload;

            if (type === "success" && data?.data) {
                const { count, page_size, current_page, results } = data.data;

                state.count = count;
                state.pageSize = page_size;
                state.totalPage = Math.ceil(count / page_size);
                state.tableData = results || [];
                state.isError = false;
                state.activePage = current_page;
            } else {
                state.isError = true;
                state.tableData = [];
                state.count = 0;
                state.pageSize = DEFAULT_PAGING_SIZE;
                state.totalPage = 0;
            }
        },
        updateSortField: (state, actions) => {
            // toggle all the sortfields to true except for the currently sorting field
            const newSortState = Object.keys(state.allSortFieldStates).reduce(
                (acc, key) => {
                    acc[key] = key === actions.payload.sortField ? false : true;
                    return acc;
                },
                {}
            );
            return {
                ...state,
                sortField: actions.payload.sortField,
                orderType: actions.payload.sortOrder,
                allSortFieldStates: newSortState,
            };
        },
        updateFieldValues: (state, action) => {
            const updateState = action.payload.state;
            const value = action.payload.value;
            const field = action.payload.field;
            if (updateState !== "mainState") {
                return {
                    ...state,
                    [updateState]: {
                        ...state[updateState],
                        [field]: value,
                    },
                };
            } else {
                return {
                    ...state,
                    [field]: value,
                };
            }
        },
    },
});

export const {
    onResetData,
    updateDenialReport,
    updateSortField,
    updateFieldValue,
} = DenialReportSlice.actions;

export default DenialReportSlice.reducer;