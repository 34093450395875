import i18n from '../../../utilities/i18n';
import { isValidFilterConfig } from '../../../utilities/commonUtilities';
import { DEFAULT_PAGING_SIZE } from '../../../utilities/staticConfigs';

export const denialReportInitialState = {
    tableData: [],
    loading: false,
    pageSize: DEFAULT_PAGING_SIZE,
    count: 0,
    totalPage: 0,
    activePage: null,
    sortField: null, // Current SortField
    orderType: null, // Current Sort Order Asc or Dsc
    filterConfig: [
        // Date of Service Range
        {
            fieldName: "dos_from",
            type: "dateField",
            label: i18n.t("reports.filters.dateOfServiceFrom")
        },
        {
            fieldName: "dos_to",
            type: "dateField",
            label: i18n.t("reports.filters.dateOfServiceTo"),
            minDateField: "dos_from"
        },

        // Payment Entry Date Range
        {
            fieldName: "date_of_payment_entry_from",
            type: "dateField",
            label: i18n.t("reports.filters.dateOfPaymentEntryFrom")
        },
        {
            fieldName: "date_of_payment_entry_to",
            type: "dateField",
            label: i18n.t("reports.filters.dateOfPaymentEntryTo"),
            minDateField: "date_of_payment_entry_from"
        },

        // Claim Entry Date Range
        {
            fieldName: "date_of_claim_entry_from",
            type: "dateField",
            label: i18n.t("reports.filters.dateOfEntryFrom")
        },
        {
            fieldName: "date_of_claim_entry_to",
            type: "dateField",
            label: i18n.t("reports.filters.dateOfEntryTo"),
            minDateField: "date_of_claim_entry_from"
        },

        // Check Date Range
        {
            fieldName: "check_date_from",
            type: "dateField",
            label: i18n.t("reports.filters.paymentCheckDateFrom")
        },
        {
            fieldName: "check_date_to",
            type: "dateField",
            label: i18n.t("reports.filters.paymentCheckDateTo"),
            minDateField: "check_date_from"
        },

        // First Billed Date Range
        {
            fieldName: "first_billed_date_from",
            type: "dateField",
            label: i18n.t("reports.filters.firstBilledDateFrom")
        },
        {
            fieldName: "first_billed_date_to",
            type: "dateField",
            label: i18n.t("reports.filters.firstBilledDateTo"),
            minDateField: "first_billed_date_from"
        },

        // Last Billed Date Range
        {
            fieldName: "last_billed_date_from",
            type: "dateField",
            label: i18n.t("reports.filters.lastBilledDateFrom")
        },
        {
            fieldName: "last_billed_date_to",
            type: "dateField",
            label: i18n.t("reports.filters.lastBilledDateTo"),
            minDateField: "last_billed_date_from"
        },

        // Multi-Select Lists
        {
            fieldName: "insurance_company_list",
            type: "multiSelect",
            label: i18n.t("reports.filters.insuranceCompanies"),
            dropDownOptionName: "insuranceCompanyList"
        },
        {
            fieldName: "claim_status_list",
            type: "multiSelect",
            label: i18n.t("reports.filters.claimStatus"),
            dropDownOptionName: "claimStatusList"
        },
        {
            fieldName: "claim_sub_status_list",
            type: "multiSelect",
            label: i18n.t("reports.filters.claimSubStatus"),
            dropDownOptionName: "claimSubStatusList"
        },
        {
            fieldName: "rendering_provider_list",
            type: "multiSelect",
            label: i18n.t("reports.filters.renderingProvider"),
            dropDownOptionName: "renderingProviderList"
        },
        {
            fieldName: "referring_provider_list",
            type: "multiSelect",
            label: i18n.t("reports.refferingProvider"),
            dropDownOptionName: "referringProviderList"
        },
        {
            fieldName: "billing_provider_list",
            type: "multiSelect",
            label: i18n.t("reports.filters.billingProvider"),
            dropDownOptionName: "renderingProviderList"
        },
        {
            fieldName: "cpt_code_list",
            type: "multiSelect",
            label: i18n.t("reports.filters.cptList"),
            dropDownOptionName: "cptList"
        },
        {
            fieldName: "denial_code_list",
            type: "multiSearchSelect",
            label: i18n.t("reports.filters.adjustmentCodes"),
            dropDownOptionName: "adjustmentCodesList"
        },
        {
            fieldName: "serviceLocation",
            type: "multiSelect",
            label: i18n.t("reports.filters.serviceLocation"),
            dropDownOptionName: "serviceLocationList"
        },

        // Amount Range Filters
        {
            fieldName: "balanceAmount",
            type: "amountFilter",
            label: i18n.t("reports.filters.balanceAmountTotals"),
            fieldPrefix: "balanceAmount"
        },
        {
            fieldName: "chargeAmount",
            type: "amountFilter",
            label: i18n.t("reports.cpt_charge_amount"),
            fieldPrefix: "chargeAmount"
        },

        // Other Filters
        {
            fieldName: "aging",
            type: "select",
            label: i18n.t("reports.filters.aging"),
            dropDownOptionName: "agingList"
        }
    ].map(item => {
        if (!isValidFilterConfig(item)) {
            throw new Error(`Invalid filter config for ${item.fieldName}`);
        }
        return item;
    }),
    filters: {
        // Date Range Filters
        dos_from: "",
        dos_to: "",
        date_of_payment_entry_from: "",
        date_of_payment_entry_to: "",
        date_of_claim_entry_from: "",
        date_of_claim_entry_to: "",
        check_date_from: "",
        check_date_to: "",
        first_billed_date_from: "",
        first_billed_date_to: "",
        last_billed_date_from: "",
        last_billed_date_to: "",

        // List Selection Filters
        insurance_company_list: [],
        claim_status_list: [],
        claim_sub_status_list: [],
        rendering_provider_list: [],
        referring_provider_list: [],
        billing_provider_list: [],
        cpt_code_list: [],
        denial_code_list: [],
        serviceLocation: [],

        // Amount Range Filters
        balanceAmountType: "",
        balanceAmountFrom: "",
        balanceAmountTo: "",
        chargeAmountType: "",
        chargeAmountFrom: "",
        chargeAmountTo: "",

        // Other Filters
        aging: ""
    },
    allSortFieldStates: {
        "custom_claim_id": true
    },
    isError: "not-set",
};


const chipBaseStyle = {
    margin: '3px',
    fontSize: '1rem',
    backgroundColor: '#F5F5F5',
    cursor: 'default',
};

export const getChipStyle = (color) => ({
    ...chipBaseStyle,
    border: `1px solid ${color} !important`,
    color: `${color} !important`,
});