import React, { useContext, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { format } from 'date-fns';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';
import CommonButton from '../../commons/Buttons';

import { styled } from '@mui/material/styles';
import { getStorage } from '../../../utilities/browserStorage';
import LoadingContext from '../../../container/loadingContext';
import { DEFAULT_PAGING_SIZE, PAGING_END_INDEX } from '../../../utilities/staticConfigs';
import service from '../service';
import i18n from '../../../utilities/i18n';
import { ViewPaymentsTableBodyOnly, ViewPaymentsTableObject } from '../ViewPaymentsTable';
import Notify from '../../commons/notify';
import SelectInput from '../../commons/input/SelectInput';
import { POST_PAYMENT_PRIMARY_TYPES } from '../../../utilities/dictionaryConstants';
import PatientDropDownSearch from '../../patientManagement/patients/patientDropDownSearch';
import RightSidePanel from '../../commons/RightSidePanel/RightSidePanel';
import PatientsAdvancedSearch from '../../patientManagement/patients/PatientsAdvancedSearch';
import TextInput from '../../commons/input/input';
import CalendarInput from '../../commons/input/CalendarInput';
import { MaterialMultiselect } from '../../../MaterialMultiselect/MaterialMultiselect';
import Table from '../../commons/Table/Table';
import Pagination from '../../commons/pagination';
import { useDispatch } from 'react-redux';
import { removeAllPaymentDetails } from './StateManagement/paymentSlice';
import PostPayment from './PostPayment';
// Custom style for the indicator
const CustomIndicator = styled('span')(() => ({
    backgroundColor: 'white',
    height: 7,
    position: 'absolute',
    bottom: 0,
    width: 'calc(100% - 4px)',
    marginLeft: '2px'

}));

const StyledTabList = styled(TabList)`
  & .MuiTabs-indicator {
    background-color: transparent !important;
    height: 0px !important;
  }
`;

const ViewPayments = (props) => {
    const dispatch=useDispatch();
    const [activeTab, setActiveTab] = useState("viewPayments");
    const practicePK = getStorage('practice');
    const setShowLoadingBar = useContext(LoadingContext);
    const [revenueCodeList, setRevenueCodeList] = useState([]);
    const [providerList, setProviderList] = useState([]);
    const [insuranceCompaniesList, setInsuranceCompaniesList] = useState([]);
    const [CPTList, setCPTList] = useState([]);
    const [ICDList, setICDList] = useState([]);

    const [payment_from, setPayment_from] = useState("");

    const [patientPK, setPatientPK] = useState("");
    const [patientSelected, setPatientSelected] = useState([]);

    const [insurance, setInsurance] = useState("");
    const [paymentCheckNo, setPaymentCheckNo] = useState("");
    const [payment_date_from, setPayment_date_from] = useState("");
    const [payment_date_to, setPayment_date_to] = useState("");
    const [payment_entered_date_from, setPayment_entered_date_from] = useState("");
    const [payment_entered_date_to, setPayment_entered_date_to] = useState("");
    const [provider, setProvider] = useState([]);
    const [hcpcs, setHcpcs] = useState([]);
    const [revenue_codes, setRevenue_codes] = useState([]);
    const [icd, setIcd] = useState([]);
    const [paymentDatas, setPaymentDatas] = useState([]);

    const [selectedEditPaymentPK, setSelectedEditPaymentPK] = useState('');
    const [selectedEditPaymentSubPK, setSelectedEditPaymentSubPK] = useState('');
    const [selectedEditPaymentType, setSelectedEditPaymentType] = useState('');
    const [selectedEditPtientPK, setSelectedEditPatientPK] = useState('');
    const [selectedEditPatientPostType, setSelectedEditPatientPostType] = useState('');
    const [selectedEditPaymentID, setSelectedEditPaymentID] = useState('');
    const [selectedClaimID, setSelectedClaimID] = useState('');
    const [selectedCustomClaimID, setSelectedCustomClaimID] = useState('');
    const [selectedClaim, setSelectedClaim] = useState({});
    const [patientAdvSearchData, setPatientAdvSearchData] = useState([]);

    const [viewFromLog, setViewFromLog] = useState(true);

    const [showNotify, setShowNotify] = useState('hide');
    const [notifyDescription, setNotifyDescription] = useState('');
    const [notifyType, setNotifyType] = useState('success');
    const [clearSelectedPatient, setClearSelectedPatient] = useState(0);

    const [viewPaymentsTableReset, setViewPaymentsTableReset] = useState(false);

    function showNotifyWindow(action, type, desc, age = 3000) {
        if (action == 'show') {
            setTimeout(() => {
                setShowNotify('hide');
            }, age)
        }
        setShowNotify(action);
        setNotifyType(type);
        setNotifyDescription(desc);
    }

    //Pagination start
    const [totalPage, setTotalPage] = useState(1);
    const [activePage, setActivePage] = useState(1);
    const [startIndex, setStartIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(PAGING_END_INDEX);

    function onPagePrevious() {
        let previousPage = startIndex + 1 - PAGING_END_INDEX;
        setActivePage(previousPage);
        if (startIndex !== 0) {
            setStartIndex(startIndex - PAGING_END_INDEX);
            setEndIndex(endIndex - PAGING_END_INDEX);
        }
        getPayments(DEFAULT_PAGING_SIZE, previousPage);
    }

    function onPageUp(e) {
        let page = Number(e.target.id)
        setActivePage(page);
        getPayments(DEFAULT_PAGING_SIZE, page);
    }

    function onPageNext() {
        let nextPage = startIndex + 1 + PAGING_END_INDEX;
        if (endIndex === totalPage || totalPage <= PAGING_END_INDEX) {
            setActivePage(nextPage);
            setStartIndex(startIndex);
            setStartIndex(endIndex);
        } else {
            setActivePage(nextPage);
            setStartIndex(startIndex + PAGING_END_INDEX);
            setEndIndex(endIndex + PAGING_END_INDEX);
        }
        getPayments(DEFAULT_PAGING_SIZE, nextPage);
    }
    //Pagination ends

    function onHandleChange(e) {
        if (e.target) {
            let name = e.target.name;
            let value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
            let trimmedValue = "";
            let inputFields = ["payment_from","insurance","provider","hcpcs","revenue_codes","icd","reference_check_number"];
            if (inputFields.includes(name)) {
                trimmedValue = value;
            }
            if (name === "payment_from") setPayment_from(trimmedValue)
            else if (name === "insurance") setInsurance(trimmedValue)
            else if (name === "provider") setProvider(e.target.value)
            else if (name === "hcpcs") setHcpcs(e.target.value)
            else if (name === "revenue_codes") setRevenue_codes(e.target.value)
            else if (name === "icd") setIcd(e.target.value)
            else if (name === "reference_check_number") setPaymentCheckNo(trimmedValue)
        } 
    }

    function onHandleDate(value, name) {
        if (name === "payment_date_from") setPayment_date_from(value);
        else if (name === "payment_date_to") setPayment_date_to(value);
        else if (name === "payment_entered_date_from") setPayment_entered_date_from(value);
        else if (name === "payment_entered_date_to") setPayment_entered_date_to(value);
    }

    function getRevenueCodeData() {
        setShowLoadingBar(true);
        const result = service.GetListRevenueCode();
        result.then(response => {
            setRevenueCodeList(response.data);
            setShowLoadingBar(false);
        });
    }

    function getInsuranceCompaniesData() {
        setShowLoadingBar(true);
        let result = null;
        result = service.ListInsuranceCompanies(practicePK);
        result.then(response => {
            setShowLoadingBar(false);
            setInsuranceCompaniesList(response.data);
        });
    }

    function getProvidersData() {
        setShowLoadingBar(true);
        let result = null;
        result = service.ListProviders(practicePK);
        result.then(response => {
            setShowLoadingBar(false);
            setProviderList(response.data);
        });
    }

    function getCPTCodes() {
        setShowLoadingBar(true);
        let result = service.ListCPTCodes(practicePK);
        result.then(response => {
            setShowLoadingBar(false);
            setCPTList(response.data);
        });
    }

    function getICDCodes() {
        setShowLoadingBar(true);
        let result = service.ListICDCodes(practicePK);
        result.then(response => {
            setShowLoadingBar(false);
            let icds = [];
            if (response.data && response.data.length > 0) {
                response.data.forEach((item) => {
                    icds.push({ 'id': item.label, 'name': item.name })
                });
            }
            setICDList(icds);
        });
    }

    useEffect(() => {
        getRevenueCodeData();
        getInsuranceCompaniesData();
        getProvidersData();
        getCPTCodes();
        getICDCodes();
        if (props.location.state && viewFromLog) {
            setViewFromLog(false);
            setSelectedEditPaymentPK(props.location.state.selectedID.id);
            setSelectedEditPaymentSubPK(props.location.state.selectedID.payment_sub_pk);
            setSelectedEditPaymentType(props.location.state.selectedID.payment_type);
            setSelectedEditPatientPK(props.location.state.selectedID.patient_pk);
            setSelectedEditPatientPostType(props.location.state.selectedID.post_type);
            setSelectedEditPaymentID(props.location.state.selectedID.custom_payment_id);
            setSelectedClaim({
                'label': props.location.state.selectedID.claim_id,
                'name': props.location.state.selectedID.custom_claim_id
            });
            setSelectedClaimID(props.location.state.selectedID.claim_id);
            setSelectedCustomClaimID(props.location.state.selectedID.custom_claim_id);
        }
    }, [])

    function onViewPayments() {
        if(payment_date_to && payment_date_to < payment_date_from) {
            showNotifyWindow('show','error',i18n.t("commons.dateOrder"))
        } else if (payment_entered_date_to && payment_entered_date_to < payment_entered_date_from) {
            showNotifyWindow('show','error',i18n.t("commons.dateOrder"))
        } else {
            getPayments(DEFAULT_PAGING_SIZE, 1);
            setActivePage(1);

        }
    }

    function getPayments(pageSize, page) {
        setShowLoadingBar(true);
        let query = '?practice_pk=' + practicePK + '&page_size=' + pageSize + '&page=' + page;
        if (payment_from) query += '&payment_from=' + payment_from;
        if (patientPK) query += '&patient_pk=' + patientPK;
        if (insurance) query += '&insurance=' + insurance;
        if (paymentCheckNo) query += '&reference_check_number=' + paymentCheckNo;
        if (payment_date_from) {
            query += '&payment_date_from=' + format(payment_date_from, 'yyyy-MM-dd');
        }
        if (payment_date_to) {
            query += '&payment_date_to=' + format(payment_date_to, 'yyyy-MM-dd');
        }
        if (payment_entered_date_from) {
            query += '&entered_date_from=' + format(payment_entered_date_from, 'yyyy-MM-dd');
        }
        if (payment_entered_date_to) {
            query += '&entered_date_to=' + format(payment_entered_date_to, 'yyyy-MM-dd');
        }
        if (provider) query += '&provider_pk=' + provider;
        if (hcpcs) query += '&cpt_pk=' + hcpcs;
        if (revenue_codes) query += '&revenue_pk=' + revenue_codes;
        if (icd) query += '&icd=' + icd;
        let result = null;
        result = service.ListPayments(query);
        result.then(response => {
            if (response.data?.results !== undefined) {
                setTotalPage(Math.ceil(response.data.count / response.data.page_size));
                setActivePage(page);

                let anArray = [];
                let rowArray = [];
                let newData = Array(response.data?.results?.length).fill(JSON.parse(JSON.stringify(ViewPaymentsTableObject.tableBodyData[0])));
                newData.map((row, rowIndex) => {
                    row.map((col) => {
                        let colObject = {};
                        colObject = {
                            ...col, 'value': response.data.results[rowIndex][col.name],
                            "id": response.data.results[rowIndex].id
                        }
                        anArray.push(colObject)
                    })
                    rowArray.push(anArray)
                    anArray = [];
                });

                if (rowArray.length > 0) {
                    ViewPaymentsTableObject.tableBodyData = rowArray;
                }
                else {
                    ViewPaymentsTableObject.tableBodyData = ViewPaymentsTableBodyOnly;
                }
                setPaymentDatas(response.data.results || []);
            } else {
                showNotifyWindow('show', 'error', i18n.t('errorMessages.commonError'));
                ViewPaymentsTableObject.tableBodyData = ViewPaymentsTableBodyOnly;
                return;
            }
        }).catch((err) => {
            console.error("An error occured while fetching data:", err)
        }).finally(() => {
            setShowLoadingBar(false);
        })
    }


    function onTabChange(e, tab) {
        if (activeTab === tab) {
            return
        }
        setActiveTab(tab);
    }

    useEffect(() => {
        if (selectedEditPaymentPK) {
            setActiveTab('editPayments');
        } else {
            setActiveTab('viewPayments');
        }
    }, [selectedEditPaymentPK]);

    useEffect(() => {
        if (activeTab === 'viewPayments') {
            onViewPayments();
        }
    }, [activeTab]);

    function onLinkClick(idValue) {
        let obj = paymentDatas.find(item => item.id == idValue);
        onEditPayment(obj.custom_payment_id, obj.id, obj.payment_sub_pk, obj.payment_type, obj.patient_pk, obj.post_type);
        dispatch(removeAllPaymentDetails())
    }

    function onEditPayment(paymentID, paymentPK, paymentSubPK, paymentType, patientSubPK, postType) {
        // item.custom_payment_id, item.id, item.payment_sub_pk, item.payment_type, item.patient_pk, item.post_type
        if (selectedEditPaymentPK && selectedEditPaymentPK !== paymentPK)
            showNotifyWindow('show', 'error', i18n.t('errorMessages.max_payment_tabs'));
        else {
            setSelectedEditPaymentPK(paymentPK);
            setSelectedEditPaymentSubPK(paymentSubPK);
            setSelectedEditPaymentType(paymentType);
            setSelectedEditPatientPK(patientSubPK);
            setSelectedEditPatientPostType(postType);
            setSelectedEditPaymentID(paymentID);
            setActiveTab('editPayments');
        }
    }

    function onClosePayment() {
        setSelectedEditPaymentPK("");
        setPatientPK("");
        setActiveTab('viewPayments');
        dispatch(removeAllPaymentDetails())
    }

    function resetSearch() {
        setPayment_from("");
        setInsurance("");
        setPaymentCheckNo("");
        setPayment_date_from("");
        setPayment_date_to("");
        setPatientPK("");
        setPayment_entered_date_from("");
        setPayment_entered_date_to("");
        setProvider([]);
        setHcpcs([]);
        setRevenue_codes([]);
        setIcd([]);
        setClearSelectedPatient((clearSelectedPatient + 1));
        document.querySelector('button[aria-label="Clear"]')?.click();
        setViewPaymentsTableReset(true);
    }

    useEffect(() => {
        if (viewPaymentsTableReset) {
            getPayments(DEFAULT_PAGING_SIZE, 1);
            setViewPaymentsTableReset(false);
        }
    }, [viewPaymentsTableReset])

    return (
        <React.Fragment>
            <Notify showNotify={showNotify} setShowNotify={setShowNotify} notifyDescription={notifyDescription} setNotifyType={setNotifyType} setNotifyDescription={setNotifyDescription} notifyType={notifyType} />
            <div className="col-md-12 min-height-500">
                <div className="box">
                    <Form autoComplete="off">
                        <TabContext value={activeTab}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <StyledTabList onChange={onTabChange} aria-label="lab API tabs example"
                                    TabIndicatorProps={{

                                        children: <CustomIndicator />, // Assigning the custom indicator here
                                    }}>
                                    <Tab label={i18n.t("payments.viewPayments.viewPaymentsTab")} value={"viewPayments"} className="tabMainMenu" />
                                    {selectedEditPaymentPK &&
                                        <Tab label={
                                            <span className="">
                                                {i18n.t("payments.viewPayments.updatePayment")} - {selectedEditPaymentID}
                                                <span className="closeIconCommonTab margin-left10" onClick={()=>onClosePayment()}>
                                                    <i className="fa fa-times" />
                                                </span>
                                            </span>}
                                        value={"editPayments"} className="tabMainMenu" />
                                    }
                                </StyledTabList>
                            </Box>
                            <TabPanel value={"viewPayments"} className="bordering border-radius">
                                <div className="">
                                    <div className="mb-1 mt-3">
                                        <Form autoComplete="off">
                                            <div className="row margin-top-m15">
                                                <div className="col-5">
                                                    <SelectInput name="payment_from" data={POST_PAYMENT_PRIMARY_TYPES} value={payment_from} onValueChange={onHandleChange} label={i18n.t("payments.viewPayments.payment_from")} />
                                                </div>
                                                <div className="row col-5 pl-0 margin-right0 padding-right0" >
                                                    <PatientDropDownSearch setSelectPatient={""} setPatientPK={setPatientPK} patientSelected={patientSelected} setPatientSelected={setPatientSelected} patientAdvSearchData={patientAdvSearchData} clearSelectedPatient={clearSelectedPatient} 
                                                    fullWidth={true} 
                                                    noRightMargin={true}></PatientDropDownSearch>
                                                </div>
                                                <div className='margin-top25 col-2 justify-right pr-0' >
                                                    <div className='justify-right'> 
                                                        <RightSidePanel title={i18n.t("commons.advancedSearch")} onclickLabel={i18n.t("commons.advancedSearch")} >
                                                            <PatientsAdvancedSearch setSelectPatient={""} setPatientPK={setPatientPK} setPatientSelected={setPatientSelected} setPatientAdvSearchData={setPatientAdvSearchData} >
                                                            </PatientsAdvancedSearch>
                                                        </RightSidePanel>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-5">
                                                    <SelectInput data={insuranceCompaniesList} name="insurance" value={insurance} onValueChange={onHandleChange} label={i18n.t("payments.viewPayments.insurance")} />
                                                </div>
                                                <div className="col-5 pl-0">
                                                    <TextInput name="reference_check_number" value={paymentCheckNo} onValueChange={onHandleChange} label={i18n.t("payments.viewPayments.payment_reference")} />
                                                </div>
                                        
                                            </div>

                                            <div className="row">
                                                <div className='col-5'>
                                                    <div className="justify-space-between d-flex">
                                                        <div className="flex-grow-1">
                                                            <CalendarInput name="payment_date_from" selected={payment_date_from} onValueChange={(e) => onHandleDate(e, "payment_date_from")} label={i18n.t("payments.viewPayments.payment_date_from")} />
                                                        </div>
                                                        <div style={{minWidth: "15px" }}> </div>
                                                        <div className="flex-grow-1">
                                                            <CalendarInput name="payment_date_to" minDate={payment_date_from} selected={payment_date_to} onValueChange={(e) => onHandleDate(e, "payment_date_to")} label={i18n.t("payments.viewPayments.payment_date_to")} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='col-5 pl-0'  >
                                                    <div className="justify-space-between  d-flex" >
                                                        <div className="flex-grow-1">
                                                            <CalendarInput name="payment_entered_date_from" selected={payment_entered_date_from} onValueChange={(e) => onHandleDate(e, "payment_entered_date_from")} label={i18n.t("payments.viewPayments.payment_entered_date_from")} />
                                                        </div>
                                                        <div style={{minWidth: "15px" }}> </div>
                                                        <div className="flex-grow-1">
                                                            <CalendarInput name="payment_entered_date_to" minDate={payment_entered_date_from} selected={payment_entered_date_to} onValueChange={(e) => onHandleDate(e, "payment_entered_date_to")} label={i18n.t("payments.viewPayments.payment_entered_date_to")} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-5">
                                                    <MaterialMultiselect name="provider" value={provider} onValueChange={onHandleChange}
                                                            options={providerList} required={false} label={i18n.t("payments.viewPayments.provider")}
                                                    />
                                                    {/* <SelectInput name="provider" data={providerList} value={provider} onValueChange={onHandleChange} label={i18n.t("payments.viewPayments.provider")} /> */}
                                                </div>
                                                <div className="col-5 pl-0">
                                                    <MaterialMultiselect name="hcpcs" value={hcpcs} onValueChange={onHandleChange}
                                                            options={CPTList} label={i18n.t("payments.viewPayments.cpt/hcpcs")}
                                                    />
                                                    {/* <SelectInput name="hcpcs" data={CPTList} value={hcpcs} onValueChange={onHandleChange} label={i18n.t("payments.viewPayments.cpt/hcpcs")} /> */}
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className="col-5">
                                                    <MaterialMultiselect name="revenue_codes" value={revenue_codes} onValueChange={onHandleChange}
                                                        options={revenueCodeList} label={i18n.t("payments.viewPayments.revenue_codes")}
                                                    />
                                                    {/* <SelectInput data={revenueCodeList} name="revenue_codes" value={revenue_codes} onValueChange={onHandleChange} label={i18n.t("payments.viewPayments.revenue_codes")} /> */}
                                                </div>
                                                <div className="col-5 pl-0">
                                                    <MaterialMultiselect name="icd" value={icd} onValueChange={onHandleChange}
                                                            options={ICDList} label={i18n.t("payments.viewPayments.icd10/icd9")}
                                                    />
                                                    {/* <SelectInput data={ICDList} name="icd" value={icd} onValueChange={onHandleChange} label={i18n.t("payments.viewPayments.icd10/icd9")} /> */}
                                                </div>
                                                <div className="col-2 justify-right margin-top25">
                                                    <div className="margin-right15">
                                                        <CommonButton onClick={resetSearch} variant="outlined"
                                                            label={i18n.t("buttons.reset")}
                                                        />
                                                    </div>
                                                    <div className="justify-right">
                                                        <CommonButton onClick={onViewPayments} variant="contained"
                                                            label={i18n.t("payments.viewPayments.search")}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="col justify-right ml-3">
                                                <RightSidePanel title={i18n.t("commons.advancedSearch")} onclickLabel={i18n.t("commons.advancedSearch")} >
                                                    <PatientsAdvancedSearch setSelectPatient={""} setPatientPK={setPatientPK} setPatientSelected={setPatientSelected} setPatientAdvSearchData={setPatientAdvSearchData} >
                                                    </PatientsAdvancedSearch>
                                                </RightSidePanel> 
                                            </div> */}
                                        </Form>
                                    </div>
                                </div>
                            </TabPanel>
                            {selectedEditPaymentPK &&
                                <TabPanel value={"editPayments"} className="bordering">
                                    <PostPayment updatePayment={true} setActiveTab={setActiveTab} onClosePayment={onClosePayment} selectedEditPaymentPK={selectedEditPaymentPK} setSelectedEditPaymentPK={setSelectedEditPaymentPK} selectedEditPaymentSubPK={selectedEditPaymentSubPK} selectedEditPaymentType={selectedEditPaymentType} selectedEditPtientPK={selectedEditPtientPK}
                                        selectedEditPatientPostType={selectedEditPatientPostType} selectedClaim={selectedClaim} claimID={selectedClaimID} customClaimID={selectedCustomClaimID} />
                                </TabPanel>
                            }
                        </TabContext>
                    </Form>
                    {activeTab == "viewPayments" ?
                    <div className='padding-top25 margin-right20'>
                        <div style={{ overflowX: "auto"}}>
                            <Table tableObject={ViewPaymentsTableObject} onLinkClick={onLinkClick} tblRadiusClass={' table-border-radius8 '} />
                        </div>
                        <div className='mt-4'>
                            <Pagination totalPage={totalPage} activePage={activePage} startIndex={startIndex} endIndex={endIndex} onPagePrevious={onPagePrevious} onPageUp={onPageUp} onPageNext={onPageNext} />
                            {totalPage <= 1 && <div className="clear-b-scroll-sector">&nbsp;</div>}
                            <div className="height-75">&nbsp;</div>
                        </div>
                    </div> : ""}
                </div>
            </div>
        </React.Fragment>
    )
}

export default ViewPayments;
