import i18n from "../../../utilities/i18n";

const commonWidth  = {
    extraSmall: "extra-small",
    small: "small",
    medium: "medium",
    large:"large"

}

const tableData = [
    {
        "head": {
            name: i18n.t("reports.visit_id"),
            type: "number",
            width: commonWidth.extraSmall,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "claim_reference_number",
            value: "",
            type: "string",
            needLink: true,
            align: "center",
            width: commonWidth.extraSmall,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.claim_id"),
            type: "number",
            width: commonWidth.extraSmall,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "custom_claim_id",
            value: "",
            type: "string",
            needLink: true,
            align: "center",
            width: commonWidth.extraSmall,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.accession_number"),
            type: "number",
            width: commonWidth.extraSmall,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "accession_number",
            value: "",
            type: "string",
            needLink: true,
            align: "center",
            width: commonWidth.extraSmall,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.patient_id"),
            type: "number",
            width: commonWidth.extraSmall,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "custom_patient_id",
            value: "",
            type: "string",
            needLink: true,
            align: "center",
            width: commonWidth.extraSmall,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.firstName"),
            type: "number",
            width: commonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "patient_first_name",
            value: "",
            type: "string",
            needLink: true,
            align: "center",
            width: commonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.lastName"),
            type: "number",
            width: commonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "patient_last_name",
            value: "",
            type: "string",
            needLink: true,
            align: "center",
            width: commonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.middleName"),
            type: "number",
            width: commonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "patient_middle_name",
            value: "",
            type: "string",
            needLink: true,
            align: "center",
            width: commonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.gender"),
            type: "number",
            width: commonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "patient_gender",
            value: "",
            type: "string",
            needLink: true,
            align: "center",
            width: commonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.DOB"),
            type: "number",
            width: commonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "date_of_birth",
            value: "",
            type: "string",
            needLink: true,
            align: "center",
            width: commonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.dateOfEntry"),
            type: "number",
            width: commonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "entered_date",
            value: "",
            type: "string",
            needLink: true,
            align: "center",
            width: commonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.firstBilledDate"),
            type: "number",
            width: commonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "first_billed_date",
            value: "",
            type: "string",
            needLink: true,
            align: "center",
            width: commonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.lastBilledDate"),
            type: "number",
            width: commonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "last_billed_date",
            value: "",
            type: "string",
            needLink: true,
            align: "center",
            width: commonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.dateOfServiceFrom"),
            type: "number",
            width: commonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "service_from_date",
            value: "",
            type: "string",
            needLink: true,
            align: "center",
            width: commonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.dateOfServiceTo"),
            type: "number",
            width: commonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "service_to_date",
            value: "",
            type: "string",
            needLink: true,
            align: "center",
            width: commonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.refferingProvider"),
            type: "number",
            width: commonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "referring_provider_name",
            value: "",
            type: "string",
            needLink: true,
            align: "center",
            width: commonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.refferingProviderNPI"),
            type: "number",
            width: commonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "referring_provider_npi",
            value: "",
            type: "string",
            needLink: true,
            align: "center",
            width: commonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.renderingProvider"),
            type: "number",
            width: commonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "rendering_provider_name",
            value: "",
            type: "string",
            needLink: true,
            align: "center",
            width: commonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.renderingProviderNPI"),
            type: "number",
            width: commonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "rendering_provider_npi",
            value: "",
            type: "string",
            needLink: true,
            align: "center",
            width: commonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.billingProvider"),
            type: "number",
            width: commonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "billing_provider_name",
            value: "",
            type: "string",
            needLink: true,
            align: "center",
            width: commonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.billingProviderNPI"),
            type: "number",
            width: commonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "billing_provider_npi",
            value: "",
            type: "string",
            needLink: true,
            align: "center",
            width: commonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.serviceLocation"),
            type: "number",
            width: commonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "service_location",
            value: "",
            type: "string",
            needLink: true,
            align: "center",
            width: commonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.cptcode"),
            type: "number",
            width: commonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "cpt_name",
            value: "",
            type: "string",
            needLink: true,
            align: "center",
            width: commonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.units"),
            type: "number",
            width: commonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "fee_units",
            value: "",
            type: "string",
            needLink: true,
            align: "center",
            width: commonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.modifier1"),
            type: "number",
            width: commonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "m1_name",
            value: "",
            type: "string",
            needLink: true,
            align: "center",
            width: commonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.modifier2"),
            type: "number",
            width: commonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "m2_name",
            value: "",
            type: "string",
            needLink: true,
            align: "center",
            width: commonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.modifier3"),
            type: "number",
            width: commonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "m3_name",
            value: "",
            type: "string",
            needLink: true,
            align: "center",
            width: commonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.modifier4"),
            type: "number",
            width: commonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "m4_name",
            value: "",
            type: "string",
            needLink: true,
            align: "center",
            width: commonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.ICD_list"),
            type: "number",
            width: commonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "icds",
            value: "",
            type: "string",
            needLink: true,
            align: "center",
            width: commonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.cpt_charge_amount"),
            type: "number",
            width: commonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "cpt_charge_amount",
            value: "",
            type: "string",
            needLink: true,
            align: "center",
            width: commonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.insurancePayment"),
            type: "number",
            width: commonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "insurance_payments",
            value: "",
            type: "string",
            needLink: true,
            align: "center",
            width: commonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.patientPayments"),
            type: "number",
            width: commonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "patient_payments",
            value: "",
            type: "string",
            needLink: true,
            align: "center",
            width: commonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.totalPayments"),
            type: "number",
            width: commonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "total_payments",
            value: "",
            type: "string",
            needLink: true,
            align: "center",
            width: commonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.insuranceAdjustments"),
            type: "number",
            width: commonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "insurance_adjustments",
            value: "",
            type: "string",
            needLink: true,
            align: "center",
            width: commonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.patientWriteoff"),
            type: "number",
            width: commonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "patient_write_offs",
            value: "",
            type: "string",
            needLink: true,
            align: "center",
            width: commonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.totalAdjustments"),
            type: "number",
            width: commonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "total_adjustments",
            value: "",
            type: "string",
            needLink: true,
            align: "center",
            width: commonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.insuranceBalance"),
            type: "number",
            width: commonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "insurance_balance",
            value: "",
            type: "string",
            needLink: true,
            align: "center",
            width: commonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.patientBalance"),
            type: "number",
            width: commonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "patient_balance",
            value: "",
            type: "string",
            needLink: true,
            align: "center",
            width: commonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.totalBalance"),
            type: "number",
            width: commonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "total_balance",
            value: "",
            type: "string",
            needLink: true,
            align: "center",
            width: commonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.currentPayerType"),
            type: "number",
            width: commonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "current_resp_payer_type",
            value: "",
            type: "string",
            needLink: true,
            align: "center",
            width: commonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.current_payer_name"),
            type: "number",
            width: commonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "current_payer_name",
            value: "",
            type: "string",
            needLink: true,
            align: "center",
            width: commonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.current_payer_id"),
            type: "number",
            width: commonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "current_payer_id",
            value: "",
            type: "string",
            needLink: true,
            align: "center",
            width: commonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.current_payer_policy_id"),
            type: "number",
            width: commonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "current_policy_id",
            value: "",
            type: "string",
            needLink: true,
            align: "center",
            width: commonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.current_group_no"),
            type: "number",
            width: commonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "current_group_number",
            value: "",
            type: "string",
            needLink: true,
            align: "center",
            width: commonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.primary_payer_name"),
            type: "number",
            width: commonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "primary_payer_name",
            value: "",
            type: "string",
            needLink: true,
            align: "center",
            width: commonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.primary_payer_id"),
            type: "number",
            width: commonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "primary_payer_id",
            value: "",
            type: "string",
            needLink: true,
            align: "center",
            width: commonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.primary_policy_id"),
            type: "number",
            width: commonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "primary_policy_id",
            value: "",
            type: "string",
            needLink: true,
            align: "center",
            width: commonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.primary_group_no"),
            type: "number",
            width: commonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "primary_group_number",
            value: "",
            type: "string",
            needLink: true,
            align: "center",
            width: commonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.secondary_payer_name"),
            type: "number",
            width: commonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "secondary_payer_name",
            value: "",
            type: "string",
            needLink: true,
            align: "center",
            width: commonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.secondary_payer_id"),
            type: "number",
            width: commonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "secondary_payer_id",
            value: "",
            type: "string",
            needLink: true,
            align: "center",
            width: commonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.secondary_policy_id"),
            type: "number",
            width: commonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "secondary_policy_id",
            value: "",
            type: "string",
            needLink: true,
            align: "center",
            width: commonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.secondary_group_no"),
            type: "number",
            width: commonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "secondary_group_number",
            value: "",
            type: "string",
            needLink: true,
            align: "center",
            width: commonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.ageFromDos"),
            type: "number",
            width: commonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "age_from_dos",
            value: "",
            type: "string",
            needLink: true,
            align: "center",
            width: commonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.ageFromDateofEntry"),
            type: "number",
            width: commonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "age_from_date_of_entry",
            value: "",
            type: "string",
            needLink: true,
            align: "center",
            width: commonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.agefromfirstbillDate"),
            type: "number",
            width: commonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "age_from_first_billed_date",
            value: "",
            type: "string",
            needLink: true,
            align: "center",
            width: commonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.agefromlstbillDate"),
            type: "number",
            width: commonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "age_from_last_billed_date",
            value: "",
            type: "string",
            needLink: true,
            align: "center",
            width: commonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.claimStatus"),
            type: "number",
            width: commonWidth.medium,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "claim_status",
            value: "",
            type: "string",
            needLink: true,
            align: "center",
            width: commonWidth.medium,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.claimSubStatus"),
            type: "number",
            width: commonWidth.medium,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "claim_sub_status",
            value: "",
            type: "string",
            needLink: true,
            align: "center",
            width: commonWidth.medium,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.patientNote"),
            type: "number",
            width: commonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "patient_notes",
            value: "",
            type: "string",
            needLink: true,
            align: "center",
            width: commonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.lastPatientNoteEnteredBy"),
            type: "number",
            width: commonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "last_patient_note_entered_by",
            value: "",
            type: "string",
            needLink: true,
            align: "center",
            width: commonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.claimNote"),
            type: "number",
            width: commonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "claim_notes",
            value: "",
            type: "string",
            needLink: true,
            align: "center",
            width: commonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.arNotes"),
            type: "number",
            width: commonWidth.large,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "ar_notes",
            value: "",
            type: "string",
            needLink: true,
            align: "center",
            width: commonWidth.large,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.arNoteEnteredDate"),
            type: "number",
            width: commonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "ar_notes_entered_date",
            value: "",
            type: "string",
            needLink: true,
            align: "center",
            width: commonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.arActionCode"),
            type: "number",
            width: commonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "ar_action",
            value: "",
            type: "string",
            needLink: true,
            align: "center",
            width: commonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": { 
            name: i18n.t("reports.arStatusCode"),
            type: "number",
            width: commonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "ar_status",
            value: "",
            type: "string",
            needLink: true,
            align: "center",
            width: commonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.lastClaimNoteEnteredBy"),
            type: "number",
            width: commonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "last_claim_note_entered_by",
            value: "",
            type: "string",
            needLink: true,
            align: "center",
            width: commonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.claimEnteredBy"),
            type: "number",
            width: commonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "entered_by",
            value: "",
            type: "string",
            needLink: true,
            align: "center",
            width: commonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.lab_sales_report"),
            type: "number",
            width: commonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "lab_sales_report",
            value: "",
            type: "string",
            needLink: true,
            align: "center",
            width: commonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.clinic_site_name"),
            type: "number",
            width: commonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "clinic_site_name",
            value: "",
            type: "string",
            needLink: true,
            align: "center",
            width: commonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.claim_service_type"),
            type: "number",
            width: commonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "claim_service_type",
            value: "",
            type: "string",
            needLink: true,
            align: "center",
            width: commonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.pos"),
            type: "number",
            width: commonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "pos",
            value: "",
            type: "string",
            needLink: true,
            align: "center",
            width: commonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.tos"),
            type: "number",
            width: commonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "tos",
            value: "",
            type: "string",
            needLink: true,
            align: "center",
            width: commonWidth.small,
            hideValue: true,
        }
    }
]

export const ReportTableData = {
    "name": "open-ar-report-fixed-head",
    "isResize": false,
    "isReportTable": true,
    "isFixedHead": true,
    "tableHeadings": tableData.map(item => item.head),
    "tableBodyData": [tableData.map(item => item.body)]
}
