import React, { useContext, useEffect, useState } from "react";
import Table from "../../../commons/Table/Table";
import { DemoMergeTableData, tableData } from "./patientMergeTable";
import { commonTableBody } from "../../../../utilities/commonUtilities";
import service from "../service";
import { getStorage } from "../../../../utilities/browserStorage";
import LoadingContext from "../../../../container/loadingContext";

const PatientMerge = (props) => {
    let { patientDetails,patientAddressDetails,mergePatientSelected,setMergePatientSelected } = props;
    const practice_pk = getStorage('practice');
    const setShowLoadingBar = useContext(LoadingContext);
    const [patientMergeList, setPatientMergeList] = useState([]);
    const [patientMergeIsCheckedAll, setPatientMergeIsCheckedAll] = useState(false);

    useEffect(() => {
        getMergePatientDataList();
    }, [])

    /**
     * function to build query parms for the api
     */
    const queryBuilder=()=>{
        let {date_of_birth,first_name,last_name,middle_name,gender,custom_patient_id}=patientDetails;
        let {location_data}=patientAddressDetails;
        let {street1,street2,city,state,country,zip_code}=location_data;
        let query = '?list_type=merge_patient' + '&practice_pk=' + practice_pk + '&filter=1'+
        '&last_name=' + last_name + '&first_name=' + first_name + '&middle_name='+ middle_name +'&gender='+gender+
        '&dob='+date_of_birth+'&street1='+street1+'&street2='+street2+'&city='+city+
        '&state='+state+'&country='+country+'&zip_code='+zip_code+
        '&patient_id='+custom_patient_id+'&active_status=1';

        return query;
    }

    /**
     * function to get similar patient to merge 
     */
    const getMergePatientDataList = async () => {
        setShowLoadingBar(true);
        if (patientDetails && patientAddressDetails) {
            try {

                const query=queryBuilder();
                const result = await service.ListPatients(query);
                if (result.data.results.length > 0) {
                    setShowLoadingBar(false);
                    const rowArray = commonTableBody(
                        result.data.results,
                        DemoMergeTableData.tableBodyData[0]
                    );
                    DemoMergeTableData.tableBodyData = rowArray;
                    setPatientMergeList(result.data.results);
                } else {
                    setPatientMergeList([]);
                    DemoMergeTableData.tableBodyData = [tableData.map(item => item.body)]; 
                    setShowLoadingBar(false);
                }
            } catch (error) {
                console.error(`An unexpected error occured in listing patient:${JSON.stringify(error)}`);
                setShowLoadingBar(false);
            }
        }
    }

    /**
     * function to make checkbox clickable
     * @param {*} e 
     * @param {*} id 
     */
    const onClickGridCheckBox = (e, calledFrom, id) => {
        if (calledFrom == 'data') {
            handleCheckedOnChange(e, id);
        }
        else if (calledFrom == 'header') {
            handleAllCheckedOnChange(e);
        }
    }

    /**
     * function to handle header check box click
     * @param {*} e 
     */
    const handleAllCheckedOnChange = (e) => {
        let currentSelectedIds = [];
        if (e.target.checked) {
            DemoMergeTableData.tableBodyData.forEach(rowItem => {
                let index = rowItem.findIndex(colItem => colItem.type == 'checkbox');
                rowItem[index].value = true;
            });
            setPatientMergeIsCheckedAll(true);
            patientMergeList.forEach((item) => {
                currentSelectedIds.push(item.id);
            })
            setMergePatientSelected(currentSelectedIds);
        } else {
            DemoMergeTableData.tableBodyData.forEach(rowItem => {
                let index = rowItem.findIndex(colItem => colItem.type == 'checkbox');
                rowItem[index].value = false;
            });
            patientMergeList.forEach((item) => {
                let index = currentSelectedIds.indexOf(item.id);
                currentSelectedIds.splice(index, 1);
            })
            setMergePatientSelected(currentSelectedIds);
            setPatientMergeIsCheckedAll(false);
        }
    }

    /**
     * function to handle check box click by each patient 
     * @param {*} e 
     * @param {*} id 
     */
    const handleCheckedOnChange = (e, id) => {
        DemoMergeTableData.tableBodyData.forEach(rowItem => {
            if (rowItem.find(colItem => colItem.id == id)) {
                let rowIndex = rowItem.findIndex(cItem => cItem.type == 'checkbox');
                rowItem[rowIndex].value = e.target.checked;
            }
        });
        let currentSelectedIds = [];
        let pageAllItemSelected = true;
        mergePatientSelected.forEach((item) => {
            currentSelectedIds.push(item);
        })
        setMergePatientSelected([]);
        let index = currentSelectedIds.indexOf(id);
        if (e.target.checked) {
            if (index === -1) {
                currentSelectedIds.push(id);
            }

        }
        else {
            if (index !== -1) {
                currentSelectedIds.splice(index, 1);
            }
        }
        setMergePatientSelected(currentSelectedIds);
        patientMergeList.forEach((item) => {
            let checkedItem = currentSelectedIds.indexOf(item.id);
            if (checkedItem === -1) {
                pageAllItemSelected = false;
            }
        })
        if (pageAllItemSelected) {
            setPatientMergeIsCheckedAll(true);
        } else {
            setPatientMergeIsCheckedAll(false);
        }
    }

    return (
        <div style={{ marginTop: 20 }}>
            <Table
                tableObject={DemoMergeTableData}
                list={patientMergeList}
                onClickGridCheckBox={(e, calledFrom, id) => onClickGridCheckBox(e, calledFrom, id)}
                isCheckboxDisabled={true}
                checkedHeaderCheckBox={patientMergeIsCheckedAll}
            />
        </div>
    )
}


export default PatientMerge;