import React, { useContext, useEffect, useState } from 'react'
import { Form } from 'react-bootstrap';
import { Redirect, useHistory } from 'react-router-dom';

import { ClaimsFilterTableData, TableBodyData } from './ClaimsFilterTable';

import LoadingContext from '../../../container/loadingContext';

import i18n from '../../../utilities/i18n';
import { getStorage, setStorage } from '../../../utilities/browserStorage';
import { checkPermission, commonTableBody, formatDateUS } from '../../../utilities/commonUtilities';
import { ROUTE_PATIENTS_LIST, ROUTE_SEARCH_CLAIMS, MAX_OPEN_TABS_CLAIMS, MAX_OPEN_TABS_PATIENTS, PAGING_END_INDEX, ROUTE_INSURANCE_COMPANIES_EDIT, DEFAULT_PAGING_SIZE } from '../../../utilities/staticConfigs';
import { CLAIM_TYPES, PAYER_TYPE_LIST, ICD, RESPONSIBILITY_TYPES } from '../../../utilities/dictionaryConstants';

import Table from '../../commons/Table/Table';
import CalendarInput from '../../commons/input/CalendarInput';
import SelectInput from '../../commons/input/SelectInput';
import RightSidePanel from '../../commons/RightSidePanel/RightSidePanel';
import Pagination from '../../commons/pagination';
import Notify from '../../commons/notify';
import searchClaimService from '../services/claim_service';
import PatientsAdvancedSearch from '../../patientManagement/patients/PatientsAdvancedSearch';
import PatientDropDownSearch from '../../patientManagement/patients/patientDropDownSearch';

import { MaterialMultiselect } from '../../../MaterialMultiselect/MaterialMultiselect';

import CustomizedSmallDialogs from '../../modalWindowComponent/CustomisedSmallDialog';
import MaterialMultiSelectSearch from '../../../MaterialMultiselect/MaterialMultiSelectSearch';
import ExportIconButton from '../../commons/exportIconButton';
import { permission_key_values_claim } from '../../../utilities/permissions';
import CommonButton from '../../commons/Buttons';
import { getFilterDataSet, resetBulkStatusData, resetResponsibilityType } from '../StateManagement/claimFilterSlice';
import { useSelector, useDispatch } from 'react-redux';

function ClaimsFilter() {
    const { dropDownListData,
        isErrorGettingDropdownData,
        isFilterDropDownDataReceived, responsibilityList, responsibility_type } = useSelector((state) => state.claimFilter);
    const searchQueryInput = useSelector((state) => state.claimFilter.searchQuery);
    const sorting = useSelector((state) => state.claimFilter.sorting);
    const { orderType, claim_order_type, startIndex, endIndex, activePage, selectedClaimIDs,
        allchecked, claimBulkStatusUpdate, searched, reseted, validated, bulkupdated,
        patientSelectedData, patient_PK
    } = useSelector((state) => state.claimFilter);
    const dispatch = useDispatch();
    const history = useHistory();
    const practicePK = getStorage("practice")
    const filterClaimTableOpened = getStorage('filterClaimTableOpened');
    const [totalPage, setTotalPage] = useState(1);
    const setShowLoadingBar = useContext(LoadingContext);
    const [patientPK, setPatientPK] = useState("");
    const [patientSelected, setPatientSelected] = useState([]);
    const [filterClaimsData, setFilterClaimsData] = useState([]);
    const [showNotify, setShowNotify] = useState('hide');
    const [notifyDescription, setNotifyDescription] = useState('');
    const [notifyType, setNotifyType] = useState('success');
    const [redirectToPatient, setRedirectToPatient] = useState(false);
    const [redirectToClaim, setRedirectToClaim] = useState(false);
    const [checkedClaim, setCheckedClaim] = useState([]);
    const [checkedAllClaim, setCheckedAllClaim] = useState(false);
    const [formWarningData, setFormWarningData] = useState([]);
    const [formWarningStatus, setFormWarningStatus] = useState(false);
    const [statusUpdateSuccessMessage, setStatusUpdateSuccessMessage] = useState("");
    const [statusUpdateAlertModal, setStatusUpdateAlertModal] = useState(false);
    const [validatedCount, setValidatedCount] = useState(0);
    const [validateAlertModal, setValidateAlertModal] = useState(false);
    const [statusUpdateConfirmationModal, setStatusUpdateConfirmationModal] = useState(false);
    const [statusUpdateWarningModal, setStatusUpdateWarningModal] = useState(false);
    const [statusUpdateConfirmationMessage, setStatusUpdateConfirmationMessage] = useState("");
    const [patientAdvSearchData, setPatientAdvSearchData] = useState([]);
    const [isLoading, setIsLoading] = useState({ loading: false, id: 0 });
    const [patientRemoved, setPatientRemoved] = useState(true);

    // eslint-disable-next-line no-undef
    var fileDownload = require('js-file-download');
    const [clearAMMSelection, setClearAMMSelection] = useState(0);

    // table column sorting
    ClaimsFilterTableData.tableHeadings[1].initialOrdering = sorting.initialOrderingClaimId;
    ClaimsFilterTableData.tableHeadings[1].orderType = claim_order_type;
    ClaimsFilterTableData.tableHeadings[3].initialOrdering = sorting.initialOrderingPatientName;
    ClaimsFilterTableData.tableHeadings[3].orderType = orderType;
    ClaimsFilterTableData.tableHeadings[4].initialOrdering = sorting.initialOrderingDos;
    ClaimsFilterTableData.tableHeadings[4].orderType = orderType;


    useEffect(() => {
        if (!checkedAllClaim) {
            dispatch({ type: "ClaimFilter/bulkStatusUpdate", payload: { value: "" } });
        }
    }, [checkedAllClaim]);

    // Display error notification if any error occurred while fetching filter dropdown list data
    useEffect(() => {
        if (isErrorGettingDropdownData === true)
            showNotifyWindow('show', 'error', i18n.t('errorMessages.error_getting_dropdown_data'));
    }, [isErrorGettingDropdownData]);

    useEffect(() => {
        if (patientSelected.length > 0) {
            dispatch({
                type: "ClaimFilter/patientAdvanceSearchData", payload: {
                    patient_selected: patientSelected,
                    patient_advance_data: patientAdvSearchData,
                    patient_pk: patientPK
                }
            })
        }
    }, [patientSelected])

    useEffect(() => {
        if (patientRemoved) {
            dispatch({
                type: "ClaimFilter/patientAdvanceSearchData", payload: {
                    patient_selected: [],
                    patient_advance_data: [],
                    patient_pk: "",
                }
            })
            setPatientRemoved(false);
        }
    }, [patientRemoved])

    function showNotifyWindow(action, type, desc, age = 3000) {
        if (action == 'show') {
            setTimeout(() => {
                setShowNotify('hide');
            }, age)
        }
        setShowNotify(action);
        setNotifyType(type);
        setNotifyDescription(desc);
    }

    function onPagePrevious() {
        let previousPage = startIndex + 1 - PAGING_END_INDEX;
        dispatch({ type: "ClaimFilter/setActivePage", payload: { active: previousPage } });
        if (startIndex !== 0) {
            let start = startIndex - PAGING_END_INDEX;
            let end = endIndex - PAGING_END_INDEX;
            dispatch({ type: "ClaimFilter/onPagePrevious", payload: { startIndex: start, endIndex: end } });
        }
        getFilterClaimsData(DEFAULT_PAGING_SIZE, previousPage);
    }

    function onPageUp(e) {
        let page = Number(e.target.id)
        dispatch({ type: "ClaimFilter/setActivePage", payload: { active: page } });
        getFilterClaimsData(DEFAULT_PAGING_SIZE, page);
    }

    function onPageNext() {
        let nextPage = startIndex + 1 + PAGING_END_INDEX;
        if (endIndex === totalPage || totalPage <= PAGING_END_INDEX) {
            dispatch({ type: "ClaimFilter/setActivePage", payload: { active: nextPage } });
            dispatch({ type: "ClaimFilter/onPageNext", payload: { startIndex: startIndex, endIndex: endIndex } });

        } else {
            dispatch({ type: "ClaimFilter/setActivePage", payload: { active: nextPage } });
            let start = startIndex + PAGING_END_INDEX;
            let end = endIndex + PAGING_END_INDEX;
            dispatch({ type: "ClaimFilter/onPageNext", payload: { startIndex: start, endIndex: end } });

        }
        getFilterClaimsData(DEFAULT_PAGING_SIZE, nextPage);
    }

    function onHandleChange(e) {
        if (e.target) {
            const { name, value } = e.target;
            const trimmedValue = ["claimStatus", "claimType", "provider", "serviceLocation", "claimBulkStatusUpdate", "currentPayer", "primaryPayer", "secondaryPayer", "payerType", "cpt", "revenueCodes", "icd", "responsibility_type"].includes(name) ? value : "";
            switch (name) {
                case "claimStatus":
                    dispatch({ type: "ClaimFilter/updateFilterData", payload: { name: "claimStatus", value: trimmedValue } });
                    break;
                case "claimType":
                    dispatch({ type: "ClaimFilter/updateFilterData", payload: { name: "claimType", value: trimmedValue } });
                    break;
                case "provider":
                    dispatch({ type: "ClaimFilter/updateFilterData", payload: { name: "provider", value: trimmedValue } });
                    break;
                case "currentPayer":
                    dispatch({ type: "ClaimFilter/updateFilterData", payload: { name: "currentPayer", value: trimmedValue } });
                    break;
                case "primaryPayer":
                    dispatch({ type: "ClaimFilter/updateFilterData", payload: { name: "primaryPayer", value: trimmedValue } });
                    break;
                case "secondaryPayer":
                    dispatch({ type: "ClaimFilter/updateFilterData", payload: { name: "secondaryPayer", value: trimmedValue } });
                    break;
                case "payerType":
                    dispatch({ type: "ClaimFilter/updateFilterData", payload: { name: "payerType", value: trimmedValue } });
                    break;
                case "revenueCodes":
                    dispatch({ type: "ClaimFilter/updateFilterData", payload: { name: "revenueCodes", value: trimmedValue } });
                    break;
                case "icd":
                    dispatch({ type: "ClaimFilter/updateFilterData", payload: { name: "icd", value: trimmedValue } });
                    break;
                case "claimBulkStatusUpdate":
                    dispatch({ type: "ClaimFilter/bulkStatusUpdate", payload: { value: trimmedValue } });
                    break;
                case "responsibility_type":
                    dispatch({ type: "ClaimFilter/responsibilityTypeChange", payload: { value: trimmedValue } });
                    setStatusUpdateWarningModal(false);
                    break;
                default:
                    break;
            }
        } else {
            const [selectedPatient] = e;
            setPatientPK(selectedPatient?.id ?? "");
            setPatientSelected(e);
        }
    }



    function onHandleDateOfServiceFrom(selected) {
        try {
            dispatch({ type: "ClaimFilter/updateFilterData", payload: { name: "dateOfServiceFrom", value: selected } })
        } catch {
            dispatch({ type: "ClaimFilter/updateFilterData", payload: { name: "dateOfServiceFrom", value: "" } })
        }
    }
    function onHandleDateOfServiceTo(selected) {
        try {
            dispatch({ type: "ClaimFilter/updateFilterData", payload: { name: "dateOfServiceTo", value: selected } })
        } catch {
            dispatch({ type: "ClaimFilter/updateFilterData", payload: { name: "dateOfServiceTo", value: "" } })
        }
    }
    function onHandleDateOfClaimEnteredFrom(selected) {
        try {
            dispatch({ type: "ClaimFilter/updateFilterData", payload: { name: "claimEnteredFrom", value: selected } })
        } catch {
            dispatch({ type: "ClaimFilter/updateFilterData", payload: { name: "claimEnteredFrom", value: "" } })
        }
    }
    function onHandleDateOfClaimEnteredTo(selected) {
        try {
            dispatch({ type: "ClaimFilter/updateFilterData", payload: { name: "claimEnteredTo", value: selected } })
        } catch {
            dispatch({ type: "ClaimFilter/updateFilterData", payload: { name: "claimEnteredTo", value: "" } })
        }
    }
    function onHandleDateOfclaimSubmittedFrom(selected) {
        try {
            dispatch({ type: "ClaimFilter/updateFilterData", payload: { name: "claimSubmittedFrom", value: selected } })
        } catch {
            dispatch({ type: "ClaimFilter/updateFilterData", payload: { name: "claimSubmittedFrom", value: "" } })
        }
    }
    function onHandleDateOfclaimSubmittedTo(selected) {
        try {
            dispatch({ type: "ClaimFilter/updateFilterData", payload: { name: "claimSubmittedTo", value: selected } })
        } catch {
            dispatch({ type: "ClaimFilter/updateFilterData", payload: { name: "claimSubmittedTo", value: "" } })
        }
    }


    useEffect(() => {
        if (!isFilterDropDownDataReceived) {
            // Dispatch Redux function to get the drop-down list data if it not there already in the state
            dispatch(getFilterDataSet());
        }
        if (filterClaimTableOpened && filterClaimsData.length === 0) {
            getFilterClaimsData(DEFAULT_PAGING_SIZE, activePage);
        }
    }, []);

    useEffect(() => {
        if (claimBulkStatusUpdate && checkedClaim && checkedClaim.length > 0 && responsibilityList.length == 0) setStatusUpdateConfirmationModal(true);
        else if (claimBulkStatusUpdate && checkedClaim && checkedClaim.length > 0) setStatusUpdateWarningModal(true);
    }, [claimBulkStatusUpdate]);

    useEffect(() => {
        if (responsibilityList.length > 1 && responsibility_type) {
            setStatusUpdateConfirmationModal(true);
        }
    }, [responsibility_type]);

    useEffect(() => {
        if (statusUpdateConfirmationModal && checkedClaim && checkedClaim.length > 0) {
            let statusObj = dropDownListData.claimStatusList.filter((status) => status.id === claimBulkStatusUpdate);
            let updateMessageObj = { "claimCount": checkedClaim.length }
            if (statusObj) updateMessageObj.status = statusObj[0].name;
            let confirmationMessage = checkedClaim.length === 1 ? i18n.t("claimsFilter.statusUpdateConfirmationSingle").replace(/{(\w+)}/g, function (_, k) {
                return updateMessageObj[k];
            }) : i18n.t("claimsFilter.statusUpdateConfirmation").replace(/{(\w+)}/g, function (_, k) {
                return updateMessageObj[k];
            });
            setStatusUpdateConfirmationMessage(confirmationMessage);
        }
    }, [statusUpdateConfirmationModal])


    

    function getFilterClaimsData(pageSize, page) {
        setShowLoadingBar(true)
        let query = '?list=true&page_size=' + pageSize + '&page=' + page + '&practice_pk=' + practicePK + '&patient_pk=' + patient_PK +
            '&billing_provider_pk=' + searchQueryInput.provider + '&claim_type=' + searchQueryInput.claimType + '&claim_status_pk=' + searchQueryInput.claimStatus +
            '&primary_payer_pk=' + searchQueryInput.primaryPayer + '&secondary_payer_pk=' + searchQueryInput.secondaryPayer + '&payer_type=' + searchQueryInput.payerType
            + '&cpt_hcps=' + searchQueryInput.selectedCptIDs + '&icd=' + searchQueryInput.icd + '&current_payer_pk=' + searchQueryInput.currentPayer

        if (searchQueryInput.dateOfServiceFrom) query += '&dos_from=' + formatDateUS(searchQueryInput.dateOfServiceFrom)
        if (searchQueryInput.dateOfServiceTo) query += '&dos_to=' + formatDateUS(searchQueryInput.dateOfServiceTo)
        if (searchQueryInput.claimEnteredFrom) query += '&claim_entered_from=' + formatDateUS(searchQueryInput.claimEnteredFrom)
        if (searchQueryInput.claimEnteredTo) query += '&claim_entered_to=' + formatDateUS(searchQueryInput.claimEnteredTo)
        if (searchQueryInput.claimSubmittedFrom) query += '&claim_submitted_from=' + formatDateUS(searchQueryInput.claimSubmittedFrom)
        if (searchQueryInput.claimSubmittedTo) query += '&claim_submitted_to=' + formatDateUS(searchQueryInput.claimSubmittedTo)

        if (searchQueryInput.selectServiceLocation) {
            query += '&service_location=' + searchQueryInput.serviceLocation;
            query += '&service_location_type=' + searchQueryInput.serviceLocationType;
        }
        if (searchQueryInput.claimType === 2) {
            query += '&revenue_codes=' + searchQueryInput.revenueCodes
        }

        // table column sort
        if (!sorting.initialOrderingPatientName || !sorting.initialOrderingClaimId || !sorting.initialOrderingDos) {
            let fieldName = ''
            if (!sorting.initialOrderingPatientName) {
                fieldName = 'patient__full_name'
            } else if (!sorting.initialOrderingClaimId) {
                fieldName = 'custom_claim_id'
            } else if (!sorting.initialOrderingDos) {
                fieldName = 'service_from_date'
            }
            if (fieldName) {
                if (fieldName == 'custom_claim_id') {
                    query += '&column=' + claim_order_type + fieldName;
                }
                else {
                    query += '&column=' + orderType + fieldName;
                }
            }
        }

        const result = searchClaimService.GetListFilteredPatientClaims(query, false);
        result.then((response) => {
            setShowLoadingBar(false)
            let responseData = response.data.results;
            if (response.data.results.length === 0) {
                ClaimsFilterTableData.tableBodyData = TableBodyData;
            }
            else {
                const rowArray = commonTableBody(response.data.results, ClaimsFilterTableData.tableBodyData[0]);
                if (selectedClaimIDs?.length > 0) {
                    if (allchecked == true) {
                        setCheckedAllClaim(true)
                    }
                    else {
                        setCheckedAllClaim(false);
                        setCheckedClaim([]);
                        setValidatedCount(0)
                        dispatch({ type: "ClaimFilter/bulkStatusUpdate", payload: { value: "" } });
                    }
                    rowArray.forEach((element, index) => {
                        selectedClaimIDs.forEach(item => {
                            if (element[0].id == item) {
                                if (rowArray[index][0].type == 'checkbox')
                                    rowArray[index][0].value = true
                            }
                        })
                    })
                    ClaimsFilterTableData.tableBodyData = rowArray;
                    setCheckedClaim(selectedClaimIDs)
                }
                else {
                    rowArray.forEach((element, index) => {

                        if (rowArray[index][0].type == 'checkbox')
                            rowArray[index][0].value = false

                    })
                    ClaimsFilterTableData.tableBodyData = rowArray;
                    setCheckedAllClaim(false);
                    setCheckedClaim([]);
                    setValidatedCount(0)
                    dispatch({ type: "ClaimFilter/bulkStatusUpdate", payload: { value: "" } });
                }


            }
            setStorage('filterClaimTableOpened', true);
            setFilterClaimsData(responseData);
            if (response.data.results !== undefined) {
                setTotalPage(Math.ceil(response.data.count / response.data.page_size));
            }
            if (patientSelectedData.length > 0) {
                setPatientAdvSearchData(patientSelectedData);
                setPatientSelected(patientSelectedData);
                setPatientPK(patient_PK)
            }
            else {
                setPatientAdvSearchData([]);
                setPatientSelected([]);
                setPatientPK('')

            }
        }).catch(() => {
            setShowLoadingBar(false)
            showNotifyWindow('show', 'error', i18n.t('errorMessages.commonError'));
        })
    }

    function sortingFunction(e, name) {
        name = name.replace(" ", "_").toLowerCase();
        if (name == 'patient_name') {
            dispatch({ type: "ClaimFilter/updateSorting", payload: { name: "patient_name" } })
        } else if (name == 'claim_id') {
            dispatch({ type: "ClaimFilter/updateSorting", payload: { name: "claim_id" } })
        } else if (name == 'dos') {
            dispatch({ type: "ClaimFilter/updateSorting", payload: { name: "dos" } })
        }
        dispatch({ type: "ClaimFilter/updateSeletedIDs", payload: { selected: [] } })
        if (name == 'claim_id') {
            if (claim_order_type) dispatch({ type: "ClaimFilter/updateClaimOrderType", payload: { claim_order_type: "" } });
            else dispatch({ type: "ClaimFilter/updateClaimOrderType", payload: { claim_order_type: "-" } });
            return
        }
        if (!orderType) dispatch({ type: "ClaimFilter/updateOrderType", payload: { order_type: "-" } });
        else dispatch({ type: "ClaimFilter/updateOrderType", payload: { order_type: "" } });
    }

    function resetSorting() {
        dispatch({ type: "ClaimFilter/resetSorting" });
    }

    useEffect(() => {
        getFilterClaimsData(DEFAULT_PAGING_SIZE, activePage);
    }, [orderType, searched, reseted, validated, bulkupdated, claim_order_type]);

    function handleMaterialMultiselectSearch(value) {
        if (value.length > 0) {
            dispatch({ type: "ClaimFilter/updateFilterData", payload: { name: "selectedCptIDs", value: value } })
        } else {
            dispatch({ type: "ClaimFilter/updateFilterData", payload: { name: "selectedCptIDs", value: "" } })
        }
    }

    function openLink(id, name) {
        let item = filterClaimsData.find(obj => obj.id == id);
        if (name == 'custom_claim_id') {
            if (checkPermission(permission_key_values_claim.claims_search_claims_view)) {
                onClaimIDClick(item.id, item.patient_name, item.custom_claim_id, item.patient_pk);
            }
            else {
                showNotifyWindow('show', 'error', i18n.t('errorMessages.permission_error'));
            }
        }
        else if (name == 'patient_name') {
            if (checkPermission(permission_key_values_claim.patient_list_sub_module_view)) {
                onPatientNameClick(item.patient_pk)
            }
            else {
                showNotifyWindow('show', 'error', i18n.t('errorMessages.permission_error'));
            }
        }
        else if (name == 'payer_name') {
            if (item.payer_types.type == 'patient') {
                onPatientNameClick(item.patient_pk)
            }
            else if (item.payer_types.type == 'insurance') {
                history.push(ROUTE_INSURANCE_COMPANIES_EDIT, { selectedID: item.payer_types.pk });
            }
        }
    }


    function onSearchFilterClaims() {
        let dateValues = [searchQueryInput.dateOfServiceFrom, searchQueryInput.dateOfServiceTo, searchQueryInput.claimEnteredFrom,
        searchQueryInput.claimEnteredTo, searchQueryInput.claimSubmittedFrom, searchQueryInput.claimSubmittedTo]

        let hasInvalidDate = dateValues.some(function (dateValue) {
            return dateValue == 'Invalid Date';
        });

        if (hasInvalidDate) {
            showNotifyWindow('show', 'error', i18n.t('errorMessages.invalid_date'));
        } else {
            resetSorting();
            dispatch({ type: "ClaimFilter/statusUpdateSearched", payload: { searched: !searched } });
            setCheckedClaim([]);
            setCheckedAllClaim(false);
            setValidatedCount(0)

        }

    }

    function onClaimIDClick(pk, name, claimId) {
        let openedPKs = false;
        const result = searchClaimService.GetSelectedTabs('claims', getStorage("practice"));
        result.then(response => {
            openedPKs = response.data && response.data.opened_tab_pks ? response.data.opened_tab_pks : [];
            let allowedPk = ['search', 'last10'];
            allowedPk.forEach((item) => {
                let index = openedPKs.indexOf(item);
                if (index !== -1) {
                    openedPKs.splice(index, 1);
                }
            })

            if (openedPKs && Array.isArray(openedPKs) && openedPKs.length >= MAX_OPEN_TABS_CLAIMS && !openedPKs.includes(pk)) {
                showNotifyWindow('show', 'error', i18n.t('errorMessages.max_claim_tabs'));
            }
            else {
                let item = { pk: pk, claim_id: claimId, type: 'claims', action: 'add', practice_pk: getStorage("practice") }
                addRemoveFromSelectedTab(item);
            }
        });
    }

    function onPatientNameClick(patientID) {
        const result = searchClaimService.GetSelectedTabs('patients', getStorage("practice"));
        result.then(response => {
            let openedPKs = response.data && response.data.opened_tab_pks ? response.data.opened_tab_pks.filter(item => parseInt(item, 10) > 0) : [];
            if (openedPKs && Array.isArray(openedPKs) && openedPKs.length >= MAX_OPEN_TABS_PATIENTS && !openedPKs.includes(patientID)) {
                showNotifyWindow('show', 'error', i18n.t('errorMessages.max_patient_tabs'));
            }
            else {
                let item = { pk: patientID, type: 'patients', action: 'add', practice_pk: getStorage("practice") }
                addRemoveFromSelectedTab(item);
            }
        });
    }

    if (redirectToPatient) {
        return <Redirect to={{ pathname: ROUTE_PATIENTS_LIST }} />;

    }

    if (redirectToClaim) {
        return <Redirect to={{ pathname: ROUTE_SEARCH_CLAIMS }} />;

    }

    function addRemoveFromSelectedTab(item) {
        const result = searchClaimService.AddRemoveSelectedTab(item);
        result.then(response => {
            if (response.data.code === 404) {
                showNotifyWindow('show', 'error', i18n.t('errorMessages.not_found'))
            } else {
                if (item && item.type == 'patients') {
                    setRedirectToPatient(true);
                } else if (item && item.type == 'claims') {
                    setRedirectToClaim(true);
                }
            }
        });
    }

    const handleAllCheckedOnChange = (e) => {
        let currentSelectedIds = [];
        if (e.target.checked) {
            ClaimsFilterTableData.tableBodyData.forEach(rowItem => {
                let index = rowItem.findIndex(colItem => colItem.type == 'checkbox');
                rowItem[index].value = true;
            });
            setCheckedAllClaim(true);
            dispatch({ type: "ClaimFilter/checkAllClaimSelected", payload: { checked: true } })
            filterClaimsData.forEach((item) => {
                currentSelectedIds.push(item.id);
            })
            setCheckedClaim(currentSelectedIds);
            dispatch({ type: "ClaimFilter/updateSeletedIDs", payload: { selected: currentSelectedIds } })
        } else {
            ClaimsFilterTableData.tableBodyData.forEach(rowItem => {
                let index = rowItem.findIndex(colItem => colItem.type == 'checkbox');
                rowItem[index].value = false;
            });
            filterClaimsData.forEach((item) => {
                let index = currentSelectedIds.indexOf(item.id);
                currentSelectedIds.splice(index, 1);
            })
            setCheckedClaim(currentSelectedIds);
            dispatch({ type: "ClaimFilter/updateSeletedIDs", payload: { selected: currentSelectedIds } })
            setCheckedAllClaim(false);
            dispatch({ type: "ClaimFilter/checkAllClaimSelected", payload: { checked: false } })
            dispatch({ type: "ClaimFilter/bulkStatusUpdate", payload: { value: "" } });
        }
    }

    const handleCheckedOnChange = (e, id) => {
        ClaimsFilterTableData.tableBodyData.forEach(rowItem => {
            if (rowItem.find(colItem => colItem.id == id)) {
                let rowIndex = rowItem.findIndex(cItem => cItem.type == 'checkbox');
                rowItem[rowIndex].value = e.target.checked;
            }
        });
        let currentSelectedIds = [];
        let pageAllItemSelected = true;
        checkedClaim.forEach((item) => {
            currentSelectedIds.push(item);
        })
        setCheckedClaim([]);
        dispatch({ type: "ClaimFilter/updateSeletedIDs", payload: { selected: [] } })
        let index = currentSelectedIds.indexOf(id);
        if (e.target.checked) {
            if (index === -1) {
                currentSelectedIds.push(id);
            }

        }
        else {
            if (index !== -1) {
                currentSelectedIds.splice(index, 1);
            }
        }
        setCheckedClaim(currentSelectedIds);
        dispatch({ type: "ClaimFilter/updateSeletedIDs", payload: { selected: currentSelectedIds } })
        filterClaimsData.forEach((item) => {
            let checkedItem = currentSelectedIds.indexOf(item.id);
            if (checkedItem === -1) {
                pageAllItemSelected = false;
            }
        })
        if (pageAllItemSelected) {
            setCheckedAllClaim(true);
            dispatch({ type: "ClaimFilter/checkAllClaimSelected", payload: { checked: true } })
        } else {
            setCheckedAllClaim(false);
            dispatch({ type: "ClaimFilter/checkAllClaimSelected", payload: { checked: false } })
        }
    }

    const onMouseOverWarning = (e, id) => {
        setIsLoading({
            loading: true,
            id: id
        });
        const result = searchClaimService.GetClaimWarnings(id);
        result.then(response => {
            setWarningFromServer(id, response);
        }).catch(() => {
            showNotifyWindow('show', 'error', 'Unexpected Error');
            setIsLoading({
                loading: false,
                id: null
            });
        });
    }

    function setWarningFromServer(id, response) {
        if (!response?.data || !response?.data?.warnings || !Array.isArray(response?.data?.warnings)) {
            showNotifyWindow('show', 'error', 'Unexpected Error');
        }
        setTimeout(() => {
            setIsLoading({
                loading: false,
                id: id
            });
        }, 1000);

        try {

            let warningsArray = formWarningData;
            warningsArray = warningsArray.filter(function (obj) {
                return obj.type !== 'validation';
            });

            if (response.data.warnings && response.data.warnings.length > 0) {
                let claimValidations = response.data.warnings;
                claimValidations.forEach((item) => {
                    let warningExist = warningsArray.filter((warningObj) => warningObj.tabName === item);
                    let warningCode = '';
                    let warningMessage = '';
                    if (Array.isArray(item)) {
                        warningCode = item[0];
                        let optionString = i18n.t('warning.claim.' + warningCode);
                        warningMessage = optionString.replace(/{(\w+)}/g, function (_, k) {
                            return item[1][k];
                        });
                    } else {
                        warningCode = item;
                        warningMessage = i18n.t('warning.claim.' + warningCode);
                    }

                    let warnings = { "tabName": warningCode, "type": "validation" }
                    warnings[warningMessage] = "";
                    if (warningExist) {
                        let warningIndexItem = '';
                        if (Array.isArray(item))
                            warningIndexItem = item[0];
                        else
                            warningIndexItem = item;
                        let warningIndex = warningsArray.findIndex(items => items.tabName === warningIndexItem);
                        if (warningIndex < 0) {
                            warningsArray.push(warnings);
                        } else {
                            warningsArray[warningIndex] = warnings;
                        }
                    } else {
                        warningsArray.push(warnings);
                    }
                })
            }

            if (formWarningStatus) {
                // should be set false and true
                setFormWarningStatus(false);
                setFormWarningStatus(true);
            }
            else {
                // should be set true and false
                setFormWarningStatus(true);
                setFormWarningStatus(false);
            }

            ClaimsFilterTableData.tableBodyData.forEach(rowItem => {
                if (rowItem.find(colItem => colItem.id == id)) {
                    let index = rowItem.findIndex(cItem => cItem.type == 'warning');
                    rowItem[index].value = warningsArray;
                    rowItem[index].status = true;
                    setFormWarningData(warningsArray);
                }
            });

        } catch (err) {
            showNotifyWindow('show', 'error', 'Unexpected Error');
        }
    }

    const onUpdateBulkStatus = () => {
        setShowLoadingBar(true);
        setStatusUpdateConfirmationModal(false);
        setStatusUpdateWarningModal(false);
        if (claimBulkStatusUpdate && checkedClaim && checkedClaim.length > 0) {
            let data = { 'claim_ids': checkedClaim, 'practice_pk': practicePK, 'responsibility_type': responsibility_type, 'status_pk': claimBulkStatusUpdate }
            const result = searchClaimService.UpdateClaimStatusbulk(data);
            result.then(response => {
                if (response.status == 200) {
                    let messageObjects = {
                        "updatedClaims": response.data.updates_count, "totalClaims": checkedClaim.length,
                    }
                    let statusObj = dropDownListData.claimStatusList.filter((status) => status.id === claimBulkStatusUpdate);
                    if (statusObj) messageObjects.status = statusObj[0].name;
                    let confirmationMessage = i18n.t("claimsFilter.bulkUpdateAlert").replace(/{(\w+)}/g, function (_, k) {
                        return messageObjects[k];
                    });
                    setStatusUpdateSuccessMessage(confirmationMessage);
                    setStatusUpdateAlertModal(true);
                    setShowLoadingBar(false);
                } else {
                    setShowLoadingBar(false);
                    if (response.data.message === "responsibility_type_invalid") {
                        showNotifyWindow('show', 'error',i18n.t("errorMessages.responsibility_invalid"));
                    } else {
                        showNotifyWindow('show', 'error',i18n.t("errorMessages.commonError"));
                    }
                    dispatch(resetBulkStatusData());
                }
            });
        }
    }

    const onValidateClaims = () => {
        setShowLoadingBar(true);
        if (checkedClaim && checkedClaim.length > 0) {
            let data = { 'claim_ids': checkedClaim, 'practice_pk': practicePK }
            const result = searchClaimService.ClaimBulkWarningCheck(data);
            result.then(response => {
                setShowLoadingBar(false);
                setValidatedCount(response.data.claims_validated);
                setValidateAlertModal(true);
            });
        }
    }

    function onAlertOk() {
        setStatusUpdateAlertModal(false);
        setValidateAlertModal(false);
        setStatusUpdateWarningModal(false);
        dispatch({ type: "ClaimFilter/validateStatusUpdate", payload: { validated: !validated } });
        dispatch({ type: "ClaimFilter/bulkStatusUpdated", payload: { bulkupdated: !bulkupdated } });
    }

    function onResetFilter() {
        setClearAMMSelection((clearAMMSelection + 1));
        dispatch({ type: "ClaimFilter/resetFiltersAndData", payload: { status: !reseted } })
        document.querySelector('button[aria-label="Clear"]')?.click();
    }

    const OnExportMenuItemClicked = (e, type) => {
        setShowLoadingBar(true);
        let query = '?list=true&practice_pk=' + practicePK +
            '&patient_pk=' + patientPK +
            '&billing_provider_pk=' + searchQueryInput.provider +
            '&claim_type=' + searchQueryInput.claimType +
            '&claim_status_pk=' + searchQueryInput.claimStatus +
            '&primary_payer_pk=' + searchQueryInput.primaryPayer +
            '&secondary_payer_pk=' + searchQueryInput.secondaryPayer +
            '&payer_type=' + searchQueryInput.payerType +
            '&cpt_hcps=' + searchQueryInput.selectedCptIDs +
            '&icd=' + searchQueryInput.icd +
            '&current_payer_pk=' + searchQueryInput.currentPayer;

        if (searchQueryInput.dateOfServiceFrom) query += '&dos_from=' + formatDateUS(searchQueryInput.dateOfServiceFrom)
        if (searchQueryInput.dateOfServiceTo) query += '&dos_to=' + formatDateUS(searchQueryInput.dateOfServiceTo)
        if (searchQueryInput.claimEnteredFrom) query += '&claim_entered_from=' + formatDateUS(searchQueryInput.claimEnteredFrom)
        if (searchQueryInput.claimEnteredTo) query += '&claim_entered_to=' + formatDateUS(searchQueryInput.claimEnteredTo)
        if (searchQueryInput.claimSubmittedFrom) query += '&claim_submitted_from=' + formatDateUS(searchQueryInput.claimSubmittedFrom)
        if (searchQueryInput.claimSubmittedTo) query += '&claim_submitted_to=' + formatDateUS(searchQueryInput.claimSubmittedTo)

        query += '&page=0&export=true&export_type=' + type;

        if (searchQueryInput.selectServiceLocation) {
            query += '&service_location=' + searchQueryInput.serviceLocation;
            query += '&service_location_type=' + searchQueryInput.serviceLocationType;
        }
        searchClaimService.GetListFilteredPatientClaims(query, type)
            .then((result) => {
                if (result.status === 200 || result.status === 201) {
                    fileDownload(result.data, result.headers['content-disposition']);
                    setShowLoadingBar(false);
                    showNotifyWindow('show', 'success', 'Export Success');
                } else if (result.status === 404 && result.message === 'no_data_available_for_export') {
                    showNotifyWindow('show', 'error', i18n.t('errorMessages.no_record_found_to_export'));
                    setShowLoadingBar(false);
                } else {
                    showNotifyWindow('show', 'error', result.statusText);
                }
            })
            .catch(() => {
                showNotifyWindow('show', 'error', 'Unexpected Error');
                setShowLoadingBar(false);
            });
    };

    function MaterialMultiSelectHandle(e) {
        let name = e.target.name;
        let value = e.target.value;
        if (name === 'serviceLocation') {
            dispatch({ type: "ClaimFilter/updateFilterData", payload: { name: "selectServiceLocation", value: value } });
            let service_locations = [];
            let service_location_types = [];
            if (value.length > 0) {
                value.forEach((item) => {
                    service_locations.push(item.split("||")[0]);
                    service_location_types.push(item.split("||")[1]);

                });
            }
            dispatch({ type: "ClaimFilter/updateServiceLocation", payload: { service_location: service_locations, service_location_type: service_location_types } });
        }
        else if (name === 'provider') {
            dispatch({ type: "ClaimFilter/updateFilterData", payload: { name: "provider", value: value } });
        }
        else if (name === "claimStatus") {
            dispatch({ type: "ClaimFilter/updateFilterData", payload: { name: "claimStatus", value: value } });
        }
        else if (name === "claimType") {
            dispatch({ type: "ClaimFilter/updateFilterData", payload: { name: "claimType", value: value } })
        }
        else if (name === "revenueCodes") {
            dispatch({ type: "ClaimFilter/updateFilterData", payload: { name: "revenueCodes", value: value } })
        }
        else if (name === "icd") {
            dispatch({ type: "ClaimFilter/updateFilterData", payload: { name: "icd", value: value } })
        }
        else if (name === "payerType") {
            dispatch({ type: "ClaimFilter/updateFilterData", payload: { name: "payerType", value: value } })
        }
        else if (name === "secondaryPayer") {
            dispatch({ type: "ClaimFilter/updateFilterData", payload: { name: "secondaryPayer", value: value } })
        }
        else if (name === "primaryPayer") {
            dispatch({ type: "ClaimFilter/updateFilterData", payload: { name: "primaryPayer", value: value } })
        }
        else if (name === "currentPayer") {
            dispatch({ type: "ClaimFilter/updateFilterData", payload: { name: "currentPayer", value: value } })
        }
    }

    function onClickGridCheckBox(e, clickedFrom, id) {
        if (clickedFrom == 'data') {
            if (checkPermission(permission_key_values_claim.can_add_warnings)) {
                handleCheckedOnChange(e, id);
            }
            else {
                showNotifyWindow('show', 'error', i18n.t('errorMessages.permission_error'));
            }
        }
        else if (clickedFrom == 'header') {
            handleAllCheckedOnChange(e);
        }
    }


    const onResponsibilityPopHide = () => {
        setStatusUpdateWarningModal(false);
        dispatch(resetResponsibilityType());
    }

    const onCloseUpdateWindow=()=>{
        dispatch(resetBulkStatusData())
    }

    return (
        <div className="col-md-12">
            <Form autoComplete="off" className='margin-right15'>
                <Notify showNotify={showNotify} setShowNotify={setShowNotify} notifyDescription={notifyDescription} setNotifyType={setNotifyType} setNotifyDescription={setNotifyDescription} notifyType={notifyType} />
                <div className="">
                    <div className="">
                        <div className="searchBox">
                            <div className="row">
                                <PatientDropDownSearch setPatientPK={setPatientPK} patientSelected={patientSelected} setPatientSelected={setPatientSelected} patientAdvSearchData={patientAdvSearchData} setPatientRemoved={setPatientRemoved}></PatientDropDownSearch>
                                <div className="">
                                    <div className="input-content-box margin-top25 justify-right">
                                        <RightSidePanel title={i18n.t("commons.advancedSearch")} onclickLabel={i18n.t("commons.advancedSearch")} >
                                            <PatientsAdvancedSearch setPatientPK={setPatientPK} setPatientSelected={setPatientSelected} setPatientAdvSearchData={setPatientAdvSearchData}>
                                            </PatientsAdvancedSearch>
                                        </RightSidePanel>
                                    </div>
                                </div>
                            </div>
                            <div className="row margin-top20">
                                <div className="col-2">
                                    <CalendarInput name="dateOfServiceFrom"
                                        selected={searchQueryInput.dateOfServiceFrom} onValueChange={onHandleDateOfServiceFrom}
                                        label={i18n.t("claimsFilter.dateOfServiceFrom")} />
                                </div>
                                <div className="col-2 pl-0">
                                    <CalendarInput name="dateOfServiceTo"
                                        selected={searchQueryInput.dateOfServiceTo} minDate={searchQueryInput.dateOfServiceFrom} onValueChange={onHandleDateOfServiceTo}
                                        label={i18n.t("claimsFilter.dateOfServiceTo")} />
                                </div>
                                <div className="col-2 pl-0">
                                    <CalendarInput name="claimEnteredFrom"
                                        selected={searchQueryInput.claimEnteredFrom} onValueChange={onHandleDateOfClaimEnteredFrom}
                                        label={i18n.t("claimsFilter.claimEntered")} />
                                </div>
                                <div className="col-2 pl-0">
                                    <CalendarInput name="claimEnteredTo"
                                        selected={searchQueryInput.claimEnteredTo} minDate={searchQueryInput.claimEnteredFrom} onValueChange={onHandleDateOfClaimEnteredTo}
                                        label={i18n.t("claimsFilter.claimEnteredTo")} />
                                </div>
                                <div className="col-2 pl-0">
                                    <CalendarInput name="claimSubmittedFrom"
                                        selected={searchQueryInput.claimSubmittedFrom} onValueChange={onHandleDateOfclaimSubmittedFrom}
                                        label={i18n.t("claimsFilter.claimSubmitted")} />
                                </div>
                                <div className="col-2 pl-0">
                                    <CalendarInput name="claimSubmittedTo"
                                        selected={searchQueryInput.claimSubmittedTo} minDate={searchQueryInput.claimSubmittedFrom} onValueChange={onHandleDateOfclaimSubmittedTo}
                                        label={i18n.t("claimsFilter.claimSubmittedTo")} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-4 ">
                                    <MaterialMultiselect name="claimStatus" value={searchQueryInput.claimStatus} onValueChange={MaterialMultiSelectHandle}
                                        options={dropDownListData.claimStatusList} label={i18n.t("claimsFilter.claimStatus")}
                                    />
                                </div>
                                <div className="col-4 pl-0">
                                    <SelectInput name="claimType"
                                        data={CLAIM_TYPES}
                                        value={searchQueryInput.claimType}
                                        onValueChange={MaterialMultiSelectHandle}
                                        label={i18n.t("claimsFilter.claimType")} />

                                </div>
                                <div className="col-4 pl-0">
                                    <MaterialMultiselect name="provider" value={searchQueryInput.provider} onValueChange={MaterialMultiSelectHandle}
                                        options={dropDownListData.providerList} label={i18n.t("claimsFilter.provider")}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-4">
                                    <MaterialMultiselect name="serviceLocation" value={searchQueryInput.selectServiceLocation} onValueChange={MaterialMultiSelectHandle}
                                        options={dropDownListData.ServiceLocationList} label={i18n.t("statementManagement.selFacility")}
                                    />
                                </div>
                                <div className="col-4 pl-0">
                                    <MaterialMultiselect name="currentPayer" value={searchQueryInput.currentPayer} onValueChange={MaterialMultiSelectHandle}
                                        options={dropDownListData.payersList} label={i18n.t("claimsFilter.currentPayer")}
                                    />
                                </div>
                                <div className="col-4 pl-0">
                                    <MaterialMultiselect name="primaryPayer" value={searchQueryInput.primaryPayer} onValueChange={MaterialMultiSelectHandle}
                                        options={dropDownListData.payersList} label={i18n.t("claimsFilter.primaryPayer")}
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-4">
                                    <MaterialMultiselect name="secondaryPayer" value={searchQueryInput.secondaryPayer} onValueChange={MaterialMultiSelectHandle}
                                        options={dropDownListData.payersList} label={i18n.t("claimsFilter.secondaryPayer")}
                                    />
                                </div>
                                <div className="col-4 pl-0">
                                    <MaterialMultiselect name="payerType" value={searchQueryInput.payerType} onValueChange={MaterialMultiSelectHandle}
                                        options={PAYER_TYPE_LIST} label={i18n.t("claimsFilter.payerType")}
                                    />
                                </div>
                                <div className="col-4 pl-0">
                                    <MaterialMultiSelectSearch
                                        options={dropDownListData.cptCodeList}
                                        label={i18n.t("claimsFilter.cpt")}
                                        name={"cpt"}
                                        valuesArr={searchQueryInput.selectedCptIDs}
                                        onValueChange={handleMaterialMultiselectSearch}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                {searchQueryInput.claimType == 2 ? <div className="col-4">
                                    <MaterialMultiselect name="revenueCodes" value={searchQueryInput.revenueCodes} onValueChange={MaterialMultiSelectHandle}
                                        options={dropDownListData.revenueCodeList} label={i18n.t("claimsFilter.revenueCodes")}
                                    />
                                </div> : ''}
                                <div className={searchQueryInput.claimType == 2 ? "col-4 pl-0" : "col-4"}>
                                    <MaterialMultiselect name="icd" value={searchQueryInput.icd} onValueChange={MaterialMultiSelectHandle}
                                        options={ICD} label={i18n.t("claimsFilter.icd")}
                                    />
                                </div>
                                <div className="col justify-right">
                                    <div className="div-float-right padding-top23 ml-3">
                                        <CommonButton variant="outlined" onClick={onResetFilter} label={i18n.t("claimsFilter.reset")} />
                                    </div>
                                    <div className="div-float-right padding-top23 ml-3">
                                        <CommonButton variant="contained" onClick={onSearchFilterClaims} label={i18n.t("claimsFilter.search")} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row margin-top20'>
                        {checkPermission(permission_key_values_claim.can_add_warnings) && checkedClaim.length > 0 &&
                            <>
                                <div className='col-3'>
                                    <SelectInput data={dropDownListData.claimStatusList} name="claimBulkStatusUpdate" value={claimBulkStatusUpdate} onValueChange={onHandleChange} label={i18n.t("claimsFilter.updateClaimStatus")} />
                                </div>
                            </>}
                        <div className="col justify-right dropdown margin-top23 margin-bottom10" id="export-dropdown">
                            <div style={{ marginRight: 12 }}>
                                <CommonButton variant="contained" disabled={checkedClaim.length > 0 ? false : true} onClick={() => onValidateClaims()} label={i18n.t("claimsFilter.claimValidate")} />
                            </div>
                            <div className="">
                                {checkPermission(permission_key_values_claim.can_view_filtered_claim_list) &&
                                    <ExportIconButton
                                        disabled={!filterClaimsData.length}
                                        onExportCsv={(e) => OnExportMenuItemClicked(e, 'csv')}
                                        onExportXls={(e) => OnExportMenuItemClicked(e, 'xls')} />
                                }
                            </div>
                        </div>
                    </div>
                    {filterClaimTableOpened ?
                        <div style={{ overflowX: "auto", width: "100%" }} className="mb-4">
                            <div>
                                <Table tableObject={ClaimsFilterTableData} isCheckboxDisabled={checkPermission(permission_key_values_claim.can_add_warnings)} onLinkClick={openLink} onClickGridCheckBox={onClickGridCheckBox}
                                    onMouseOverWarning={onMouseOverWarning} checkedHeaderCheckBox={checkedAllClaim} sortingFunction={sortingFunction} tblRadiusClass={' table-border-radius12 '} isLoading={isLoading}
                                />
                            </div>
                        </div> : ""
                    }
                    <Pagination totalPage={totalPage} activePage={activePage} startIndex={startIndex} endIndex={endIndex} onPagePrevious={onPagePrevious} onPageUp={onPageUp} onPageNext={onPageNext} />
                    <div className="clear-b-scroll-sector">&nbsp;</div>
                </div>
            </Form>
            <CustomizedSmallDialogs showModal={statusUpdateAlertModal} setShowModalWindow={setStatusUpdateAlertModal} alertOK={onAlertOk} header={i18n.t("commons.alert")} type="alert">
                {statusUpdateSuccessMessage}
            </CustomizedSmallDialogs>
            <CustomizedSmallDialogs showModal={validateAlertModal} setShowModalWindow={setValidateAlertModal} alertOK={onAlertOk} header={i18n.t("commons.alert")} type="alert">
                {validatedCount + " " + (validatedCount === 1 ? i18n.t("claimsFilter.validatedsuccessfullySingle") : i18n.t("claimsFilter.validatedsuccessfully"))}
            </CustomizedSmallDialogs>
            <CustomizedSmallDialogs showModal={statusUpdateConfirmationModal} setShowModalWindow={setStatusUpdateConfirmationModal} inactveItem={onUpdateBulkStatus} onHide={onCloseUpdateWindow} header={i18n.t("commons.alert")} type="yes">
                {statusUpdateConfirmationMessage}
            </CustomizedSmallDialogs>
            <CustomizedSmallDialogs
                showModal={statusUpdateWarningModal}
                setShowModalWindow={setStatusUpdateWarningModal}
                header={i18n.t("commons.alert")}
                onCancel={() => onResponsibilityPopHide(true)}>
                <div>
                    <div style={{ textAlign: 'center' }}>
                        <span>{i18n.t("claimsFilter.claim_update_warning")}</span>
                    </div>
                    {checkPermission(permission_key_values_claim.can_add_warnings) && responsibilityList.length > 1 &&
                        <div className='col-12 margin-top10'>
                            <SelectInput
                                data={RESPONSIBILITY_TYPES.filter(item => responsibilityList.includes(item.id))}
                                name="responsibility_type" value={responsibility_type}
                                onValueChange={onHandleChange}
                                label={i18n.t("claimsFilter.responsibility")} />
                        </div>}
                </div>
            </CustomizedSmallDialogs>
        </div>
    )
}
export default ClaimsFilter