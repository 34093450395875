import React, { useState, useEffect, useContext } from 'react';
import { Form } from 'react-bootstrap';
import TextInput from "../../commons/input/input";
import SelectInput from '../../commons/input/SelectInput';
import i18n from "../../../utilities/i18n";
import service from './service';
import { PAGE_LOCKS } from '../../../utilities/dictionaryConstants';
import { PAGING_END_INDEX, DEFAULT_PAGING_SIZE } from '../../../utilities/staticConfigs';
import { getStorage } from '../../../utilities/browserStorage';
import Pagination from '../../commons/pagination';
import { Link } from 'react-router-dom';
import Notify from "../../commons/notify";
import Moment from 'react-moment';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { checkPermission, currencyFormat } from '../../../utilities/commonUtilities';
import { useDispatch, useSelector } from 'react-redux';
import { updatePatientInfo } from '../../../store/patient/patientInfoSlice';
import { permission_key_values_administration, super_admin_privileges, module_submodule_permission } from '../../../utilities/permissions';
import LoadingContext from '../../../container/loadingContext';
import { handleClaimPageUnlock } from '../../ClaimModule/StateManagement/ClaimModuleSlice';


const PageLocks = () => {
    const [permission, setPermission] = useState(false);
    const dispatch = useDispatch();
    const patientInfoData = useSelector(state => state.patientData.patientInfoData);
    const activePatientPks = useSelector(state => state.patientData.pks);
    const [pageLockType, setPageLockType] = useState('patient');
    const [patientList, setPatientList] = useState([]);
    const [claimList, setClaimList] = useState([]);
    const [search, setSearch] = useState('');
    const [searchValueClose, setSearchValueClose] = useState('');
    const setShowLoadingBar = useContext(LoadingContext);
    const { claimParentTabs } = useSelector((state) => state.claimManagement);

    //start ==== Alert message properties
    const [showNotify, setShowNotify] = useState("hide");
    const [notifyDescription, setNotifyDescription] = useState("");
    const [notifyType, setNotifyType] = useState("success");

    function showNotifyWindow(action, type, desc, age = 4000) {
        if (action === "show") {
            setTimeout(() => {
                setShowNotify("hide");
            }, age);
        }
        setShowNotify(action);
        setNotifyType(type);
        setNotifyDescription(desc);
    }

    useEffect(() => {
        setPermission(checkPermission(super_admin_privileges.super_admin_full_privilege, permission_key_values_administration.page_unlock_access, module_submodule_permission.page_lock_sub_module_modify))
    }, [])

    //Patient Pagination start
    const [patientTotalPage, setPatientTotalPage] = useState(1);
    const [patientActivePage, setPatientActivePage] = useState(1);
    const [patientStartIndex, setPatientStartIndex] = useState(0);
    const [patientEndIndex, setPatientEndIndex] = useState(PAGING_END_INDEX);
    const [initialTableData, setInitialTableData] = useState(i18n.t("commons.loading"));

    function onPatientPagePrevious() {
        let previousPage = patientStartIndex + 1 - PAGING_END_INDEX;
        setPatientActivePage(previousPage);
        if (patientStartIndex !== 0) {
            setPatientStartIndex(patientStartIndex - PAGING_END_INDEX);
            setPatientEndIndex(patientEndIndex - PAGING_END_INDEX);
        }
        getLockedData(pageLockType, DEFAULT_PAGING_SIZE, previousPage);
    }

    function onPatientPageUp(e) {
        let page = Number(e.target.id)
        setPatientActivePage(page);
        getLockedData(pageLockType, DEFAULT_PAGING_SIZE, page);
    }

    function onPatientPageNext() {
        let nextPage = patientStartIndex + 1 + PAGING_END_INDEX;
        if (patientEndIndex === patientTotalPage || patientTotalPage <= PAGING_END_INDEX) {
            setPatientActivePage(nextPage);
            setPatientStartIndex(patientStartIndex);
            setPatientEndIndex(patientEndIndex);
        } else {
            setPatientActivePage(nextPage);
            setPatientStartIndex(patientStartIndex + PAGING_END_INDEX);
            setPatientEndIndex(patientEndIndex + PAGING_END_INDEX);
        }
        getLockedData(pageLockType, DEFAULT_PAGING_SIZE, nextPage);
    }
    //Patient Pagination ends

    //Claim Pagination start
    const [claimTotalPage, setClaimTotalPage] = useState(1);
    const [claimActivePage, setClaimActivePage] = useState(1);
    const [claimStartIndex, setClaimStartIndex] = useState(0);
    const [claimEndIndex, setClaimEndIndex] = useState(PAGING_END_INDEX);

    function onClaimPagePrevious() {
        let previousPage = claimStartIndex + 1 - PAGING_END_INDEX;
        setClaimActivePage(previousPage);
        if (claimStartIndex !== 0) {
            setClaimStartIndex(claimStartIndex - PAGING_END_INDEX);
            setClaimEndIndex(claimEndIndex - PAGING_END_INDEX);
        }
        getLockedData(pageLockType, DEFAULT_PAGING_SIZE, previousPage);
    }

    function onClaimPageUp(e) {
        let page = Number(e.target.id)
        setClaimActivePage(page);
        getLockedData(pageLockType, DEFAULT_PAGING_SIZE, page);
    }

    function onClaimPageNext() {
        let nextPage = claimStartIndex + 1 + PAGING_END_INDEX;
        if (claimEndIndex === claimTotalPage || claimTotalPage <= PAGING_END_INDEX) {
            setClaimActivePage(nextPage);
            setClaimStartIndex(claimStartIndex);
            setClaimEndIndex(claimEndIndex);
        } else {
            setClaimActivePage(nextPage);
            setClaimStartIndex(claimStartIndex + PAGING_END_INDEX);
            setClaimEndIndex(claimEndIndex + PAGING_END_INDEX);
        }
        getLockedData(pageLockType, DEFAULT_PAGING_SIZE, nextPage);
    }
    //Claim Pagination ends

    function onHandleChange(e) {
        let name = e.target.name;
        let value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
        let trimmedValue = value;
        if (name === "pageLockType") {
            setPageLockType(trimmedValue);
            if (trimmedValue === 'patient')
                setPatientActivePage(1);
            else
                setClaimActivePage(1);
            setSearch('');
            getLockedData(trimmedValue, DEFAULT_PAGING_SIZE, 1, '');
        } else if (name === 'search'){
            setSearch(trimmedValue);
            if (!trimmedValue || trimmedValue === '0') { 
                getLockedData(pageLockType, DEFAULT_PAGING_SIZE, 1, ''); 
            }
        }   
    }

    function getLockedData(lockType, pageSize, page, searchValue) {
        setShowLoadingBar(true);
        if (lockType === 'patient') {
            const result = service.GetLockedPatients(pageSize, page, getStorage('practice'), searchValue);
            result.then((response) => {
                setPatientList(response.data.results);
                setClaimList([]);
                if (response.data.results !== undefined) {
                    setPatientTotalPage(Math.ceil(response.data.count / response.data.page_size));
                }
                setInitialTableData(i18n.t("commons.noRecords"));
                setShowLoadingBar(false);
            });
        } else {
            const result = service.GetLockedClaims(pageSize, page, getStorage('practice'), searchValue);
            result.then((response) => {
                setClaimList(response.data.results);
                setPatientList([]);
                if (response.data.results !== undefined) {
                    setClaimTotalPage(Math.ceil(response.data.count / response.data.page_size));
                }
                setInitialTableData(i18n.t("commons.noRecords"));
                setShowLoadingBar(false);
            });
        }
    }

    useEffect(() => {
        if (pageLockType === 'patient')
            getLockedData(pageLockType, DEFAULT_PAGING_SIZE, patientActivePage, '');
        else
            getLockedData(pageLockType, DEFAULT_PAGING_SIZE, claimActivePage, '');
    }, [searchValueClose]);

    function onUnlockPage(id) {
        if (!permission) {
            showNotifyWindow('show', 'error', i18n.t('errorMessages.permission_error'));
            return;
        }
        const result = service.UnlockPage({ pk: id, lock_type: pageLockType });
        result.then(() => {
            showNotifyWindow("show", "success", "Unlocked Successfully...");
            let searchValue = search ? search : '';
            if (pageLockType === 'patient') {
                getLockedData(pageLockType, DEFAULT_PAGING_SIZE, patientActivePage, searchValue);
                if (patientInfoData.length > 0 && activePatientPks.includes(id)) {
                    dispatch(updatePatientInfo({
                        patientAddVariables: {
                            locked: false
                        },
                        patientPK: id
                    }))
                }
            }
            else {
                getLockedData(pageLockType, DEFAULT_PAGING_SIZE, claimActivePage, searchValue);
                // If user has opened the currently un-locked claim in this session, dispatching to claim redux to un-lock it from the claim module aswell.
                if (Array.isArray(claimParentTabs) && claimParentTabs.length) {
                    let claimCurrentlyOpenedByUser = claimParentTabs?.some(item => `${item.pk}` == `${id}`)
                    if (claimCurrentlyOpenedByUser)
                        dispatch(handleClaimPageUnlock({ claimPK: id }));
                }
            }
        });
    }

    function onListLockedData() {
        let searchValue = search ? search : '';
        if (pageLockType === 'patient')
            getLockedData(pageLockType, DEFAULT_PAGING_SIZE, patientActivePage, searchValue);
        else
            getLockedData(pageLockType, DEFAULT_PAGING_SIZE, claimActivePage, searchValue);
    }

    return (
        <React.Fragment>
            <Notify showNotify={showNotify} setShowNotify={setShowNotify} notifyDescription={notifyDescription} setNotifyType={setNotifyType} setNotifyDescription={setNotifyDescription} notifyType={notifyType} />
            <div className="col-md-12">
                <div className="box basic-info-padding bordering border-radius-8"  style={{ padding: "15px"}}>
                    <div className="">
                        <Form autoComplete="off">
                            <div className="box bordering">
                                <div className="row">
                                    <div className="col-2">
                                        <SelectInput data={PAGE_LOCKS} name="pageLockType" value={pageLockType} onValueChange={onHandleChange} label={i18n.t("userPages.pageLocks.lockType")} />
                                    </div>
                                    <div className="col-2 pl-2 relative">
                                        <TextInput type="text" name="search" value={search} pageLockType label={i18n.t("userPages.pageLocks.search")} onValueChange={onHandleChange} />
                                        {(search.length !== 0) &&
                                            <IconButton
                                                className="pagelockSearchIcon_close"
                                                onClick={() => {
                                                    setSearch('');
                                                    setSearchValueClose(searchValueClose + 1)
                                                }}
                                                style={{zIndex:1}}
                                            >
                                                <CloseIcon sx={{ fontSize: "small", color: '#9093a4' }} />
                                            </IconButton>
                                        }
                                    </div>
                                    <div className="col-1 pl-0">
                                    <div className="padding-top25">
                                            <button type="button" className="btn btn-primary-blue btn-common-height35 width-75" onClick={(e) => onListLockedData(e)}>
                                                {i18n.t("buttons.search")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </div>
                    <div className="">
                        <div className="">
                            {pageLockType === 'patient' &&
                                <div className="mt-4">
                                    <div style={{ overflowX: "auto", width: "100%"}}>
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>{i18n.t("userPages.pageLocks.patientId")}</th>
                                                    <th>{i18n.t("userPages.pageLocks.patientName")}</th>
                                                    <th>{i18n.t("userPages.pageLocks.dob")}</th>
                                                    <th>{i18n.t("userPages.pageLocks.patientType")}</th>
                                                    <th>{i18n.t("userPages.pageLocks.locked_by")}</th>
                                                    <th>{i18n.t("dictionariesPages.action")}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {patientList && patientList.length === 0 &&
                                                    <tr>
                                                        <td align="center" colSpan='6'>
                                                            {initialTableData}
                                                        </td>
                                                    </tr>
                                                }
                                                {patientList && patientList.map((item, index) => {
                                                    return (
                                                        <tr key={item.id}>
                                                            <td align="center">{item.custom_patient_id}</td>
                                                            <td style={{whiteSpace:'pre-line'}}>{item.name}</td>
                                                            <td align="center"><Moment format='MM/DD/YYYY'>{item.date_of_birth}</Moment></td>
                                                            <td>{item.patient_type}</td>
                                                            <td>{item.locked_by_name}</td>
                                                            <td align="center">
                                                                <Link className="pl-0" to="#" data-testid={"unlock-" + index} onMouseDown={() => onUnlockPage(item.id)}>{'Unlock'}</Link>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                    <Pagination totalPage={patientTotalPage} activePage={patientActivePage} startIndex={patientStartIndex} endIndex={patientEndIndex} onPagePrevious={onPatientPagePrevious} onPageUp={onPatientPageUp} onPageNext={onPatientPageNext} />
                                    {patientTotalPage <= 1 && <div className="clear-b-scroll-sector">&nbsp;</div>}
                                </div>
                            }
                            {pageLockType === 'claim' &&
                                <div className="mt-4">
                                    <div style={{ overflowX: "auto", width: "100%"}}>
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>{i18n.t("userPages.pageLocks.claimId")}</th>
                                                    <th>{i18n.t("userPages.pageLocks.patientName")}</th>
                                                    <th>{i18n.t("userPages.pageLocks.dos")}</th>
                                                    <th>{i18n.t("userPages.pageLocks.charges")}</th>
                                                    <th>{i18n.t("userPages.pageLocks.balance")}</th>
                                                    <th>{i18n.t("userPages.pageLocks.billedDate")}</th>
                                                    <th>{i18n.t("userPages.pageLocks.responsiblePayer")}</th>
                                                    <th>{i18n.t("userPages.pageLocks.locked_by")}</th>
                                                    <th>{i18n.t("dictionariesPages.action")}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {claimList && claimList.length === 0 &&
                                                    <tr>
                                                        <td align="center" colSpan='9'>
                                                            {initialTableData}
                                                        </td>
                                                    </tr>
                                                }
                                                {claimList && claimList.map((item, index) => {
                                                    return (
                                                        <tr key={item.id}>
                                                            <td align='center'>{item.custom_claim_id}</td>
                                                            <td>{item.patient_name}</td>
                                                            <td align='center'><Moment format='MM/DD/YYYY'>{item.dos_from}</Moment></td>
                                                            <td align='right'>{currencyFormat(item.charges)}</td>
                                                            <td align='right'>{currencyFormat(item.balance)}</td>
                                                            <td align='center'>{item.billedDate}</td>
                                                            <td>{item?.responsible_payer?.name ?? "Self Pay"}</td>
                                                            <td>{item.locked_by_name}</td>
                                                            <td align='center'>
                                                                <Link className="pl-0" to="#" data-testid={"unlock-" + index} onMouseDown={() => onUnlockPage(item.id)}>{'Unlock'}</Link>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                    <Pagination totalPage={claimTotalPage} activePage={claimActivePage} startIndex={claimStartIndex} endIndex={claimEndIndex} onPagePrevious={onClaimPagePrevious} onPageUp={onClaimPageUp} onPageNext={onClaimPageNext} />
                                    {claimTotalPage <= 1 && <div className="clear-b-scroll-sector">&nbsp;</div>}
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default PageLocks