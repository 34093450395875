import React from 'react'
import './style.css';
import TextField from '@material-ui/core/TextField';
import Label from '../Label';
const style = { width: "95%", border: "1px solid black", borderRadius: "4px" }
function NumericInput(props) {
    const required = props.required;
    let defaultClassName = props.defaultClassName ? props.defaultClassName : '';

    return (
        <div className="form-group">
            <Label label={props.label} />
            <TextField
                style={props.isBackgroundEnabled ? style : { width: "100%" }} id={props.id ? props.id : "standard-basic"} variant="outlined"
                required={required ? required : false}
                type={props.type}
                value={props.value}
                name={props.name}
                onChange={props.onValueChange}
                className={defaultClassName ? defaultClassName : required ? (props.value ? "" : "input-error") : ""}
            />
        </div>
    )
}

export default NumericInput;