import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { initialState  } from "./DropDownOptionInitialState";
import { DEFAULT_PAGING_SIZE } from "../../../utilities/staticConfigs";
import { getStorage } from "../../../utilities/browserStorage";
import service from "../services/service";

export const getAllSelectInput = createAsyncThunk(
    'claims/selectInput',
    async (payload, thunkAPI) => {
            const pageSize = DEFAULT_PAGING_SIZE;
            const page = 0;
            const practicePK = getStorage('practice');
            const claimPK = payload.claimPK ? payload.claimPK : "";
        
            const promises = [
                service.ListReferringProviders(pageSize, page, practicePK, null, claimPK).then(response => response.data),
                service.ListRenderingProviders(pageSize, page, practicePK, null, claimPK).then(response => response.data),
                service.ServiceLocationLists(practicePK, null,claimPK).then(response => {
                    let locations = []
                    response.data.map((item) => {
                        item.id = item.Id + '||' + item.location_type
                        locations.push(item)
                    })
                    return locations
                }),
                service.ListRelations(pageSize, page, claimPK).then(response => response.data),
                service.GetListClaimFrequency(pageSize, page, claimPK).then(response => response.data),
                service.ListOfUSStates(claimPK).then(response => response.data),
                service.ListDelayReasonCode(0, 0).then(response => response.data),
                service.ListTransportTypes(pageSize, page, claimPK).then(response=>response.data)
        ]
        try {
            const [
                referringProvider,
                renderingProvider,
                serviceLocations,
                relationList,
                claimFrequencyList,
                stateList,
                delayReasonCodeList,
                transportTypeList
            ] = await Promise.all(promises);

            return {
                renderingProvider,
                referringProvider,
                serviceLocations,
                relationList,
                claimFrequencyList,
                stateList,
                delayReasonCodeList,
                transportTypeList
            };

        } catch (error) {
            return thunkAPI.rejectWithValue({ errorMessage: 'Error getting Drop-down list data!' })
        }
    }
)

//professional claim stastus list api call
export const GetClaimStatusData = createAsyncThunk(
    /**
     * we pass a key here because we want to call this same api for both procedure and parent component status
     * while passing the key we can set state[key]=data stat will store data to appropriate state in initialstate
     */
    "claim-info/claimStatus", async (payload) => {
        const result = await service.ListClaimStatus(
            payload.pageSize, payload.page,
            payload.practicePK, payload.listType,
            payload.patientPK, payload.claimPK,true);
        if (result.data) {
            return {data:result.data,key:payload.key}
        }
    }
)

//professional claim sub status with status pklist api call
export const GetClaimSubStatusPkData = createAsyncThunk(
    "claim-info/claimSubStatusPK", async (payload) => {
        const result = await service.ListClaimStatusSubStatus(
            payload.pageSize, payload.page,
            payload.practicePK, payload.claimPK, payload.statusPK,true);
        if (result.data) {
            return {data:result.data,key:payload.key}
        }
    }
)

//professional claim sub status for responsibility
export const GetClaimSubStatusDataList = createAsyncThunk(
    "claim-info/claimSubStatusdatalist", async (payload) => {
        const result = await service.ListClaimStatusSubStatus(
            payload.pageSize, payload.page,
            payload.practicePK, payload.claimPK,"",true);
        if (result.data) {
            return result.data
        }
    }
)


//get Accident type list Api Call here
export const GetAccidentType = createAsyncThunk(
    "claim-info/accidentType", async (payload) => {
        const result = await service.ListAccidentTypes(
            payload.pageSize, payload.page,payload.claimPK);
        if (result.data) {
            return result.data
        }
    }
)

//get Accident type list Api Call here
export const GetStateList = createAsyncThunk(
    "claim-info/stateList", async (payload) => {
        const result = await service.ListOfUSStates(payload);
        if (result.data) {
            return result.data
        }
    }
)

//get ICD list api call

export const GetICDList = createAsyncThunk(
    "claim-info/icdlist", async (payload) => {
        let type = "dropdown"
        let page = 0;
        let page_size = 0;
        let practicePK = getStorage('practice');
        const result = await service.GetICD10List(page_size,page,practicePK,type,payload.query,payload.claimPK);
        if (result.data) {
            return result.data
        }
    }
)

//get CPT list api call

export const GetCPTList = createAsyncThunk(
    "claim-info/cptList", async (payload) => {
        let type = "dropdown"
        let page = 0;
        let page_size = 0;
        let practicePK = getStorage('practice');
        const result = await service.GetCPTCodeList(page_size, page, practicePK, type, payload.query, payload.claimPK,payload.dos);
        if (result.data) {
            return result.data
        }
    }
)

//get POS list api call

export const GetPOSList = createAsyncThunk(
    "claim-info/posList", async (payload) => {
        const result = await service.SearchPosCodes(payload);
        if (result.data) {
            return result?.data?.results
        }
    }
)

//get Tos list api call

export const GetTOSList = createAsyncThunk(
    "claim-info/tosList", async (payload) => {
        const result = await service.SearchTosCodes(payload);
        if (result.data) {
            return result?.data?.results
        }
    }
)

//get Tos list api call

export const GetModifierList = createAsyncThunk(
    "claim-info/modifierList", async (payload) => {
        const result = await service.GetModifier(payload.query);
        if (result.data) {
            return { data: result?.data?.results, modifier: payload.modifier }
        }
    }
)



const DropDownOptionSlice = createSlice({
    name: 'DropDownOptionSlice',
    initialState,
    reducers: {
        resetToInitial: () => initialState,
        resetClaimSubStatus:(state)=>{
            state.claimSubStatus=[];
        }
    },
    
    extraReducers: (builder) => {
        /**
         * getAllSelectInput pending case
         */
        builder.addCase(getAllSelectInput.pending, (state) => {
            state.isSelectInputDataLoaded = false;
            state.isErrorLoadingData = false;
        })
        
        /**
         * getAllSelectInput success case
         */
        builder.addCase(getAllSelectInput.fulfilled, (state,action) => {
            const {
                referringProvider,
                renderingProvider,
                serviceLocations,
                relationList,
                claimFrequencyList,
                stateList,
                delayReasonCodeList,
                transportTypeList
            } = action.payload;
            state.renderingProvider = renderingProvider;
            state.referringProvider = referringProvider;
            state.serviceLocations = serviceLocations;
            state.relationList = relationList;
            state.claimFrequencyList = claimFrequencyList;
            state.stateList = stateList,
            state.delayReasonCodeList = delayReasonCodeList 
            state.transportTypeList = transportTypeList
            state.isSelectInputDataLoaded = true;
            state.isErrorLoadingData = false;
        })

        /**
         * getAllSelectInput error case
         */
        builder.addCase(getAllSelectInput.rejected, (state) => {
            state.isSelectInputDataLoaded = false;
            state.isErrorLoadingData = true;
        })

        //CLAIM sTATUS type api success case
        builder.addCase(GetClaimStatusData.fulfilled, (state, actions) => {
            state[actions.payload.key] = actions.payload.data;
        })

        //CLAIM SUB STATUS type api success case
        builder.addCase(GetClaimSubStatusPkData.fulfilled, (state, actions) => {
            state[actions.payload.key] = actions.payload.data;
        })

        //CLAIM SUB STATUS type api success case
        builder.addCase(GetClaimSubStatusDataList.fulfilled, (state, actions) => {
            state["responsibilitySubStatus"] = actions.payload;
        })


        //CLAIM SUB STATUS type api success case
        builder.addCase(GetAccidentType.fulfilled, (state, actions) => {
            let accident_no;
            if (actions.payload.length > 0) {
                state.accidentTypeList = actions.payload.filter(item => item.active === true)
                accident_no = actions.payload.find((item => item.name.toLowerCase() == 'not related to accident'));
                if (accident_no)
                    state.AccidentNoId =accident_no.id;
                actions.payload.forEach((item) => {
                    if (item.name.toLowerCase() === 'related to other accident') {
                        state.otherAccidentNoId =item.id;
                    }
                })
            }
        })

        //state list success case
        builder.addCase(GetStateList.fulfilled, (state, actions) => {
            state.stateList = actions.payload;
        })

        //icd list success case
        builder.addCase(GetICDList.fulfilled, (state, actions) => {
            state.ICDLIST = actions.payload;
        })

        //cpt list success case
        builder.addCase(GetCPTList.fulfilled, (state, actions) => {
            state.CPTLIST = actions.payload;
        })

        //pos list success case
        builder.addCase(GetPOSList.fulfilled, (state, actions) => {
            state.posCodeList = actions.payload;
        })

        //tos list success case
        builder.addCase(GetTOSList.fulfilled, (state, actions) => {
            state.tosCodeList = actions.payload;
        })

        //MODIFIER list success case
        builder.addCase(GetModifierList.fulfilled, (state, actions) => {
            state[actions.payload.modifier] = actions.payload.data;
        })
    }
})
export const { resetToInitial,resetClaimSubStatus } = DropDownOptionSlice.actions;

export default DropDownOptionSlice.reducer;