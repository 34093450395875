import React, { useContext, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { PAYMENT_TYPES } from "../../../utilities/dictionaryConstants";
import i18n from "../../../utilities/i18n";
import CalendarInput from "../../commons/input/CalendarInput";
import NumericInput from '../../commons/input/NumericInput'
import SelectInput from "../../commons/input/SelectInput";
import { CommonDateFormat, currencyFormat } from "../../../utilities/commonUtilities";
import LoadingContext from "../../../container/loadingContext";
import service from "./service";
import CommonButton from "../../commons/Buttons";
import Notify from "../../commons/notify";
export const ActivePaymentPlanAdd = ({

  patientId,
  fetchPatientDetails,
  practicePK,
  closeModal,
}) => {
  const paymentTypeList = PAYMENT_TYPES;
  const paymentLinkList = [{ id: 1, name: "Link Payment To Balance" }];
  const [showNotify, setShowNotify] = useState('hide');
  const [notifyDescription, setNotifyDescription] = useState('');
  const [notifyType, setNotifyType] = useState('success');
  const setShowLoadingBar = useContext(LoadingContext);
  const [linkPaymentToBalance, setLinkPaymentToBalance] = useState();
  const [paymentPlanAmount, setPaymentPlanAmount] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const [noInstalments, setNoInstalments] = useState("");
  const [installmentStartDate, setInstallmentStartDate] = useState("");

  const [procedureList, setProcedureList] = useState([]);
  const [emiList, setEmiList] = useState([]);
  const [selectedProcedures, setSelectedProcedures] = useState([]);

  const addOrRemoveProcedures = (item) => {
    const items = [...selectedProcedures];
    const itemFound = items.find((element) => element.id === item.id);
    if (itemFound) {
      const newItems = items.filter((element) => element.id !== item.id);
      setSelectedProcedures(newItems);
    } else {
      setSelectedProcedures([
        ...items,
        { id: item.id, balance: Number(item.patient_balance)},
      ]);
    }
  };

  const getProcedureList = () => {
    setShowLoadingBar(true);
    service
      .getPatientProcedures(patientId, practicePK)
      .then((response) => {
        setShowLoadingBar(false);
        if (response.status === 200) {
          setProcedureList(response.data.procedures_list);
        } else {
          throw new Error(response.statusText);
        }
      })
      .catch(() => {
        setShowLoadingBar(false);
      });
  };

  useEffect(() => {
    getProcedureList();
  }, []);

  const onHandleChange = (e) => {
    let name = e.target.name;
    let value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    let trimmedValue = "";
    trimmedValue = value;

    if (name === "paymentPlanAmount") {
      // Allow only numbers with up to two decimal places
      let regex = /^\d*\.?\d{0,2}$/;
  
      if (regex.test(value)) {
        setPaymentPlanAmount(value);
      }
    }
    else if (name === "paymentType") setPaymentType(trimmedValue)
    else if (name === "noInstalments") setNoInstalments(trimmedValue.replace(/[^0-9]/g, ""));
    else if (name === "linkPaymentToBalance")
      setLinkPaymentToBalance(trimmedValue);
  }

  const onChangeCheck = (event, item) => {
    addOrRemoveProcedures(item);
  };

  const generateEMI = () => {
    setShowLoadingBar(true);
    const data = {
      payment_type: paymentType,
      installment_start_date: formatDate(installmentStartDate),
      number_of_installments: Number(noInstalments),
      payment_amount: Number(paymentPlanAmount),
    };
    service
      .generateInstallments(data)
      .then((response) => {
        setShowLoadingBar(false);
        if (response.status === 200 || response.status === 201) {
          setEmiList(response.data.installments);
        } else {
          throw new Error(response.statusText);
        }
      })
      .catch(() => {
        setShowLoadingBar(false);
        showNotifyWindow('show', 'error', "Error while saving data.");
      });
  };

  const onGenerateClickHandler = () => {
    if (linkPaymentToBalance!=="" && paymentType.length !== "" && installmentStartDate.length !== "" && noInstalments !== "" && paymentPlanAmount !== 0) {
      generateEMI();
    }
  };

  const formatDate = (selectdDate) => {
    // YYYY-MM-DD
    var d = new Date(selectdDate),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };
  const savePaymentPlan = () => {
    setShowLoadingBar(true);
    if (selectedProcedures.length == 0) {
      showNotifyWindow('show', 'error', i18n.t("paymentPlan.claim_selected_message"));
      setShowLoadingBar(false);
      return
    }
    const body = {
      payment_type: paymentType,
      installment_data: [...emiList],
      claim_list: [...selectedProcedures],
      payment_start_date: formatDate(installmentStartDate),
      total_installments: Number(noInstalments),
      total_amount: Number(paymentPlanAmount),
      link_to_balance: linkPaymentToBalance,
      patient_pk: patientId,
      installment_amount: emiList[0].installment_amount,
    };
    service
      .generatePaymentPlan(body)
      .then((response) => {
        setShowLoadingBar(false);
        if (response.status === 200 || response.status === 201) {
          fetchPatientDetails();
          closeModal(false);
        } else {
          if (response.status === 400) {
            if (response.data.message == "amount_procedure_balance") {
              showNotifyWindow('show', 'error', i18n.t("paymentPlan.newPaymentPlan.amountProcedureBalance"));
            }else if(response.data.message=="installment_amount_sum_exceeds_claim_balance_sum"){
              showNotifyWindow('show', 'error', i18n.t("paymentPlan.newPaymentPlan.installment_amount_sum_exceeds_claim_balance_sum"));
            }
          }
        }
      })
      .catch(() => {
        setShowLoadingBar(false);
      });
  };
  const onSaveClickHandler = () => {
    savePaymentPlan();
  };

  function showNotifyWindow(action, type, desc, age = 3000) {
    if (action === 'show') {
      setTimeout(() => {
        setShowNotify('hide');
      }, age)
    }
    setShowNotify(action);
    setNotifyType(type);
    setNotifyDescription(desc);
  }

  return (
    <>
      <Notify
        showNotify={showNotify}
        setShowNotify={setShowNotify}
        notifyDescription={notifyDescription}
        setNotifyType={setNotifyType}
        setNotifyDescription={setNotifyDescription}
        notifyType={notifyType}
      />
      <Form autoComplete="off">
        <div className="row pb-2 mt-4">
          <div className="col-3 pl-0">
            <div className="form-group padding-top padding-left20">
              <SelectInput
                name="linkPaymentToBalance"
                value={linkPaymentToBalance}
                setValue={setLinkPaymentToBalance}
                required={true}
                onValueChange={onHandleChange}
                data={paymentLinkList}
                label={i18n.t("paymentPlan.newPaymentPlan.linkPymntBalance")}
              />
            </div>
          </div>
          <div className="col-2 pl-0">
            <SelectInput
              name="paymentType"
              value={paymentType}
              setValue={setPaymentType}
              required={true}
              onValueChange={onHandleChange}
              data={paymentTypeList}
              label={i18n.t("paymentPlan.newPaymentPlan.paymentType")}
            />
          </div>
          <div className="col-2 pl-0">
            <div className="form-group padding-top">
              <NumericInput
                type="text"
                name="paymentPlanAmount"
                value={paymentPlanAmount}
                required={true}
                onValueChange={onHandleChange}
                label={i18n.t("paymentPlan.newPaymentPlan.paymentAmount")}
              />
            </div>
          </div>
          <div className="col-3 pl-0">
            <CalendarInput
              label={i18n.t("paymentPlan.newPaymentPlan.installmentDate")}
              name="installmentStartDate"
              selected={installmentStartDate}
              minDate={new Date()}
              required={true}
              onValueChange={(selected) => setInstallmentStartDate(selected)}
            />
          </div>
          <div className="col-2 pl-0">
            <div className="form-group padding-top">
              <NumericInput
                type="text"
                name="noInstalments"
                required={true}
                value={noInstalments}
                onValueChange={onHandleChange}
                label={i18n.t("paymentPlan.newPaymentPlan.noInstallments")}
              />
            </div>
          </div>
        </div>
        <div>
          <h5 className="padding-left5 textLeft">
            <b>{i18n.t("paymentPlan.newPaymentPlan.linkToBalance")}</b>
          </h5>
          <table className="table" id={"overflow_auto"}>
            <thead>
              <tr>
                <th className="width-60">
                  {i18n.t("paymentPlan.newPaymentPlan.dateOfService")}
                </th>
                <th>
                  {i18n.t("paymentPlan.newPaymentPlan.custom_claim_id")}
                </th>
                <th className="width-10perc">
                  {i18n.t("paymentPlan.newPaymentPlan.charges")}
                </th>
                <th className="width-10perc">
                  {i18n.t("paymentPlan.newPaymentPlan.insurancePayments")}
                </th>
                <th className="width-10perc">
                  {i18n.t("paymentPlan.newPaymentPlan.patientPayments")}
                </th>
                <th className="width-10perc">
                  {i18n.t("paymentPlan.newPaymentPlan.insuranceBalance")}
                </th>
                <th className="width-10perc">
                  {i18n.t("paymentPlan.newPaymentPlan.patientBalance")}
                </th>
                <th className="width-50">
                  {i18n.t("paymentPlan.newPaymentPlan.Select")}
                </th>
              </tr>
            </thead>
            <tbody>
              {procedureList.map((item, index) => {
                return (
                  <tr key={index}>
                    <td align="center">{item.dos
                      ? CommonDateFormat(item.dos)
                      : ""}</td>
                    <td align="center">{item.custom_claim_id}</td>
                    <td align="right">{currencyFormat(item.charge)}</td>
                    <td align="right">
                      {currencyFormat(item.insurance_payments)}
                    </td>
                    <td align="right">
                      {currencyFormat(item.patient_payments)}
                    </td>
                    <td align="right">
                      {currencyFormat(item.insurance_balance)}
                    </td>
                    <td align="right">
                      {currencyFormat(item.patient_balance)}
                    </td>
                    <td align="center" className="pt-0">
                      <input
                        onChange={(e) => onChangeCheck(e, item)}
                        type="checkbox"
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="div-float-right">
              <CommonButton variant="contained" disabled={procedureList.length <= 0} onClick={onGenerateClickHandler} label={i18n.t("paymentPlan.newPaymentPlan.generateInstlmntCalc")} />
            </div>
          </div>
        </div>
        <div className="pt-2">
          <table className="table" id={"overflow_auto"}>
            <thead>
              <tr>
                <th>{i18n.t("paymentPlan.newPaymentPlan.dueDate")}</th>
                <th>
                  {i18n.t("paymentPlan.newPaymentPlan.installmentAmount")}
                </th>
                <th>{i18n.t("paymentPlan.newPaymentPlan.installmentNo")}</th>
              </tr>
            </thead>
            <tbody>
              {emiList.map((item, index) => {
                return (
                  <tr key={index}>
                    <td align="center">{item.due_date}</td>
                    <td align="center">
                      {currencyFormat(item.installment_amount)}
                    </td>
                    <td align="center">{item.installment_count}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="row">
          <div className="col-12">
            
            <div className="div-float-right">
              <CommonButton variant="contained" disabled={emiList.length > 0 ? false:true}
               onClick={onSaveClickHandler} label={i18n.t("paymentPlan.newPaymentPlan.save")} /> 
            </div>
          </div>
        </div>
      </Form>
    </>
  );
};