import i18n from "../../../../utilities/i18n"

const commmonWidth = {
    small: "small",
    extraSmall: "extra-small",
    medium: "medium"
}
 
export const tableData = [
    {
        "head": {
            "name": "checkbox",
            "type": "checkbox",
            "width": "check_box",
            "sort": false,
            "value": false,
        },
        "body": {
            "id": "",
            "name": "checkbox",
            "type": "checkbox",
            "width": "check_box",
            "align": "center",
            "value": false
        },
    },
    {
        "head": {
            name: i18n.t("patientPages.patients.labelPatientID"),
            type: "number",
            width: commmonWidth.small,
            sort: false
        },
        "body": {
            id: "",
            name: "custom_patient_id",
            value: "",
            type: "number",
            align: "left",
            width: commmonWidth.small,
        }
    },
    {
        "head": {
            name: i18n.t("patientPages.patients.labelPatientName"),
            type: "number",
            width: commmonWidth.medium,
            sort: false,
        },
        "body": {
            id: "",
            name: "name",
            value: "",
            type: "string",
            align: "left",
            width: commmonWidth.medium,
        }
    },
    {
        "head": {
            name: i18n.t("patientPages.patients.labelDOB"),
            type: "string",
            width: commmonWidth.small,
            sort: false,
        },
        "body": {
            id: "",
            name: "date_of_birth",
            value: "",
            type: "Date",
            align: "left",
            "needDateFormat": "yes",
            width: commmonWidth.small,
        }
    },
    {
        "head": {
            name: i18n.t("patientPages.patients.labelGender"),
            type: "string",
            width: commmonWidth.small,
            sort: false,
        },
        "body": {
            id: "",
            name: "gender",
            value: "",
            type: "string",
            align: "left",
            width: commmonWidth.small,
        }
    },
    {
        "head": {
            name: i18n.t("patientPages.patients.labelPatientAddress"),
            type: "string",
            width: commmonWidth.medium,
            sort: false,
        },
        "body": {
            id: "",
            name: "address",
            value: "",
            type: "string",
            align: "left",
            width: commmonWidth.medium,
        }
    }
]

export const DemoMergeTableData = {
    "name": "dynamicTable",
    "isResize": false,
    "isReportTable": true,
    "isFixedHead": true,
    "tableHeadings": tableData.map(item => item.head),
    "tableBodyData": [tableData.map(item => item.body)]
}