import SelectInput from '../../commons/input/SelectInput'
import React, { useEffect, useState, useContext } from 'react'
import { Form } from 'react-bootstrap'
import PhoneInput from '../../commons/PhoneInput/PhoneInput'
import { useHistory } from 'react-router-dom'
import i18n from '../../../utilities/i18n'
import GeoCodeSearch from '../../commons/helpers/geoCodeApiSearch'
import TextInput from '../../commons/input/input'
import Notify from '../../commons/notify';
import { getStorage } from '../../../utilities/browserStorage';
import { DAYORMONTH, DEFAULT_COUNTRY, ROUTE_INSURANCE_COMPANIES, ROUTE_USER_INSURANCE_COMPANIES } from '../../../utilities/staticConfigs'
import { NETWORK_STATUS } from '../../../utilities/dictionaryConstants'
import { ADD_SUCCESS, ADD_ERROR, UPDATE_SUCCESS } from '../../../utilities/labelConfigs';
import LoadingContext from '../../../container/loadingContext';
import service from './service';
import TextAreaInput from '../../commons/textarea/textarea'
import { US_STATES } from '../../../utilities/usStates';
import { permission_key_values_accountsetup, super_admin_privileges, pms_staff_user_privilege } from '../../../utilities/permissions'
import { checkPermission } from '../../../utilities/commonUtilities'

export const InsuranceCompanyAdd = (props) => {
    const isAdminModule = getStorage('isAdminModule');
    const practicePK = getStorage("practice");
    // variables
    const [insuranceCompaniesVariables, setInsuranceCompaniesVariables] = useState({
        "apms_payer_id": "", "name": "", "plan_name": "", "payer_type": "", "network_status": "",
        "timely_filling_value": "", "timely_filling_date": 1, "address1": "", "address2": "", "zip_code": "",
        "claim_submission_mode": "", "default_claim_status": "", "payer_id": "", "professional_claims": "",
        "professional_claims_value": "", "institutional_claims": "", "institutional_claims_value": "",
        "remits": "", "eligibility": "", "claims_monitoring": "",
        "secondary_claim": "", "claims_attachments": "", "estimation": "", "estimation_value": "", 'selected_insurances': "",
        "practice": practicePK, "phone": "", "fax": "", "state": '', "city": '',
        "country": DEFAULT_COUNTRY, "notes": "", "alert": "", "payment_source": ""
    });
    const [state, setState] = useState("");
    const [apiPayerId, setApiPayerId] = useState("");
    const [country, setCountry] = useState(DEFAULT_COUNTRY);
    const [city, setCity] = useState("");
    const [zip, setZIP] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [faxNumber, setFaxNumber] = useState("");
    const [claimStatusList, setClaimStatusList] = useState([]);
    const [paymentSourceCodesList, setPaymentSourceCodesList] = useState([]);
    const [claimSubmissionList, setClaimSubmissionList] = useState([]);
    const [payerTypeList, setPayerTypeList] = useState([]);
    const [insurancePK, setInsurancePK] = useState('');
    const setShowLoadingBar = useContext(LoadingContext);
    const [showNotify, setShowNotify] = useState('hide');
    const [notifyDescription, setNotifyDescription] = useState('');
    const [notifyType, setNotifyType] = useState('success');
    const history = useHistory();

    
    function onHandleInsuranceCompanyAdd(e) {
        const { name, value } = e.target;

        if (name === 'timely_filling_value' && value.length && parseInt(value, 10) < 0) {
            return;
        }

        let updatedState = { ...insuranceCompaniesVariables, [name]: value };

        if (name === "claim_submission_mode") {

            const selectedClaim = claimSubmissionList.find(item => item.id == value);

            if (selectedClaim) {
                if (selectedClaim.name === "Send Claims to Clearninghouse to Print and Mail") {
                    updatedState.payer_id = selectedClaim.default_value || "";
                }
                else if (selectedClaim.name === "Do not Send the Claims to Clearninghouse") {
                    updatedState.payer_id = "";
                }
                else if (selectedClaim.name === "Send Electronic Claims Via Clearninghouse") {
                    if (updatedState.payer_id.length > 0){
                        updatedState.payer_id = apiPayerId == "" ? insuranceCompaniesVariables.payer_id : apiPayerId
                    } 
                    else {
                        updatedState.payer_id = apiPayerId
                    }
                }
            }
        }

        if (name === "payer_id") {
            if (insurancePK !== "") {
                updatedState.payer_id = value
           } 
       }

        setInsuranceCompaniesVariables(updatedState);
    }

    useEffect(() => {
        setInsuranceCompaniesVariables({
            ...insuranceCompaniesVariables, "city": city, "state": state, "zip_code": zip, "country": country
        })
    }, [city, country, state, zip]);
    useEffect(() => {
        setInsuranceCompaniesVariables({
            ...insuranceCompaniesVariables, "phone": phoneNumber, "fax": faxNumber
        })
    }, [phoneNumber, faxNumber]);

    useEffect(() => {
        getClaimStatusData(0, 0);
        getPayerTypesList(0, 0);
        getClaimSubmissionData();
        getPaymentSourceCodesListAll();
        if (props.location.state && props.location.state.selectedID && props.location.state.selectedID !== 0) {
            setInsurancePK(props.location.state.selectedID);
            GetInsuranceEditData(props.location.state.selectedID);

        } else if (props.location.state && props.location.state.insuranceID && props.location.state.insuranceID !== 0) {
            setInsuranceCompaniesVariables({
                ...insuranceCompaniesVariables, "selected_insurances": props.location.state.insuranceID
            })
            getMasterInsuranceDetails(props.location.state.insuranceID)
        }
    }, []);

    function goToInsuranceList() {
        if (isAdminModule === 'true')
            history.push(ROUTE_INSURANCE_COMPANIES);
        else
            history.push(ROUTE_USER_INSURANCE_COMPANIES);
    }
    const getClaimStatusData = (pageSize, page) => {
        const result = service.ListClaimStatus(pageSize, page, practicePK, null, '', '');
        result.then(response => {
            setClaimStatusList(response.data);
        });
    };
    const getPayerTypesList = (pageSize, page) => {
        const result = service.GetPayerTypesList(pageSize, page);
        result.then(response => {
            setPayerTypeList(response.data);
        });
    };

    const getMasterInsuranceDetails = (insuranceID) => {
        setShowLoadingBar(true);
        const result = service.GetInsuranceCompany(insuranceID, '');
        result.then(response => {
            setInsuranceCompaniesVariables({
                ...insuranceCompaniesVariables,
                "selected_insurances": response.data.id,
                "apms_payer_id": response.data.apms_payer_id,
                "name": response.data.name,
                "plan_name": response.data.plan_name,
                "professional_claims": response.data.professional_claims,
                "professional_claims_value": response.data.professional_claims_value,
                "institutional_claims": response.data.institutional_claims,
                "institutional_claims_value": response.data.institutional_claims_value,
                "remits": response.data.remits,
                "remits_value": response.data.remits_value,
                "eligibility": response.data.eligibility,
                "eligibility_value": response.data.eligibility_value,
                "claims_monitoring": response.data.claims_monitoring,
                "claims_monitoring_value": response.data.claims_monitoring_value,
                "secondary_claim": response.data.secondary_claim,
                "secondary_claim_value": response.data.secondary_claim_value,
                "claims_attachments": response.data.claims_attachments,
                "claims_attachments_value": response.data.claims_attachments_value,
                "estimation_value": response.data.esitmation_required_value,
                "estimation": response.data.estimation,
                "payer_id": response.data.payer_id,
                "address1": response.data.street1,
                "address2": response.data.street2,
                "city": response.data.city,
                "state": response.data.state,
                "zip_code": response.data.zip_code,
                "payment_source": response?.data?.payment_source || ""
            })
            setApiPayerId(response.data?.payer_id);
        });
        setShowLoadingBar(false);

    };
    function showNotifyWindow(action, type, desc, age = 3000) {
        if (action === 'show') {
            setTimeout(() => {
                setShowNotify('hide');
            }, age)
        }
        setShowNotify(action);
        setNotifyType(type);
        setNotifyDescription(desc);
    }
    function onSavePracticeInsurances(e) {
        e.preventDefault();
        if (!insuranceCompaniesVariables.name || !insuranceCompaniesVariables.address1 || !insuranceCompaniesVariables.city || !insuranceCompaniesVariables.state || !insuranceCompaniesVariables.zip_code || !insuranceCompaniesVariables.payment_source || !insuranceCompaniesVariables.payer_id ) {
            return;
        } else {
            setShowLoadingBar(true);
            let result = null;
            if (insurancePK) {
                result = service.UpdateInsuranceCompany(insurancePK, insuranceCompaniesVariables, isAdminModule);
            } else {
                const payload = insuranceCompaniesVariables;
                /**
                 * Delete these keys: Only pass their ids to the server to avoid Django errors.
                 */
                delete payload.professional_claims_value;
                delete payload.institutional_claims_value;
                delete payload.remits_value
                delete payload.eligibility_value
                delete payload.claims_monitoring_value
                delete payload.claims_attachments_value
                delete payload.secondary_claim_value
                delete payload.estimation_value

                result = service.AddInsuranceCompany(payload);
            }
            result.then((response) => {
                setShowLoadingBar(false);
                if (response.status === 201 || response.status === 200) {
                    setInsurancePK('');
                    if (response.status === 201)
                        showNotifyWindow('show', 'success', ADD_SUCCESS);
                    else showNotifyWindow('show', 'success', UPDATE_SUCCESS);
                    goToInsuranceList();
                } else if (response.status === 400 && response.data.record_already_exists !== undefined) {
                    showNotifyWindow('show', 'error', i18n.t('errorMessages.record_exists'));
                } else {
                    showNotifyWindow('show', 'error', ADD_ERROR);
                }
            });
        }
    }
    function GetInsuranceEditData(selectedID) {
        setShowLoadingBar(true);
        const result = service.GetInsuranceCompany(selectedID, practicePK, isAdminModule);
        result.then(response => {
            setInsuranceCompaniesVariables({
                ...insuranceCompaniesVariables,
                "selected_insurances": response.data.id,
                "apms_payer_id": response.data.apms_payer_id,
                "name": response.data.name,
                "plan_name": response.data.plan_name,
                "professional_claims": response.data.professional_claims,
                "professional_claims_value": response.data.professional_claims_value,
                "institutional_claims": response.data.institutional_claims,
                "institutional_claims_value": response.data.institutional_claims_value,

                "remits": response.data.remits,
                "remits_value": response.data.remits_value,
                "eligibility": response.data.eligibility,
                "eligibility_value": response.data.eligibility_value,
                "claims_monitoring": response.data.claims_monitoring,
                "claims_monitoring_value": response.data.claims_monitoring_value,
                "secondary_claim": response.data.secondary_claim,
                "secondary_claim_value": response.data.secondary_claim_value,
                "claims_attachments": response.data.claims_attachments,
                "claims_attachments_value": response.data.claims_attachments_value,

                "estimation": response.data.estimation_required_value,
                "payer_id": response.data.payer_id,
                "payer_type": response.data.payer_type,
                "timely_filling_value": response.data.timely_filling_value,
                "timely_filling_date": response.data.timely_filling_date ? response.data.timely_filling_date : 1,
                "address1": response.data.street1,
                "address2": response.data.street2,
                "city": response.data.city,
                "state": response.data.state,
                "zip_code": response.data.zip_code,
                "phone": response.data.phone,
                "fax": response.data.fax,
                "practice": practicePK,
                "network_status": response.data.network_status,
                "claim_submission_mode": response.data.submission_mode,
                "default_claim_status": response.data.claim_status,
                "payment_source": response.data.payment_source || "",
                "notes": response.data.notes,
                "alert": response.data.alert
            })
            setApiPayerId(response.data?.payer_id);
            setPhoneNumber(response?.data?.phone ? response?.data?.phone : "");
            setFaxNumber(response?.data?.fax ? response?.data?.fax : "")
            setShowLoadingBar(false);
        });
    }

    async function getPaymentSourceCodesListAll() {
        try {
            const { data } = await service.getPaymentSourceCodesListAll(0, 0);
            setPaymentSourceCodesList(data || []);
        } catch (error) {
            console.error("Error fetching Payment Source Codes List:", error);
            setPaymentSourceCodesList([]);
        }
    }

    const getClaimSubmissionData = async()=> {
        try {
            let response = await service.getClaimSubmission();
            if (response.data.length > 0) {
                setClaimSubmissionList(response.data || []);
            }
        } catch (error) {
            console.error("Error fetching claim submission data:", error);   
            setClaimSubmissionList([]);     
        }
    }


    return (
        <React.Fragment>
            <Notify showNotify={showNotify} setShowNotify={setShowNotify} notifyDescription={notifyDescription} setNotifyType={setNotifyType} setNotifyDescription={setNotifyDescription} notifyType={notifyType} />
            <div className='col-md-12'>
                <div className='box box-content-white padding-bottom20 margin-right15'>
                    <div className='box-content-white'>
                        <Form autoComplete='off'>
                            <div className='row'>
                                <div className='col-2'>
                                    <TextInput disabled={true} id="payer" name="apms_payer_id" value={insuranceCompaniesVariables.apms_payer_id} onValueChange={onHandleInsuranceCompanyAdd} label={i18n.t('dictionariesPages.dictInsuranceCompanies_Add.apms_payer_id')} />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-2'>
                                    <TextInput id="name" required={true} name="name" value={insuranceCompaniesVariables.name} onValueChange={onHandleInsuranceCompanyAdd} label={i18n.t('dictionariesPages.dictInsuranceCompanies_Add.name')} />
                                </div>
                                <div className='col-2'>
                                    <TextInput id="plan_name" name="plan_name" value={insuranceCompaniesVariables.plan_name} onValueChange={onHandleInsuranceCompanyAdd} label={i18n.t('dictionariesPages.dictInsuranceCompanies_Add.plan_name')} />
                                </div>
                                <div className='col-2'>
                                        <SelectInput name='payment_source' required data={paymentSourceCodesList ?? []} value={insuranceCompaniesVariables.payment_source} onValueChange={onHandleInsuranceCompanyAdd} label={i18n.t('dictionariesPages.paymentSourceCode.name')} />
                                    </div>
                            </div>
                            <div className='row'>
                                <div className='col-2 padding-top10'>
                                    <SelectInput id="payer_type" data={payerTypeList} name="payer_type" value={insuranceCompaniesVariables.payer_type} onValueChange={onHandleInsuranceCompanyAdd} label={i18n.t('dictionariesPages.dictInsuranceCompanies_Add.payer_type')} />
                                </div>
                                <div className='col-2 padding-top10'>
                                    <SelectInput id="network_status" data={NETWORK_STATUS} name="network_status" value={insuranceCompaniesVariables.network_status} onValueChange={onHandleInsuranceCompanyAdd} label={i18n.t('dictionariesPages.dictInsuranceCompanies_Add.network_status')} />
                                </div>
                                <div className='col'>
                                    <div className='row'>
                                        <div className='col-3 margin-top10'>
                                            <TextInput id="timely_filling_value" type='number' name="timely_filling_value" value={insuranceCompaniesVariables.timely_filling_value} onValueChange={onHandleInsuranceCompanyAdd} label={i18n.t('dictionariesPages.dictInsuranceCompanies_Add.timely_filling_limit')} />
                                        </div>
                                        <div className='col-2 margin-top-35'>
                                            <SelectInput data={DAYORMONTH} id="timely_filling_date" name="timely_filling_date" value={insuranceCompaniesVariables.timely_filling_date} onValueChange={onHandleInsuranceCompanyAdd} selectOptionRemove={true} /> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className=''>
                                <div className='row'>
                                    <div className='col-2'>
                                        <TextInput name='address1' id="address1-input" required={true} value={insuranceCompaniesVariables.address1} onValueChange={onHandleInsuranceCompanyAdd} label={i18n.t('dictionariesPages.dictInsuranceCompanies_Add.address1')} />
                                    </div>
                                    <div className='col-2'>
                                        <TextInput name='address2' id="address2-input" value={insuranceCompaniesVariables.address2} onValueChange={onHandleInsuranceCompanyAdd} label={i18n.t('dictionariesPages.dictInsuranceCompanies_Add.address2')} />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-2'>
                                        <TextInput name='city' id="city-input" required={true} value={insuranceCompaniesVariables.city} onValueChange={onHandleInsuranceCompanyAdd} label={i18n.t('dictionariesPages.dictInsuranceCompanies_Add.city')} />
                                    </div>
                                    <div className='col-2'>
                                        <SelectInput listItem={'US_StateList'} required={true} id="state" data={US_STATES} name="state" value={insuranceCompaniesVariables.state} onValueChange={onHandleInsuranceCompanyAdd} label={i18n.t('patientPages.patientInfo.labelState')}/>
                                    </div>
                                    <div className='col-2'>
                                        <div className="form-group">
                                            <GeoCodeSearch id="location_data_zip" name="zip"
                                                required={true} label={i18n.t('dictionariesPages.dictInsuranceCompanies_Add.zip_code')}
                                                setlocState={setState} setLocCountry={setCountry} setLocZipCode={setZIP}
                                                setLocCity={setCity} defaultValue={insuranceCompaniesVariables.zip_code}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-2'>
                                        <PhoneInput id="phone_number" name="Phone Number" value={phoneNumber} onValueChange={setPhoneNumber} label={i18n.t("dictionariesPages.dictInsuranceCompanies_Add.phone_number")} />
                                    </div>
                                    <div className='col-2'>
                                        <PhoneInput id="fax_number" name="Fax Number" value={faxNumber} onValueChange={setFaxNumber} label={i18n.t("dictionariesPages.dictInsuranceCompanies_Add.fax_number")} />
                                    </div>
                                </div>
                            </div>
                            <div className='row mb-3'>
                                <div className='col pl-2'>
                                    <div className='div-inline'>{i18n.t("dictionariesPages.dictInsuranceCompanies_Add.configuration_management")}</div>
                                </div>
                            </div>
                            <div className=''>
                                <div className='row'>
                                    <div className='col-4'>
                                        <SelectInput name='claim_submission_mode' id="claim_submission_mode" data={claimSubmissionList}  value={insuranceCompaniesVariables.claim_submission_mode} onValueChange={onHandleInsuranceCompanyAdd} label={i18n.t("dictionariesPages.dictInsuranceCompanies_Add.claim_submission_mode")} />
                                    </div>
                                    <div className='col-2'>
                                        <SelectInput name='default_claim_status' data={claimStatusList} value={insuranceCompaniesVariables.default_claim_status} onValueChange={onHandleInsuranceCompanyAdd} label={i18n.t("dictionariesPages.dictInsuranceCompanies_Add.default_claim_status")} />
                                    </div>
                                </div>
                            </div>
                            <div className='row mb-3'>
                                <div className='col pl-2'>
                                    <div className='div-inline'>{i18n.t("dictionariesPages.dictInsuranceCompanies_Add.clearinghouseConnectionStatus")}</div>
                                </div>
                            </div>
                            <div className=''>
                                <div className='row'>
                                    <div className='col-2'>
                                        <TextInput required={true} className={!insuranceCompaniesVariables.payer_id ? "input-error" : ""}  name='payer_id' id="payerID-input" value={insuranceCompaniesVariables.payer_id} onValueChange={onHandleInsuranceCompanyAdd} label={i18n.t("dictionariesPages.dictInsuranceCompanies_Add.payer_id")} />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-2'>
                                        <TextInput disabled={true} name='professional_claims' id="professional_claims-input" value={insuranceCompaniesVariables.professional_claims_value} onValueChange={onHandleInsuranceCompanyAdd} label={i18n.t("dictionariesPages.dictInsuranceCompanies_Add.professional_claims")} />
                                    </div>
                                    <div className='col-2'>
                                        <TextInput disabled={true} name='institutional_claims' id="institutional_claims-input" value={insuranceCompaniesVariables.institutional_claims_value} onValueChange={onHandleInsuranceCompanyAdd} label={i18n.t("dictionariesPages.dictInsuranceCompanies_Add.institutional_claims")} />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-2'>
                                        <TextInput disabled={true} name='remits' id="remits-input" value={insuranceCompaniesVariables.remits_value} onValueChange={onHandleInsuranceCompanyAdd} label={i18n.t("dictionariesPages.dictInsuranceCompanies_Add.remits")} />
                                    </div>
                                    <div className='col-2'>
                                        <TextInput disabled={true} name='eligibility' id="eligibility-input" value={insuranceCompaniesVariables.eligibility_value} onValueChange={onHandleInsuranceCompanyAdd} label={i18n.t("dictionariesPages.dictInsuranceCompanies_Add.eligibility")} />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-2'>
                                        <TextInput disabled={true} name='claims_monitoring' id="claims_monitoring-input" value={insuranceCompaniesVariables.eligibility_value} onValueChange={onHandleInsuranceCompanyAdd} label={i18n.t("dictionariesPages.dictInsuranceCompanies_Add.professional_claims")} />
                                    </div>
                                    <div className='col-2'>
                                        <TextInput disabled={true} name='secondary_claim' id="secondary_claim-input" value={insuranceCompaniesVariables.secondary_claim_value} onValueChange={onHandleInsuranceCompanyAdd} label={i18n.t("dictionariesPages.dictInsuranceCompanies_Add.secondary_claims")} />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-2'>
                                        <TextInput disabled={true} name='claims_attachments' id="claims_attachments-input" value={insuranceCompaniesVariables.claims_attachments_value} onValueChange={onHandleInsuranceCompanyAdd} label={i18n.t("dictionariesPages.dictInsuranceCompanies_Add.claim_attachments")} />
                                    </div>
                                    <div className='col-2'>
                                        <TextInput disabled={true} name='estimation_value' id="estimation_value-input" value={insuranceCompaniesVariables.estimation_value} onValueChange={onHandleInsuranceCompanyAdd} label={i18n.t("dictionariesPages.dictInsuranceCompanies_Add.estimation")} />
                                    </div>
                                </div>
                            </div>
                            <div className=''>
                                <div className='margin-bottom-12'>
                                    <div className='row'>
                                        <div className='col-8'>
                                            <TextAreaInput name='notes' id="note-input" rows={5} value={insuranceCompaniesVariables.notes} onValueChange={onHandleInsuranceCompanyAdd} label={i18n.t("dictionariesPages.dictInsuranceCompanies_Add.notes")} />
                                        </div>
                                    </div>
                                </div>
                                <div className='margin-bottom-12'>
                                    <div className='row'>
                                        <div className='col-8'>
                                            <TextInput name='alert' id="alert-input" value={insuranceCompaniesVariables.alert} onValueChange={onHandleInsuranceCompanyAdd} label={i18n.t("dictionariesPages.dictInsuranceCompanies_Add.alert")} />
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-8 justify-right'>
                                        <div className='padding-left15 alignLeft'>
                                            <button type='button' onClick={goToInsuranceList} className='btn btn-secondary px-4 btn-common-height35 margin-top2'>{i18n.t("dictionariesPages.dictInsuranceCompanies_Add.cancel")}</button>
                                        </div>
                                        <div className='padding-left15 alignLeft'>
                                            {checkPermission(
                                                isAdminModule !== 'true'
                                                    ? permission_key_values_accountsetup.account_setup_insurance_management_modify
                                                    : super_admin_privileges.super_admin_full_privilege, pms_staff_user_privilege   
                                            ) && 
                                            <button type='button' id="saveButton" onClick={onSavePracticeInsurances} className='btn btn-primary-blue btn-common-height35'>{i18n.t("dictionariesPages.dictInsuranceCompanies_Add.save")}</button>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </div>    
                </div>
            </div>
        </React.Fragment>
    )
}
