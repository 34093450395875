import api from "../../../service/api";
import { getStorage } from '../../../utilities/browserStorage';

const ListPatientTypes = async (pageSize, page, practicePK, patientPK) => {
    let path = 'practice/patient-type/?page_size=' + pageSize + '&page=' + page + '&practice_pk=' + practicePK;
    if (patientPK)
        path += '&opened_patient_pk='+ patientPK;
    return api.get(path);
};

const ListLanguages = async (pageSize, page, practicePK, patientPK) => {
    let path = "practice/patient-language/?page_size=" + pageSize + "&page=" + page + '&practice_pk=' + practicePK;
    if (patientPK)
        path += '&opened_patient_pk='+ patientPK;
    return api.get(path);
};

const GetPatientDefaults = async (patientPK, fields) => {
    let path = `patient/patient-defaults/${patientPK}?fields=${fields}`;
    const response = await api.get(path);
    return response.data;
};

const ARStatusNoteList =  async(pageSize, page,practicePK,) => {
    let path =  'practice/ar-status/?page_size='+ pageSize +'&page='+ page +'&practice_pk='+ practicePK;
    return api.get(path)
}

const ARActionNoteList =  async (pageSize, page, practicePK) => {
    let path =  'practice/ar-action/?page_size=' + pageSize + '&page=' + page + '&practice_pk=' + practicePK;
    return api.get(path);
}



const ListRenderingProviders = async (pageSize, page, practicePK, patientPK, claimPK) => {
    let path = "user/provider/?list=true&page_size=" + pageSize + "&page=" + page + '&practice_pk=' + practicePK;
    if (patientPK)
        path += '&opened_patient_pk='+ patientPK;
    if (claimPK)
        path += '&opened_claim_pk='+ claimPK;
    return api.get(path);
};

const ListServiceLocations = async (pageSize, page, practicePK) => {
    let path = "user/service-locations/?list=true&page_size=" + pageSize + "&page=" + page + '&practice_pk=' + practicePK;
    return api.get(path);
};

const ListReferringProviders = async (pageSize, page, practicePK, patientPK, claimPK) => {
    let path = "user/referring-provider/?list=true&page_size=" + pageSize + "&page=" + page + '&practice_pk=' + practicePK;
    if (patientPK)
        path += '&opened_patient_pk='+ patientPK;
    if (claimPK)
        path += '&opened_claim_pk='+ claimPK;
    return api.get(path);
};

const ListReferringProviderGroups = async (pageSize, page, practicePK, patientPK) => {
    let path = "user/referring-provider-group/?list=true&page_size=" + pageSize + "&page=" + page + '&practice_pk=' + practicePK;
    if (patientPK)
        path += '&opened_patient_pk='+ patientPK;
    return api.get(path);
};

const ListRelationShips = async (pageSize, page, patientPK, type) => {
    let path = "super-admin/relationship/?page_size=" + pageSize + "&page=" + page;
    if (patientPK)
        path += '&opened_patient_pk='+ patientPK;
    if (type)
        path += '&type='+ type;
    return api.get(path);
};

const ListPatients = async (query) => {
    let path = "patient/patients/"+ query;
    return api.get(path);
};

const getPatientData = async (patientPK) => {
    let path = "patient/patients/" + patientPK;
    return api.get(path);
};

const getPatientInfoData = async (patientInfoPK) => {
    let path = "patient/patient-info/" + patientInfoPK;
    return api.get(path);
};


const ListInsuranceInfos = async (patientPK) => {
    let path = "patient/insurance-info-details/?patient_pk=" + patientPK;
    return api.get(path);
};
const ListPastCoverageInsuranceInfos = async (patientPK, status) => {
    let path = "patient/insurance-info-details/?patient_pk=" + patientPK + "&status=" + status;
    return api.get(path);
};

const ListPolicyTypes = async (pageSize, page, patientPK) => {
    let path = "super-admin/policy-type/?page_size=" + pageSize + "&page=" + page;
    if (patientPK)
        path += '&opened_patient_pk='+ patientPK;
    return api.get(path);
};

const SaveInsuranceDetails = async (data) => {
    let path = "patient/insurance-info/";
    return api.post(path, data);
};

const GetInsuranceDetails = async (insuranceInfoPK) => {
    let path = "patient/insurance-info/" + insuranceInfoPK;
    return api.get(path);
};

const UpdateInsuranceDetails = async (insuranceInfoPK, data) => {
    let path = "patient/insurance-info/" + insuranceInfoPK;
    return api.put(path, data);
};

const GetFamilyMembers = async (patientPK) => {
    let path = "patient/patient-family-member/?patient_pk=" + patientPK;
    return api.get(path);
};

const ListAccidentLocation = async () => {
    let path = "super-admin/us-states/?page=0";
    return api.get(path);
};

const ListTypeOfAccident = async (pageSize, patientPK) => {
    let path = "super-admin/accident-type/?page_size" + pageSize + "&page=0";
    if (patientPK) path += '&opened_patient_pk='+ patientPK;
    return api.get(path);
};

const ListTypeOfAssignmentBenefits = async (pageSize, patientPK) => {
    let path = "super-admin/assignment-benefit/?page_size" + pageSize + "&page=0";
    if (patientPK) path += '&opened_patient_pk='+ patientPK;
    return api.get(path);
};

const ListPatientsToAdd = async (practicePK, patientPK, familyDOB, searchedPatientPK) => {
    //tod
    let path = "patient/family-patients-list/?practice_pk=" + practicePK + "&patient_pk=" + patientPK + "&page=0";
    if (familyDOB)
        path = path + "&date_of_birth=" + familyDOB;
    if (searchedPatientPK)
        path = path + "&search_patient_pk=" + searchedPatientPK;
    return api.get(path);
};

const AddToFamilyMember = async (data) => {
    let path = "patient/patient-family-member/";
    return api.post(path, data);
};

const DeleteFamilyMember = async (patientPK, id) => {
    let path = "patient/patient-family-member/" + id;
    return api.destroy(path);
};

const SavePatientAlert = async (data) => {
    let path = "patient/alerts/";
    return api.post(path, data);
};

const ListPatientAlert = async (patientPK) => {
    let path = "patient/alerts/?patient_pk=" + patientPK + "&page=0";
    return api.get(path);
};

const GetPatientAlert = async (id) => {
    let path = "patient/alerts/" + id;
    return api.get(path);
};

const DeletePatientAlert = async (id) => {
    let path = "patient/alerts/" + id;
    return api.destroy(path);
};

const UpdatePatientAlert = async (id, data) => {
    let path = "patient/alerts/" + id;
    return api.put(path, data);
};

const SavePatientNote = async (patientPK, data) => {
    let path = "claim/notes/?patient_pk=" + patientPK;
    return api.post(path, data);
};

const ListTypeOfNotes = async (pageSize, page, practicePK) => {
    let path = "practice/note-type/?page_size=" + pageSize + "&page=" + page + "&practice_pk=" + practicePK;
    return api.get(path);
};

const ListPatientNotes = async (practicePK, patientPK, noteType, category) => {
    let path = "claim/notes/?practice_pk="+practicePK+"&patient_pk=" + patientPK +"&category="+ category + "&page=0";
    if (noteType !== '0' && noteType)
        path += "&notes_type=" + noteType;
        return api.get(path);
};

const GetPatientNote = async (id) => {
    let path = "claim/notes/" + id;
    return api.get(path);
};

const DeletePatientNotes = async (id) => {
    let path = "claim/notes/" + id;
    return api.destroy(path);
};

const UpdatePatientNote = async (id, data) => {
    let path = "claim/notes/" + id;
    return api.put(path, data);
};

const UpdateInsuranceCardImage = async (insuranceInfoPK, data) => {
    let path = 'patient/insurance-card-image/' + insuranceInfoPK;
    return api.put(path, data);
}

const DeleteInsuranceCardImage = async (insuranceInfoPK, Card) => {
    let path = 'patient/insurance-card-image/' + insuranceInfoPK + '?card=' + Card;
    return api.destroy(path);
}

const ListInsuranceNames = async (pageSize, page, practicePK, patientPK) => {
    let path = "practice/insurance-company/?page_size=" + pageSize + "&page=" + page +"&practice_pk="+ practicePK +'&list='+ true;
    if (patientPK)
        path += '&opened_patient_pk='+ patientPK;
        return api.get(path);
}

const ListProviderGroups = async (pageSize, page) => {
    let path = 'super-admin/provider-group/?page_size=' + pageSize + '&page=' + page
    return api.get(path);
}

const ListFacilityGroups = async (pageSize, page) => {
    let path = 'super-admin/facility-type/?page_size=' + pageSize + '&page=' + page;
    return api.get(path);
}

const ListFacilities = async (pageSize, page, practicePK) => {
    let path = 'user/facility/?list=true&page_size='+ pageSize +'&page='+ page +'&practice_pk='+ practicePK;
    return api.get(path);
}

const SaveNewPatient = async (data) =>{
    let path = "patient/patients-new/";
    return api.post(path,data);
}

const GetListPatientDropdown = async (search, type, patientPK) => {
    let path = 'patient/patients/?list_type=drop_down&page=0&practice_pk='+ getStorage('practice') +'&search='+ search;
    if (type)
        path += '&type='+ type;
    if (patientPK)
        path += '&patient_pk='+ patientPK;
        return api.get(path);
}

const GetOtherBillingInfoData = async (billingPK) => {
    let path = 'patient/other-billing-info/'+ billingPK;
    return api.get(path);
}

const UpdatePatient = async (patientPK, data) =>{
    let path = "patient/patients-update/"+ patientPK;
    return api.put(path,data);
}

const GetSelectedTabs = async (type, practicePK) => {
    let path = 'user/user-opened-tab/?type='+ type +'&practice_pk='+ practicePK;
    return api.get(path);
}

const AddRemoveSelectedTab = async (data) => {
    let path = 'user/user-opened-tab/';
    return api.post(path, data);
}

const CheckForAlert = async (data) => {
    let path = 'patient/check-for-alert/';
    return api.post(path, data);
}

const ListPracticeLocationsPatientFilter = async(pageSize, page) =>{
    let path = 'patient/patient-filter-locations/?practice_pk='+ getStorage('practice') +'&page_size='+ pageSize +'&page='+ page;
    return api.get(path);
}

const ListInsuranceCompaniesPatientFilter = async(pageSize, page) => {
    let path = 'patient/patient-filter-insurances/?practice_pk='+ getStorage('practice') +'&page_size='+ pageSize +'&page='+ page;
    return api.get(path); 
}

const ListPatientTypesPatientFilter = async (pageSize, page) => {
    let path = 'patient/patient-filter-patient-types/?page_size=' + pageSize + '&page=' + page + '&practice_pk=' + getStorage('practice');
    return api.get(path);
};

const ListReferringProvidersPatientFilter = async (pageSize, page) => {
    let path = "patient/patient-filter-referring-providers/?list=true&page_size=" + pageSize + "&page=" + page + '&practice_pk=' + getStorage('practice');
    return api.get(path);
};

const ListRenderingProvidersPatientFilter = async (pageSize, page) => {
    let path = "patient/patient-filter-providers/?list=true&page_size=" + pageSize + "&page=" + page + '&practice_pk=' + getStorage('practice') + '&list=true';
    return api.get(path);
};

const UpdatePatientActive = async(patientPK, data) => {
    let path = 'patient/patient-activate/'+patientPK;
    return api.put(path,data);
};

const SaveAutorizationDetails = async(data) => {
    let path = 'patient/authorization/';
    return api.post(path, data);
}

const ListAuthorizations = async(pageSize, page, insuranceInfoPK) => {
    let path = 'patient/authorization/?page_size='+ pageSize +'&page='+ page +'&insurance_info_pk='+ insuranceInfoPK;
    return api.get(path);
}

const GetPatientAuthorization = async(authorizedPK) => {
    let path = 'patient/authorization/'+ authorizedPK;
    return api.get(path);
}

const UpdatePatientAuthorization = async(authorizedPK, data) => {
    let path = 'patient/authorization/'+ authorizedPK;
    return api.put(path, data)
}

const DeletePatientAuthorization = async(authorizedPK) => {
    let path = 'patient/authorization/'+ authorizedPK;
    return api.destroy(path);
}

const RunEligibility = async(data) =>{
    let path = 'patient/run-eligibility/';
    return api.post(path, data);
}

const ExportPatientsList = async(query, exportType) => {
    let path = 'patient/patients/';
    if(query)
        path += query;
    let result = null;
    if(exportType === 'xls')
        result = api.getXLS(path);
    else
        result = api.get(path);
    return result;
}

const RunBatchEligibility = async(data) =>{
    let path = 'patient/run-batch-eligibility/';
    return api.post(path, data);
}

const RunImportedBatchEligibility = async(data) =>{
    let path = 'patient/run-imported-batch-eligibility/';
    return api.post(path, data);
}

const BatchEligibilityHistory = async (pageSize, page, batch_id, query, exportType) => {
    let path = 'patient/run-batch-eligibility/?page_size=' + pageSize + '&page=' + page + '&batch_id=' + batch_id;
    if(query)
        path += query;
    let result = '';
    if(exportType === 'xls')
        result = api.getXLS(path);
    else
        result = api.get(path);
    return result;
}

const GetBatchIds = async(practice_pk,is_import) =>{
    let path = 'patient/batch-ids/?practice_pk=' + practice_pk;
    if (is_import) {
        path +='&is_import='+true
    }
    else {
        path +='&is_import='+false 
    }
    return api.get(path);
}

const GetEligibilityList = async(practice_pk,pagesize,page) =>{
    let path = `patient/run-eligibility/?practice_pk=${practice_pk}&pagesize=${pagesize}&page=${page}`;
    return api.get(path);
}

const checkInsuranceEligibility = async(data) =>{
    let path = 'patient/check-insurance-eligibility/';
    return api.post(path,data);
}

const getInsuranceEligibility = async(practice_pk,insurance_company_id,patient_id) =>{
    let path = 'patient/check-insurance-eligibility/?practice_pk='+practice_pk+"&insurance_company_id="+insurance_company_id+"&patient_id="+patient_id;
    return api.get(path);
}

const ShowResponseHtml = async(id)=>{
    let path = 'patient/show-response-html/'+id
    return api.get(path);
}

const ShowBatchResponseHtml = async(id)=>{
    let path = 'patient/show-batch-response-html/'+id
    return api.get(path);
}

const ImportBatch = async(data)=>{
    let path = 'patient/import-batch/'
    return api.post(path,data);
}
const ImportPatientListCsv = async(dropDown_value,data)=>{
    let path = 'patient/import-patients-list/?type=' + dropDown_value 
    return api.post(path,data);
}

const GetImportBatchHistory = async(pageSize, page,batchId) =>{
    let path = 'patient/run-batch-eligibility/?page_size=' + pageSize + '&page=' + page + '&batch_id=' + batchId;
    return api.get(path);
}

const ImportCSVData = async(data) =>{
    let path = 'super-admin/import-patient/'
    return api.post(path,data);
}

const GetExtractedData = async (path) => {
    return api.get(path);
}

const UpdateInsuranceInfo = async(data)=>{
    let path = 'patient/update-insurance-info/';
    return api.post(path,data);
}

const GetPatientAgingSummaryDetails = async(patientPK) => {
    let path = 'patient/patient-aging-summary/?patient_pk='+ patientPK;
    return api.get(path);
}

const GetPatientPaymentSummaryDetails = async (patientPK, fromDate, endDate, query) => {
    let path = 'patient/patient-payment-summary/?patient_pk=' + patientPK + '&from_date=' + fromDate + '&to_date=' + endDate;
    if (query) {
        path += query;
    }
    return api.get(path);
};

const GetICD10List = async(pageSize, page, practicePK, type, search) =>{
    let path = "practice/icd10/?page_size=" + pageSize + "&page=" + page + "&practice_pk=" + practicePK + "&search=" +search;
    if(type){
        path += "&type="+type
    }
    return api.get(path);
}

const GetCPTList = async(pageSize, page, practicePK, type, search) =>{
    let path = "practice/cpt-codes/?page_size=" + pageSize + "&page=" + page + "&practice_pk=" + practicePK + "&search=" +search;
    if(type){
        path += "&type="+type
    }
    return api.get(path);
}

const PrintPatientStatement = async(patientPKs, printSelectedOption, practicePK) => {
    let path = "patient/print-patient-statement/?patient_pks="+patientPKs + '&count_statment='+printSelectedOption + '&practice_pk=' + practicePK;
    return api.get(path);
}

const sendPatientStatement = async (data) => {
    let path = "patient/send-patient-statement/";
    return api.post(path,data);
}

const GetBillByPRCode = async (pageSize, page, practicePK, code_prefix) => {
    let path = "practice/reason-code-by-prefix/?practice_pk=" + practicePK + "&prefix="+code_prefix;
    return api.get(path);
};

const PatientStatementPdf = async(patientPKs, practicePK) => {
    let path = "patient/patient-statement-pdf/?patient_pks=" + patientPKs + "&practice_pk=" + practicePK;
    return api.get(path);
}

const GetCPTCodeList = async (pageSize, page, practicePK, type, search, claimPK) => {
    let path = "practice/cpt-codes/?page_size=" + pageSize + "&page=" + page + "&practice_pk=" + practicePK + "&search=" + search;
    if (type) {
        path += "&type=" + type
    }
    if (claimPK)
        path += '&opened_claim_pk=' + claimPK;
    return api.get(path);

}

const MergePatient=async(data)=>{
    let path = "patient/merge-patient/";
    return api.post(path, data);
}

export default {
    ListPatients, ListPatientTypes, ListLanguages, ListRenderingProviders, ListServiceLocations,
    ListReferringProviders, ListReferringProviderGroups, ListRelationShips, getPatientData, getPatientInfoData,
    ListInsuranceInfos, ListPolicyTypes, ListInsuranceNames, SaveInsuranceDetails, GetInsuranceDetails, UpdateInsuranceDetails,
    GetFamilyMembers, DeleteFamilyMember, ListAccidentLocation, ListTypeOfAccident, ListTypeOfAssignmentBenefits,
    ListPatientsToAdd, AddToFamilyMember, SavePatientAlert, ListPatientAlert, GetPatientAlert, DeletePatientAlert, 
    UpdatePatientAlert, SavePatientNote, ListTypeOfNotes, ListPatientNotes, DeletePatientNotes, GetPatientNote,
    UpdatePatientNote, UpdateInsuranceCardImage, ListProviderGroups, ListFacilityGroups, ListPastCoverageInsuranceInfos,
    SaveNewPatient, GetListPatientDropdown, GetOtherBillingInfoData, UpdatePatient, GetSelectedTabs, AddRemoveSelectedTab,
    CheckForAlert, ListPracticeLocationsPatientFilter, ListInsuranceCompaniesPatientFilter, ListPatientTypesPatientFilter,
    ListReferringProvidersPatientFilter, ListRenderingProvidersPatientFilter, ListFacilities, UpdatePatientActive,
    SaveAutorizationDetails, ListAuthorizations, GetPatientAuthorization, UpdatePatientAuthorization, GetPatientDefaults,
    DeletePatientAuthorization, RunEligibility, ExportPatientsList, RunBatchEligibility, BatchEligibilityHistory,
    GetBatchIds, GetEligibilityList, checkInsuranceEligibility, getInsuranceEligibility, ShowResponseHtml, ShowBatchResponseHtml,   
    ImportBatch, RunImportedBatchEligibility, GetImportBatchHistory, ImportCSVData, GetExtractedData, UpdateInsuranceInfo,
    GetPatientAgingSummaryDetails, GetPatientPaymentSummaryDetails, GetICD10List, GetCPTList,
    PrintPatientStatement, sendPatientStatement, DeleteInsuranceCardImage, GetBillByPRCode, PatientStatementPdf, ImportPatientListCsv,
    GetCPTCodeList,ARStatusNoteList,ARActionNoteList,MergePatient
};
