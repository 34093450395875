import i18n from "../../../utilities/i18n";


const TableHeadData = [
    {
        "name": "checkbox",
        "type": "checkbox",
        "width": "check_box",
        "sort": false,
        "value": false,
    },
    {
        "name": i18n.t("claimsFilter.claimId"),
        "type": "number",
        "width": "small",
        "sort": true,
        "initialOrdering": true,
    },
    {
        "name": i18n.t("claimsFilter.warnings"),
        "type": "string",
        "width": "small",
        "sort": false,
    },
    {
        "name": i18n.t("claimsFilter.patientName"),
        "type": "string",
        "width": "medium",
        "sort": true,
        "initialOrdering": true,
    },
    {
        "name": i18n.t("claimsFilter.dos"),
        "type": "currency",
        "width": "small",
        "sort": true,
        "initialOrdering": true,
    },
    {
        "name": i18n.t("claimsFilter.charges"),
        "type": "currency",
        "width": "small",
        "sort": false,
    },
    {
        "name": i18n.t("claimsFilter.balance"),
        "type": "currency",
        "width": "small",
        "sort": false,
    },
    {
        "name": i18n.t("claimsFilter.responsible"),
        "type": "Date",
        "width": "medium",
        "sort": false,
    },
    {
        "name": i18n.t("claimsFilter.claimStatus"),
        "type": "string",
        "width": "medium",
        "sort": false,
    },
];

export const TableBodyData = [
    [
        {
            "id": "",
            "name": "checkbox",
            "type": "checkbox",
            "width": "check_box",
            "align": "center",
            "value": false
        },
        {
            "id": "",
            "name": "custom_claim_id",
            "value": "",
            "type": "string",
            "needLink": true,
            "align": "center",
            "width": "small",
        },
        {
            "id": "",
            "name": "is_warnings",
            "value": [],
            "type": "warning",
            "align": "center",
            "width": "small",
            "status": false,
        },
        {
            "id": "",
            "name": "patient_name",
            "value": "",
            "type": "string",
            "align": "left",
            "width": "small",
            "needLink": true,
        },
        {
            "id": "",
            "name": "dos_from",
            "value": "",
            "type": "Date",
            "align": "center",
            "width": "small",
        },
        {
            "id": "",
            "name": "charges",
            "value": "",
            "type": "currency",
            "align": "right",
            "width": "small",
        },
        {
            "id": "",
            "name": "balance",
            "value": "",
            "type": "currency",
            "align": "right",
            "width": "small",
        },
        {
            "id": "",
            "name": "payer_name",
            "value": "",
            "type": "responsiblePayer",
            "align": "left",
            "width": "medium",
        }, 
        {
            "id": "",
            "name": "payer_types",
            "value": "",
            "type": "payer_types",
            "align": "left",
            "width": "medium",
        },
        {
            "id": "",
            "name": "claim_status_name",
            "value": "",
            "type": "string",
            "align": "left",
            "width": "medium",
        },
    ]
]

export const ClaimsFilterTableData = {
    "name": "simpleTable",
    "isResize": true,
    "isFixedHead": true,
    "tableHeadings": TableHeadData,
    "tableBodyData": TableBodyData
}