import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import MergeTypeIcon from '@material-ui/icons/MergeType';
// import UndoIcon from '@material-ui/icons/Undo';
import CommonButton from './Buttons';
const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
        width: 'auto !important',
        maxWidth: '200px !important',
        minWidth: '150px !important',
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles(() => ({
    root: {
        justifyContent:'center',
    },
}))(MenuItem);


const StyledButton = withStyles(() => ({
    root: {
        backgroundColor:'#1479BB !important',
        color:'#fff',
        textTransform:'none',
        width:'150px'
    },
}))(Button);

const MergeButton = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);


    /**
 * function to open merge button menu item such as merge and undo merge
 */
    const handleMergeButtonClick = (event) => {
        setAnchorEl(event.currentTarget);
    }


    const handleClose = () => {
        setAnchorEl(null);
    }


    const showPatientMergeModal=()=>{
        props.showPatientMergeTable();
        handleClose();
    }

    return(
    <div className="padding-right10" style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <CommonButton
            icon="merge"
            label="Merge"
            variant="contained"
            onClick={(e) => handleMergeButtonClick(e)}
        />
        <StyledMenu
            id="customized-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={()=>handleClose()}
        >
            <StyledMenuItem>
                <StyledButton
                onClick={()=>showPatientMergeModal()}
                startIcon={<MergeTypeIcon/>}
                >
                    Merge
                </StyledButton>
            </StyledMenuItem>
            {/**Commenting since it's not needed for the time being */}
            {/* <StyledMenuItem>
            <StyledButton
                startIcon={<UndoIcon/>}
                >
                    Undo Merge
                </StyledButton>
            </StyledMenuItem> */}
        </StyledMenu>
    </div>
    )
}

export default MergeButton;