import i18n from "../../../utilities/i18n";

const commonWidths = {
    extraSmall: 'extra-small',
    small: 'small',
    medium: 'medium',
    large: 'large'
};

const tableData = [
    {
        "head": {
            name: i18n.t("reports.claim_id"),
            type: "string",
            width: commonWidths.small,
            sort: true,
            hideValue: false,
        },
        "body": {
            name: "custom_claim_id",
            type: "string",
            align: "center",
            width: commonWidths.small,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("reports.accession_number"),
            type: "string",
            width: commonWidths.extraSmall,
            sort: false,
            hideValue: false,
        },
        "body": {
            name: "accession_number",
            type: "string",
            align: "center",
            width: commonWidths.extraSmall,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("reports.visit_id"),
            type: "string",
            width: commonWidths.extraSmall,
            sort: false,
            hideValue: false,
        },
        "body": {
            name: "claim_reference_number",
            type: "string",
            align: "center",
            width: commonWidths.extraSmall,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("reports.firstName"),
            type: "string",
            width: commonWidths.small,
            sort: false,
            hideValue: false,
        },
        "body": {
            name: "patient_first_name",
            type: "string",
            align: "center",
            width: commonWidths.small,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("reports.lastName"),
            type: "string",
            width: commonWidths.small,
            sort: false,
            hideValue: false,
        },
        "body": {
            name: "patient_last_name",
            type: "string",
            align: "center",
            width: commonWidths.small,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("reports.middleName"),
            type: "string",
            width: commonWidths.small,
            sort: false,
            hideValue: false,
        },
        "body": {
            name: "patient_middle_name",
            type: "string",
            align: "center",
            width: commonWidths.small,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("reports.DOB"),
            type: "date",
            width: commonWidths.small,
            sort: false,
            hideValue: false,
        },
        "body": {
            name: "patient_date_of_birth",
            type: "string",
            needDateFormat: "yes",
            align: "center",
            width: commonWidths.small,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("reports.patient_id"),
            type: "string",
            width: commonWidths.extraSmall,
            sort: false,
            hideValue: false,
        },
        "body": {
            name: "patient_account_number",
            type: "string",
            align: "center",
            width: commonWidths.extraSmall,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("reports.patient_state"),
            type: "date",
            width: commonWidths.extraSmall,
            sort: false,
            hideValue: false,
        },
        "body": {
            name: "patient_state",
            type: "string",
            align: "center",
            width: commonWidths.extraSmall,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("reports.primary_payer_name"),
            type: "string",
            width: commonWidths.medium,
            sort: false,
            hideValue: false,
        },
        "body": {
            name: "primary_payer_name",
            type: "string",
            align: "center",
            width: commonWidths.medium,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("reports.primary_payer_id"),
            type: "string",
            width: commonWidths.small,
            sort: false,
            hideValue: false,
        },
        "body": {
            name: "primary_payer_id",
            type: "string",
            align: "center",
            width: commonWidths.small,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("reports.primary_policy_id"),
            type: "string",
            width: commonWidths.medium,
            sort: false,
            hideValue: false,
        },
        "body": {
            name: "primary_policy_id",
            type: "string",
            align: "center",
            width: commonWidths.medium,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("reports.primary_group_no"),
            type: "string",
            width: commonWidths.medium,
            sort: false,
            hideValue: false,
        },
        "body": {
            name: "primary_group_number",
            type: "string",
            align: "center",
            width: commonWidths.medium,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("reports.secondary_payer_name"),
            type: "string",
            width: commonWidths.medium,
            sort: false,
            hideValue: false,
        },
        "body": {
            name: "secondary_payer_name",
            type: "string",
            align: "center",
            width: commonWidths.medium,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("reports.secondary_payer_id"),
            type: "string",
            width: commonWidths.small,
            sort: false,
            hideValue: false,
        },
        "body": {
            name: "secondary_payer_id",
            type: "string",
            align: "center",
            width: commonWidths.small,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("reports.secondary_policy_id"),
            type: "string",
            width: commonWidths.medium,
            sort: false,
            hideValue: false,
        },
        "body": {
            name: "secondary_policy_id",
            type: "string",
            align: "center",
            width: commonWidths.medium,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("reports.secondary_group_no"),
            type: "string",
            width: commonWidths.medium,
            sort: false,
            hideValue: false,
        },
        "body": {
            name: "secondary_group_number",
            type: "string",
            align: "center",
            width: commonWidths.medium,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("reports.refferingProvider"),
            type: "string",
            width: commonWidths.medium,
            sort: false,
            hideValue: false,
        },
        "body": {
            name: "referring_provider_name",
            type: "string",
            align: "center",
            width: commonWidths.medium,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("reports.refferingProviderNPI"),
            type: "string",
            width: commonWidths.extraSmall,
            sort: false,
            hideValue: false,
        },
        "body": {
            name: "referring_provider_npi",
            type: "string",
            align: "center",
            width: commonWidths.extraSmall,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("reports.renderingProvider"),
            type: "string",
            width: commonWidths.medium,
            sort: false,
            hideValue: false,
        },
        "body": {
            name: "rendering_provider_name",
            type: "string",
            align: "center",
            width: commonWidths.medium,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("reports.renderingProviderNPI"),
            type: "string",
            width: commonWidths.extraSmall,
            sort: false,
            hideValue: false,
        },
        "body": {
            name: "rendering_provider_npi",
            type: "string",
            align: "center",
            width: commonWidths.extraSmall,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("reports.billingProvider"),
            type: "string",
            width: commonWidths.medium,
            sort: false,
            hideValue: false,
        },
        "body": {
            name: "billing_provider_name",
            type: "string",
            align: "center",
            width: commonWidths.medium,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("reports.billingProviderNPI"),
            type: "string",
            width: commonWidths.extraSmall,
            sort: false,
            hideValue: false,
        },
        "body": {
            name: "billing_provider_npi",
            type: "string",
            align: "center",
            width: commonWidths.extraSmall,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("reports.serviceLocation"),
            type: "string",
            width: commonWidths.medium,
            sort: false,
            hideValue: false,
        },
        "body": {
            name: "service_location",
            type: "string",
            align: "center",
            width: commonWidths.medium,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("claimsFilter.claimEntered"),
            type: "string",
            width: commonWidths.small,
            sort: false,
            hideValue: false,
        },
        "body": {
            name: "claim_entered_date",
            type: "string",
            needDateFormat: "yes",
            align: "center",
            width: commonWidths.small,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("reports.dateOfServiceFrom"),
            type: "date",
            width: commonWidths.small,
            sort: false,
            hideValue: false,
        },
        "body": {
            name: "service_from_date",
            type: "string",
            needDateFormat: "yes",
            align: "center",
            width: commonWidths.small,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("reports.dateOfServiceTo"),
            type: "date",
            width: commonWidths.small,
            sort: false,
            hideValue: false,
        },
        "body": {
            name: "service_to_date",
            type: "string",
            needDateFormat: "yes",
            align: "center",
            width: commonWidths.small,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("reports.firstBilledDate"),
            type: "date",
            width: commonWidths.small,
            sort: false,
            hideValue: false,
        },
        "body": {
            name: "first_billed_date",
            type: "string",
            needDateFormat: "yes",
            align: "center",
            width: commonWidths.small,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("reports.lastBilledDate"),
            type: "date",
            width: commonWidths.small,
            sort: false,
            hideValue: false,
        },
        "body": {
            name: "last_billed_date",
            type: "string",
            needDateFormat: "yes",
            align: "center",
            width: commonWidths.small,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("reports.filters.transaction_type"),
            type: "string",
            width: commonWidths.small,
            sort: false,
            hideValue: false,
        },
        "body": {
            name: "transaction_type",
            type: "string",
            align: "center",
            width: commonWidths.small,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("reports.cptcode"),
            type: "string",
            width: commonWidths.small,
            sort: false,
            hideValue: false,
        },
        "body": {
            name: "cpt_name",
            type: "string",
            align: "center",
            width: commonWidths.small,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("reports.units"),
            type: "string",
            width: commonWidths.extraSmall,
            sort: false,
            hideValue: false,
        },
        "body": {
            name: "fee_units",
            type: "string",
            align: "center",
            width: commonWidths.extraSmall,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("reports.cpt_charge_amount"),
            type: "currency",
            width: commonWidths.small,
            sort: false,
            hideValue: false,
        },
        "body": {
            name: "cpt_charge",
            type: "currency",
            align: "center",
            width: commonWidths.small,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("arClaims.payments"),
            type: "currency",
            width: commonWidths.extraSmall,
            sort: false,
            hideValue: false,
        },
        "body": {
            name: "cpt_payment",
            type: "currency",
            align: "center",
            width: commonWidths.extraSmall,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("arClaims.adjustments"),
            type: "currency",
            width: commonWidths.extraSmall,
            sort: false,
            hideValue: false,
        },
        "body": {
            name: "cpt_adjustment",
            type: "currency",
            align: "center",
            width: commonWidths.extraSmall,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("reports.insuranceBalance"),
            type: "currency",
            width: commonWidths.extraSmall,
            sort: false,
            hideValue: false,
        },
        "body": {
            name: "cpt_insurance_balance",
            type: "currency",
            align: "center",
            width: commonWidths.extraSmall,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("arClaims.patbalance"),
            type: "currency",
            width: commonWidths.extraSmall,
            sort: false,
            hideValue: false,
        },
        "body": {
            name: "cpt_patient_balance",
            type: "currency",
            align: "center",
            width: commonWidths.extraSmall,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("reports.totalBalance"),
            type: "currency",
            width: commonWidths.extraSmall,
            sort: false,
            hideValue: false,
        },
        "body": {
            name: "cpt_balance",
            type: "currency",
            align: "center",
            width: commonWidths.extraSmall,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("reports.adjustment_code"),
            type: "string",
            width: commonWidths.small,
            sort: false,
            hideValue: false,
        },
        "body": {
            name: "payment_reason_code",
            type: "string",
            align: "center",
            width: commonWidths.small,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("commons.description"),
            type: "string",
            width: commonWidths.large,
            sort: false,
            hideValue: false,
        },
        "body": {
            name: "payment_reason_description",
            type: "string",
            align: "center",
            width: commonWidths.large,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("reports.payment_entered_date"),
            type: "date",
            width: commonWidths.small,
            sort: false,
            hideValue: false,
        },
        "body": {
            name: "payment_entered_date",
            type: "string",
            needDateFormat: "yes",
            align: "center",
            width: commonWidths.small,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("reports.paid_payer_name"),
            type: "string",
            width: commonWidths.medium,
            sort: false,
            hideValue: false,
        },
        "body": {
            name: "paid_payer_name",
            type: "string",
            align: "center",
            width: commonWidths.medium,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("reports.paid_payer_id"),
            type: "string",
            width: commonWidths.small,
            sort: false,
            hideValue: false,
        },
        "body": {
            name: "paid_payer_id",
            type: "string",
            align: "center",
            width: commonWidths.small,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("reports.paid_policy_id"),
            type: "string",
            width: commonWidths.medium,
            sort: false,
            hideValue: false,
        },
        "body": {
            name: "paid_policy_id",
            type: "string",
            align: "center",
            width: commonWidths.medium,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("reports.paid_group_no"),
            type: "string",
            width: commonWidths.medium,
            sort: false,
            hideValue: false,
        },
        "body": {
            name: "paid_group_number",
            type: "string",
            align: "center",
            width: commonWidths.medium,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("reports.filters.paymentCheckDate"),
            type: "date",
            width: commonWidths.small,
            sort: false,
            hideValue: false,
        },
        "body": {
            name: "check_date",
            type: "string",
            needDateFormat: "yes",
            align: "center",
            width: commonWidths.small,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("reports.serviceYearMonth"),
            type: "string",
            width: commonWidths.small,
            sort: false,
            hideValue: false,
        },
        "body": {
            name: "service_month_and_year",
            type: "string",
            align: "center",
            width: commonWidths.small,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("arClaims.responsibleType"),
            type: "string",
            width: commonWidths.small,
            sort: false,
            hideValue: false,
        },
        "body": {
            name: "responsibility",
            type: "string",
            align: "center",
            width: commonWidths.small,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("reports.claimStatus"),
            type: "string",
            width: commonWidths.medium,
            sort: false,
            hideValue: false,
        },
        "body": {
            name: "claim_status",
            type: "string",
            align: "center",
            width: commonWidths.medium,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("reports.claimSubStatus"),
            type: "string",
            width: commonWidths.medium,
            sort: false,
            hideValue: false,
        },
        "body": {
            name: "claim_sub_status",
            type: "string",
            align: "center",
            width: commonWidths.medium,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("reports.lab_sales_rep"),
            type: "string",
            width: commonWidths.medium,
            sort: false,
            hideValue: false,
        },
        "body": {
            name: "lab_sales_rep",
            type: "string",
            align: "center",
            width: commonWidths.medium,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("reports.clinic_site_name"),
            type: "string",
            width: commonWidths.medium,
            sort: false,
            hideValue: false,
        },
        "body": {
            name: "clinic_site_name",
            type: "string",
            align: "center",
            width: commonWidths.medium,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("reports.claim_service_type"),
            type: "string",
            width: commonWidths.medium,
            sort: false,
            hideValue: false,
        },
        "body": {
            name: "claim_service_type",
            type: "string",
            align: "center",
            width: commonWidths.medium,
            hideValue: false,
        },
    },
];

export const ReportTableData = {
    name: "denial-report-table",
    isResize: false,
    isFixedHead: true,
    tableHeadings: tableData.map(item => item.head),
    tableBodyData: [tableData.map(item => item.body)],
};