import { PAGING_END_INDEX } from "../../../utilities/staticConfigs";

export const initialState = {
    searchQuery: {
        dateOfServiceFrom: "",
        dateOfServiceTo: "",
        claimEnteredFrom: "",
        claimEnteredTo: "",
        claimSubmittedFrom: "",
        claimSubmittedTo: "",
        claimStatus: [],
        serviceLocation: [],
        serviceLocationType: [],
        claimType: 1,
        provider: [],
        selectServiceLocation: [],
        currentPayer: [],
        primaryPayer: [],
        secondaryPayer: [],
        payerType: [],
        selectedCptIDs: [],
        revenueCodes: [],
        icd: [],
        facility: [],
        claimAmountTotalsBetween: "",
        claimAmountTotals: "",
        claimAmountTotalsType: "",
        cpt: "",
        claimPrintType: "",
        errors:{}
    },
    selectedEntries: [],
    hasCheckedAll: false,
    dropDownListData: {
        isLoading: false,
        isErrorGettingDropdownData: false,
        isDropDownDataReceived: false,
        revenueCodeList: [],
        ICD: [],
        cptCodeList: [],
        PAYER_TYPE_LIST: [],
        payersList: [],
        ServiceLocationList: [],
        providerList: [],
        claimStatusList: []
    },

    sorting: {
        initialOrderingPatientName: true,
        initialOrderingClaimId: true,
        initialOrderingDos: true,
    },
    startIndex: 0,
    endIndex: PAGING_END_INDEX,
    activePage: 1,
    claim_order_type: "-",
    orderType: "",
    searched: false,
    reseted: false,
    patientSelectedData: [],
    patientAdvanceData: [],
    patient_PK: '',
    previousQuery: "",
    searchButtonClicked: false,
    loading: false,
    tableData: [],
    count: "",
    pageSize:"",
    checkboxResetflag: false
}