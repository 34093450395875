import SelectInput from '../../commons/input/SelectInput'
import React, { useContext, useEffect, useState } from 'react'
import { Redirect, Link, useHistory } from "react-router-dom";
import { Form } from 'react-bootstrap'
import PhoneInput from "../../commons/PhoneInput/PhoneInput"
import i18n from '../../../utilities/i18n'
import GeoCodeSearch from '../../commons/helpers/geoCodeApiSearch'
import TextInput from '../../commons/input/input'
import EmailInput from '../../commons/EmailInput/EmailInput'
import LoadingContext from '../../../container/loadingContext'
import service from './service'
import { DEFAULT_COUNTRY, DEFAULT_PAGING_SIZE, ROUTE_FACILITIES } from '../../../utilities/staticConfigs'
import { PROVIDER_TYPE_ORGANIZATION } from "../../../utilities/dictionaryConstants";
import { ValidateEmail, checkPermission } from '../../../utilities/commonUtilities'
import { getStorage } from '../../../utilities/browserStorage'
import Notify from '../../commons/notify'
import { ADD_ERROR, ADD_SUCCESS, UPDATE_SUCCESS } from '../../../utilities/labelConfigs'
import CustomizedDialogs from '../../modalWindowComponent/CustomizedDialogs';
import NPISearch from '../../commons/NPISearch/NPISearch';
import { CityInput } from '../../commons/input/CityInput';
import Multiselect from '../../../multiselect/src/index'; // NEW COMPONENT FOR SEARCHABLE SINGLE/MULTI SELECT https://github.com/rowyio/multiselect
import { US_STATES } from '../../../utilities/usStates';
import { permission_key_values_accountsetup } from '../../../utilities/permissions';


export const FacilityAdd = (props) => {
    const practicePK = getStorage("practice");
    const [editForm, setEditForm] = useState(false);
    const [showNPIModalWindow, setShowNPIModalWindow] = useState(false);
    const history = useHistory();

    const [facilityName, setFacilityName] = useState('');
    const [facilityType, setFacilityType] = useState('');
    const [npi, setNPI] = useState('');
    const [pos, setPOS] = useState('');
    const [clia, setCLIA] = useState('');
    const [street1, setStreet1] = useState('');
    const [street2, setStreet2] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zip, setZIP] = useState('');
    const [country, setCountry] = useState(DEFAULT_COUNTRY);
    const [phone, setPhone] = useState('');
    const [fax, setFax] = useState('');
    const [email, setEmail] = useState('');
    const [inactive, setInactive] = useState(false);
    const [inactiveFacilityModal, setInactiveFacilityModal] = useState(false);
    const [upin, setUpin] = useState('');
    const [taxonomy, setTaxonomy] = useState('');
    const [medicareID, setMedicareID] = useState('');
    const [medicaidID, setMedicaidID] = useState('');
    const [stateLicense, setStateLicense] = useState('');
    const [bcbsProviderID, setBcbsProviderID] = useState('');
    const [tricareProviderID, setTricareProviderID] = useState('');
    const [uhcProviderID, setUhcProviderID] = useState('');
    const [commercialProviderID, setCommercialProviderID] = useState('');
    const [stateList, setStateList] = useState([]);
    const [gotoList, setGotoList] = useState(false);
    const [POSCodeList, setPOSCodeList] = useState([]);
    const [facilityGroupList, setFacilityGroupList] = useState([]);
    const [facilityID, setFacilityID] = useState('');
    const [editFacilityID, setEditFacilityID] = useState('');

    const setShowLoadingBar = useContext(LoadingContext);
    const [form_error, setFormError] = useState({
        'facilityName': '', 'facilityType': '', 'npi': '',
        'street1': '', 'city': '', 'state': '', 'zip': ''
    });
    const [mSelTaxonomyList, setMSelTaxonomyList] = useState([]);

    const [showNotify, setShowNotify] = useState('hide');
    const [notifyDescription, setNotifyDescription] = useState('');
    const [notifyType, setNotifyType] = useState('success');

    function showNotifyWindow(action, type, desc, age = 3000) {
        if (action === 'show') {
            setTimeout(() => {
                setShowNotify('hide');
            }, age)
        }
        setShowNotify(action);
        setNotifyType(type);
        setNotifyDescription(desc);
    }
    
    function onHandleChange(e) {
        if (e.target) {
            const name = e.target.name;
            let value = e.target.value;
            let trimmedValue = '';
            let inputFields = ['facilityName', 'facilityType', 'npi', 'pos',
                'clia', 'street1', 'street2', 'city', 'state',
                'country', 'phone', 'zip', 'fax', 'email', 'upin', 'taxonomy',
                'medicareID', 'medicaidID', 'stateLicense', 'bcbsProviderID',
                'tricareProviderID', 'uhcProviderID', 'commercialProviderID'
            ]
            let mandatoryFields = ['facilityName', 'facilityType', 'npi',
                'street1', 'city', 'state', 'zip'
            ]

            const validNames = [
                'facilityName', 'clia','city','upin','medicaidID',
                'medicareID','bcbsProviderID', 'tricareProviderID',
                'uhcProviderID','commercialProviderID'
            ];
    
            if (validNames.includes(name)) {
                value = value.replace(/[^A-Za-z0-9.\s]/g, '');
            }
            
            if (inputFields.includes(name)) {
                trimmedValue = value
            }

            const isValueEmpty = !(('' + trimmedValue).trim());
            const isMandatoryField = mandatoryFields.includes(name);

            if (isValueEmpty && isMandatoryField) {
                setFormError({
                    ...form_error,
                    [name]: 'error'
                });
            } else {
                setFormError({
                    ...form_error,
                    [name]: ''
                });
            }

            switch (name) {
                case 'facilityName':
                    setFacilityName(trimmedValue);
                    if (!trimmedValue) {
                        setNPI('');
                    }
                    break;
                case 'facilityType':
                    setFacilityType(trimmedValue);
                    break;
                case 'npi':
                    setNPI(trimmedValue);
                    break;
                case 'pos':
                    setPOS(trimmedValue);
                    break;
                case 'clia':
                    setCLIA(trimmedValue);
                    break;
                case 'street1':
                    setStreet1(trimmedValue);
                    break;
                case 'street2':
                    setStreet2(trimmedValue);
                    break;
                case 'city':
                    setCity(trimmedValue);
                    break;
                case 'state':
                    setState(trimmedValue);
                    break;
                case 'zip':
                    setZIP(trimmedValue);
                    break;
                case 'country':
                    setCountry(trimmedValue);
                    break;
                case 'phone':
                    setPhone(trimmedValue);
                    break;
                case 'fax':
                    setFax(trimmedValue);
                    break;
                case 'email':
                    setEmail(trimmedValue);
                    break;
                case 'upin':
                    setUpin(trimmedValue);
                    break;
                case 'taxonomy':
                    setTaxonomy(trimmedValue);
                    break;
                case 'medicareID':
                    setMedicareID(trimmedValue);
                    break;
                case 'medicaidID':
                    setMedicaidID(trimmedValue);
                    break;
                case 'stateLicense':
                    setStateLicense(trimmedValue);
                    break;
                case 'bcbsProviderID':
                    setBcbsProviderID(trimmedValue);
                    break;
                case 'tricareProviderID':
                    setTricareProviderID(trimmedValue);
                    break;
                case 'uhcProviderID':
                    setUhcProviderID(trimmedValue);
                    break;
                case 'commercialProviderID':
                    setCommercialProviderID(trimmedValue);
                    break;
                default: //Add default if necessary
                    break;
            }
        }
    }

    const handleMultiselect = (value) => {
        if (value) {
            setTaxonomy(value);
        }
        else {
            setTaxonomy('');
        }
    };
    function onInactiveFacility(e) {
        e.preventDefault();
        setInactiveFacilityModal(true);
    }

    function getFacilityGroupData(pageSize) {
        setShowLoadingBar(true);
        let pageNumber = 0
        const result = service.ListFacilityGroups(pageSize, pageNumber);
        result.then(response => {
            setShowLoadingBar(false);
            setFacilityGroupList(response.data);
        });
    }

    function getPOSCodesData(pageSize) {
        setShowLoadingBar(true);
        let pageNumber = 0
        const result = service.ListPOSCodes(pageSize, pageNumber);
        result.then(response => {
            if (response.data !== undefined) {
                let data = response.data;
                data.forEach((item) => {
                    item.description = item.name + ' - ' + item.service_place_name
                })
                setPOSCodeList(data)
            }
            setShowLoadingBar(false);
        });
    }

    const getTaxonomy = (pageSize) => {
        let pageNumber = 0;
        const result = service.ListTaxonomies(pageSize, pageNumber);
        result.then(response => {
            let tmpObjArr = [];
            if (Array.isArray(response.data) && response.data.length) {
                response.data.forEach((item) => {
                    tmpObjArr.push({ "value": item.id, "label": item.full_name,"name": item.name });
                })
                setMSelTaxonomyList(tmpObjArr);
            }
            else {
                setMSelTaxonomyList(tmpObjArr);
            }
        });
    }

    useEffect(() => {
        getTaxonomy(20, 0);
    }, []);

    function getStatesData(pageSize) {
        setShowLoadingBar(true);
        let pageNumber = 0
        const result = service.ListStates(pageSize, pageNumber);
        result.then(response => {
            setShowLoadingBar(false);
            setStateList(response.data);
        });
    }

    function setSelectedNPI(npiData) {
        const {
            NPCountry,
            NPIAddress1,
            NPIAddress2,
            NPICity,
            NPIEmail,
            NPINum,
            NPIOrgName,
            NPIPhone,
            NPIPostalCode,
            NPIState,
            NPICode
        } = npiData;

        if( NPICode && NPICode.length > 0) {
            let filteredNPI = NPICode.filter(d => d.code && d.primary == true)[0]?.code;
            /**sets the value of taxonomy automatically by filtering with the taxonomy list */
            const matchedTaxonomies = mSelTaxonomyList.find((item) => item.name == filteredNPI)?.value
            if (matchedTaxonomies) {
                setTaxonomy(matchedTaxonomies)
            } else if (mSelTaxonomyList.length > 0) {
                setTaxonomy(mSelTaxonomyList[0].value)
            }
        }
        if (NPINum && NPINum.length > 0) setNPI(NPINum);
        if (NPIOrgName && NPIOrgName.length > 0) setFacilityName(NPIOrgName);
        if (NPIAddress1 && NPIAddress1.length > 0) setStreet1(NPIAddress1);
        if (NPIAddress2 && NPIAddress2.length > 0) setStreet2(NPIAddress2);
        if (NPICity && NPICity.length > 0) setCity(NPICity);
        if (NPIState && NPIState.length > 0) setState(NPIState);
        if (NPCountry && NPCountry.length > 0) setCountry(NPCountry);
        if (NPIPostalCode && NPIPostalCode.length > 0) setZIP(NPIPostalCode);
        if (NPIPhone && NPIPhone.length > 0) setPhone(NPIPhone);
        if (NPIEmail && NPIEmail.length > 0) setEmail(NPIEmail);

        setShowNPIModalWindow(false);
    }


    useEffect(() => {
        getFacilityGroupData(DEFAULT_PAGING_SIZE);
        getPOSCodesData(DEFAULT_PAGING_SIZE);
        getStatesData(DEFAULT_PAGING_SIZE);

        let getFacilityId = '';

        if (props.location.state && props.location.state.selectedID && props.location.state.selectedID !== 0) {
            getFacilityId = props.location.state.selectedID;
        }
        else if (history.location.state) {
            getFacilityId = history.location.state.facilityID;
        }

        if (getFacilityId) {
            setShowLoadingBar(true);
            const data = service.GetFacility(getFacilityId);
            data.then(response => {
                setShowLoadingBar(false);
                setInactiveFacilityModal(false);
                setFacilityName(response.data.name);
                setFacilityID(response.data.custom_facility_id);
                setFacilityType(response.data.facility_type);
                setNPI(response.data?.npi);
                setCLIA(response.data.clia);
                if (response.data.location_data) {
                    setStreet1(response.data.location_data.street1);
                    setStreet2(response.data.location_data.street2);
                    setCity(response.data.location_data.city);
                    setState(response.data.location_data.state);
                    setZIP(response.data.location_data.zip_code);
                    setCountry(response.data.location_data.country);
                }
                setPhone(response.data.phone);
                setFax(response.data.fax);
                setEmail(response.data.email);
                setUpin(response.data.upin);
                setTaxonomy(response.data.taxonomy);
                setMedicareID(response.data.medicare_id);
                setMedicaidID(response.data.medicaid_id);
                setStateLicense(response.data.state_license);
                setBcbsProviderID(response.data.bcbs_provider_id);
                setTricareProviderID(response.data.tricare_provider_id);
                setUhcProviderID(response.data.uhc_provider_id);
                setCommercialProviderID(response.data.commercial_provider_id);
                setEditForm(true);
                setEditFacilityID(getFacilityId);
                setInactive(!response.data.active);
                setPOS(response.data.pos)
            });
        }
    }, []);

    //validation function
    function validateForm() {
        const hasError = !facilityName || !facilityType || !npi || !street1 || !zip || !city || !state || !taxonomy;
        const emailValidation = email?.length === 0 || ValidateEmail(email);
        const isValid = !hasError && emailValidation;
        return isValid;
    }

    function formatData() {
        const data = {
            practice: practicePK,
            name: facilityName,
            facility_type: facilityType,
            npi: npi,
            pos: pos,
            clia: clia,
            location: {
                street1: street1,
                street2: street2,
                city: city,
                state: state,
                zip_code: zip,
                country: country
            },
            phone: phone,
            fax: fax,
            email: email,
            upin: upin,
            taxonomy: taxonomy,
            medicare_id: medicareID,
            medicaid_id: medicaidID,
            state_license: stateLicense,
            bcbs_provider_id: bcbsProviderID,
            tricare_provider_id: tricareProviderID,
            uhc_provider_id: uhcProviderID,
            commercial_provider_id: commercialProviderID,
            inactive: inactive
        };
        return data;
    }

    function onSaveFormData(e) {
        e.preventDefault();
        const isValid = validateForm();

        if (isValid) {
            setShowLoadingBar(true);

            const data = formatData();

            const result = editForm ? service.UpdateFacility(editFacilityID, data) : service.AddFacility(data);

            result.then(response => {
                //used ternary instead of if else
                if (response.status === 201 || response.status === 200) {
                    showNotifyWindow('show', 'success', response.status === 201 ? ADD_SUCCESS : UPDATE_SUCCESS);

                    setInactiveFacilityModal(false);

                    setTimeout(() => {
                        setShowLoadingBar(false);
                        setGotoList(true);
                    }, 1000);
                } else if (response.status === 400 && response.data.record_already_exists !== undefined) {
                    showNotifyWindow('show', 'error', i18n.t('errorMessages.record_exists'));
                    setTimeout(() => {
                        setShowLoadingBar(false);
                        setGotoList(true);
                    }, 1000);
                } else if (response.status === 400 && response.data.message == "facility_npi_exists") {
                    showNotifyWindow('show', 'error', i18n.t('errorMessages.npi_record_exists'));
                    setTimeout(() => {
                        setShowLoadingBar(false);
                    }, 1000);
                } else {
                    showNotifyWindow('show', 'error', ADD_ERROR);

                    setTimeout(() => {
                        setShowLoadingBar(false);
                        setGotoList(true);
                    }, 1000);
                }
            });
        }
    }

    function showSearchNPI() {
        setShowNPIModalWindow(true);
    }

    function onHideNPI() {
        setShowNPIModalWindow(false);
    }


    function changeFacilityActive(e) {
        if (e.target.checked) setInactive(true);
        else setInactive(false);
    }

    if (gotoList) {
        return <Redirect to={{ pathname: ROUTE_FACILITIES }} />;
    }

    function backToRefering() {
        history.push(ROUTE_FACILITIES);
    }

    return (
        <div className="col-md-12">
            <div className="box pb-5 box-content-white padding-bottom20 margin-right15">
                <Notify showNotify={showNotify} setShowNotify={setShowNotify} notifyDescription={notifyDescription} setNotifyType={setNotifyType} setNotifyDescription={setNotifyDescription} notifyType={notifyType} />
                <div className="box-content-white padding-bottom20">
                    <Form id="form_dataEntry" autoComplete="off" onSubmit={inactive ? (e) => onInactiveFacility(e) : (e) => onSaveFormData(e)} encType="multipart/form-data">
                        <Form.Group>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group ">
                                        <TextInput type="text" id="facilityName" name="facilityName" label={i18n.t('userPages.facilities.labelFacilityName')} onValueChange={onHandleChange} required={true} value={facilityName} />
                                    </div>
                                </div>
                                {facilityID &&
                                    <div className="col-md-2">
                                        <div className="form-group ">
                                            <TextInput type="text" id="facilityID" name="facilityID" label={i18n.t('userPages.facilities.labelFacilityID')} onValueChange={onHandleChange} disabled={true} className={'read-only'} value={facilityID} />
                                        </div>
                                    </div>
                                }
                                <div className="col-md-2">
                                    <div className="">
                                        <SelectInput data={facilityGroupList} label={i18n.t('userPages.facilities.labelFacilityType')} name="facilityType" id="facilityType" value={facilityType} setValue={setFacilityType} required={true} onValueChange={selected => onHandleChange(selected)} />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-2">
                                        <div className="div-float-right searchNpi" style={{ position: "absolute", right: "4px" }} onClick={showSearchNPI} >
                                            <Link to="#">{i18n.t("userPages.practices.labelSearchNPI")}</Link>
                                        </div>
                                    {facilityName.trim().length === 0 &&
                                        <div className="div-float-right disabledLabel" style={{ position: "absolute", right: "4px" }}>
                                            <Link to="#" className="disabledLabel" disabled={true} >{i18n.t("userPages.practices.labelSearchNPI")}</Link>
                                        </div>
                                    }
                                    <div className="form-group ">
                                        <TextInput type="text" id="npi" name="npi" label={i18n.t('userPages.facilities.labelNPI')} onValueChange={onHandleChange} className={form_error.npi ? 'input-error' : ""} required={true} value={npi} disabled={true} />
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="padding-top max-width-350">
                                        <SelectInput data={POSCodeList} label={i18n.t('userPages.facilities.labelPOS')} name="pos" id="pos" value={pos} onValueChange={selected => onHandleChange(selected)} className={form_error.pos ? 'input-error' : ""} prefixField="name" prefixSeperator=" - " menuItemClassName="max-width-800" />
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="form-group ">
                                        <TextInput type="text" id="clia" name="clia" label={i18n.t('userPages.facilities.labelCLIA')} onValueChange={onHandleChange} className={form_error.clia ? 'input-error' : ""} value={clia} />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-2">
                                    <div className="form-group ">
                                        <TextInput type="text" id="street1" name="street1" label={i18n.t('userPages.facilities.labelStreet1')} onValueChange={onHandleChange} className={form_error.street1 ? 'input-error' : ""} required={true} value={street1} />
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="form-group ">
                                        <TextInput type="text" id="street2" name="street2" label={i18n.t('userPages.facilities.labelStreet2')} onValueChange={onHandleChange} value={street2} />
                                    </div>
                                </div>
                                <div className="col-md-2">
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-2">
                                    <div className="form-group ">
                                        <CityInput type="text" id="city" name="city" label={i18n.t('userPages.facilities.labelCity')} onValueChange={onHandleChange} className={form_error.city ? 'input-error' : ""} required={true}
                                            value={city} setValue={setCity} zip={zip} />
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="form-group ">
                                    <SelectInput listItem={'US_StateList'} required={true} id="state" data={US_STATES} name="state" value={state} onValueChange={onHandleChange} label={i18n.t('patientPages.patientInfo.labelState')} className={form_error.state ? 'input-error' : ""}/>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="form-group ">
                                        <GeoCodeSearch id="zip" name="zip" className={form_error.zip ? 'input-error' : ""} required={true}
                                            label={i18n.t("customerSetup.provider.labelZip")} onValueChange={onHandleChange}
                                            setlocState={setState} setLocCity={setCity}
                                            setLocCountry={setCountry} setLocZipCode={setZIP} defaultValue={zip} />
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="form-group ">
                                        <TextInput type="text" id="country" name="country" label={i18n.t('userPages.facilities.labelCountry')} onValueChange={onHandleChange} className={form_error.country ? 'input-error' : ""} value={country} />
                                    </div>
                                </div>
                                <div className="col-md-2"></div>
                            </div>
                            <div className="row">
                                <div className="col-md-2">
                                    <div className="form-group">
                                        <PhoneInput id="phone" name="phone" label={i18n.t('userPages.facilities.labelPhone')} onValueChange={setPhone} className={form_error.phone ? 'input-error' : ""} value={phone} />
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="form-group">
                                        <PhoneInput id="fax" name="fax" label={i18n.t('userPages.facilities.labelFax')} onValueChange={setFax} className={form_error.fax ? 'input-error' : ""} value={fax} />
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="form-group padding-top3">
                                        <EmailInput id="email" name="email" label={i18n.t('userPages.facilities.labelEmail')} onValueChange={onHandleChange} value={email} />
                                    </div>
                                </div>
                                <div className="col-md-2"></div>
                            </div>
                            <div className="row">
                                <div className="col-2">
                                    <div className="form-group">
                                        <TextInput type="text" id="upin" name="upin" label={i18n.t("userPages.facilities.labelUpin")} onValueChange={onHandleChange} value={upin} />
                                    </div>
                                </div>
                                <div className="col-2">
                                    <div className={"form-group " + (!taxonomy ? "input-error" : "")}>
                                        <Multiselect value={taxonomy}
                                            onChange={handleMultiselect}
                                            multiple={false}
                                            options={mSelTaxonomyList}
                                            label={i18n.t('customerSetup.provider.labelTaxonomy')}
                                            labelPlural={i18n.t('customerSetup.provider.labelTaxonomy')}
                                        />
                                    </div>
                                </div>
                                <div className="col-2">
                                    <div className="form-group">
                                        <TextInput type="text" id="medicareID" name="medicareID" label={i18n.t("userPages.facilities.labelMedicareID")} onValueChange={onHandleChange} value={medicareID} />
                                    </div>
                                </div>
                                <div className="col-2">
                                    <div className="form-group">
                                        <TextInput type="text" id="medicaidID" name="medicaidID" label={i18n.t("userPages.facilities.labelMedicaidID")} onValueChange={onHandleChange} value={medicaidID} />
                                    </div>
                                </div>
                                <div className="col-2">
                                    <div className="form-group">
                                        <SelectInput data={stateList} label={i18n.t('userPages.facilities.labelStateLicense')} name="stateLicense" id="stateLicense" value={stateLicense} setValue={setStateLicense} onValueChange={selected => onHandleChange(selected)} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-2">
                                    <div className="form-group">
                                        <TextInput type="text" id="bcbsProviderID" name="bcbsProviderID" label={i18n.t("userPages.facilities.labelBcbsProviderID")} onValueChange={onHandleChange} value={bcbsProviderID} />
                                    </div>
                                </div>
                                <div className="col-2">
                                    <div className="form-group">
                                        <TextInput type="text" id="tricareProviderID" name="tricareProviderID" label={i18n.t("userPages.facilities.labelTricareProvideID")} onValueChange={onHandleChange} value={tricareProviderID} />
                                    </div>
                                </div>
                                <div className="col-2">
                                    <div className="form-group">
                                        <TextInput type="text" id="uhcProviderID" name="uhcProviderID" label={i18n.t("userPages.facilities.labelUhcProviderID")} onValueChange={onHandleChange} value={uhcProviderID} />
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="form-group">
                                        <TextInput type="text" id="commercialProviderID" name="commercialProviderID" label={i18n.t("userPages.facilities.labelCOmmercialProviderID")} onValueChange={onHandleChange} value={commercialProviderID} />
                                    </div>
                                </div>
                            </div>
                            {
                                editForm ?
                                    <div className="row mt-2">
                                        <div className="col-2 padding-top2">
                                            <div className="form-group">
                                                <div className="custom-checkbox">
                                                    <input type="checkbox" id="facilityInactive" name="facilityInactive" checked={inactive} onChange={(e) => changeFacilityActive(e)} />
                                                    <label className="checkbox cursor-pointer" htmlFor="facilityInactive"></label>
                                                    <label htmlFor="facilityInactive" className="chk-label cursor-pointer">{i18n.t('userPages.facilities.labelInactive')}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-8">
                                            <div className="justify-right">
                                                <button type="button" onClick={backToRefering} className="btn btn-secondary px-4 mr-3 btn-common-height35  margin-top2">{i18n.t("commons.cancel")}</button>
                                                {checkPermission(permission_key_values_accountsetup.account_setup_facility_management_modify) && 
                                                <button type="submit" className="btn btn-primary-blue btn-common-height35">{i18n.t("commons.save")}</button>}
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className="row mt-2">
                                        <div className="col-10">
                                            <div className="justify-right">
                                                <button type="button" onClick={backToRefering} className="btn btn-secondary px-4 mr-3 btn-common-height35 margin-top2">{i18n.t("commons.cancel")}</button>
                                                {checkPermission(permission_key_values_accountsetup.account_setup_facility_management_modify,permission_key_values_accountsetup.account_setup_facility_management_add) &&
                                                <button type="submit" className="btn btn-primary-blue btn-common-height35">{i18n.t("commons.save")}</button>}
                                            </div>
                                        </div>
                                    </div>
                            }
                        </Form.Group>
                    </Form>
                </div>
                <CustomizedDialogs showModal={showNPIModalWindow} type="save" header={i18n.t("userPages.practices.labelSearchNPI")} setShowModalWindow={setShowNPIModalWindow} onHide={onHideNPI} resetForm={onHideNPI} selectNPIBtn={false} hideSave={true} marginLeftValue="30px" marginTopValue="100px" >
                    <NPISearch setSelectedNPI={setSelectedNPI} searchNPIName={facilityName} providerType={PROVIDER_TYPE_ORGANIZATION} npi={npi} />
                </CustomizedDialogs>
                <CustomizedDialogs showModal={inactiveFacilityModal} setShowModalWindow={setInactiveFacilityModal} header={i18n.t("commons.confrimInactiveHeader")} type="yes"
                    inactveItem={onSaveFormData}  buttonStyle={"danger"}>
                    {i18n.t("commons.confrimInactive") + " facility?"}
                </CustomizedDialogs>
            </div>
        </div>
    )
}
