import i18n from "../../../utilities/i18n";

const commonWidth = {
    extraSmall: 'extra-small',
    small: 'small',
    medium: 'medium',
    large: 'large'
};

const tableData = [
    {
        "head": {
            name: i18n.t("reports.claim_id"),
            type: "number",
            width: commonWidth.small,
            sort: true,
            hideValue: false,
        },
        "body": {
            id: "",
            name: "custom_claim_id",
            value: "",
            type: "number",
            needLink: false,
            align: "center",
            width: commonWidth.small,
            hideValue: false,
        }
    },
    {
        "head": {
            name: i18n.t("reports.visit_id"),
            type: "string",
            width: commonWidth.small,
            sort: false,
            hideValue: false,
        },
        "body": {
            id: "",
            name: "internal_referance",
            value: "",
            type: "string",
            needLink: false,
            align: "center",
            width: commonWidth.small,
            hideValue: false,
        }
    },
    {
        "head": {
            name: i18n.t("reports.accession_number"),
            type: "number",
            width: commonWidth.small,
            sort: false,
            hideValue: false,
        },
        "body": {
            id: "",
            name: "accession_number",
            value: "",
            type: "number",
            needLink: false,
            align: "center",
            width: commonWidth.small,
            hideValue: false,
        }
    },
    
    {
        "head": {
            name: i18n.t("reports.patient_id"),
            type: "number",
            width: commonWidth.small,
            sort: true,
            hideValue: false,
        },
        "body": {
            id: "",
            name: "custom_patient_id",
            value: "",
            type: "number",
            needLink: false,
            align: "center",
            width: commonWidth.small,
            hideValue: false,
        }
    },
    {
        "head": {
            name: i18n.t("reports.firstName"),
            type: "number",
            width: commonWidth.medium,
            sort: false,
            hideValue: false,
        },
        "body": {
            id: "",
            name: "patient_first_name",
            value: "",
            type: "number",
            needLink: false,
            align: "center",
            width: commonWidth.medium,
            hideValue: false,
        }
    },
    {
        "head": {
            name: i18n.t("reports.lastName"),
            type: "number",
            width: commonWidth.medium,
            sort: false,
            hideValue: false,
        },
        "body": {
            id: "",
            name: "patient_last_name",
            value: "",
            type: "number",
            needLink: false,
            align: "center",
            width: commonWidth.medium,
            hideValue: false,
        }
    },
    {
        "head": {
            name: i18n.t("reports.DOB"),
            type: "date",
            width: commonWidth.small,
            sort: false,
            hideValue: false,
        },
        "body": {
            name: "date_of_birth",
            type: "string",
            needDateFormat: "yes",
            align: "center",
            width: commonWidth.small,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("reports.gender"),
            type: "string",
            width: commonWidth.extraSmall,
            sort: false,
            hideValue: false,
        },
        "body": {
            name: "gender",
            type: "string",
            align: "center",
            width: commonWidth.extraSmall,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("reports.patient_state"),
            type: "date",
            width: commonWidth.extraSmall,
            sort: false,
            hideValue: false,
        },
        "body": {
            name: "patient_state",
            type: "string",
            align: "center",
            width: commonWidth.extraSmall,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("reports.primary_payer_name"),
            type: "string",
            width: commonWidth.medium,
            sort: false,
            hideValue: false,
        },
        "body": {
            name: "primary_payer_name",
            type: "string",
            align: "center",
            width: commonWidth.medium,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("reports.primary_payer_id"),
            type: "string",
            width: commonWidth.small,
            sort: false,
            hideValue: false,
        },
        "body": {
            name: "primary_payer_id",
            type: "string",
            align: "center",
            width: commonWidth.small,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("reports.primary_policy_id"),
            type: "string",
            width: commonWidth.small,
            sort: false,
            hideValue: false,
        },
        "body": {
            name: "primary_policy_id",
            type: "string",
            align: "center",
            width: commonWidth.small,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("reports.primary_group_no"),
            type: "string",
            width: commonWidth.small,
            sort: false,
            hideValue: false,
        },
        "body": {
            name: "primary_group_number",
            type: "string",
            align: "center",
            width: commonWidth.small,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("reports.secondary_payer_name"),
            type: "string",
            width: commonWidth.medium,
            sort: false,
            hideValue: false,
        },
        "body": {
            name: "secondary_payer_name",
            type: "string",
            align: "center",
            width: commonWidth.medium,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("reports.secondary_payer_id"),
            type: "string",
            width: commonWidth.small,
            sort: false,
            hideValue: false,
        },
        "body": {
            name: "secondary_payer_id",
            type: "string",
            align: "center",
            width: commonWidth.small,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("reports.secondary_policy_id"),
            type: "string",
            width: commonWidth.small,
            sort: false,
            hideValue: false,
        },
        "body": {
            name: "secondary_policy_id",
            type: "string",
            align: "center",
            width: commonWidth.small,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("reports.secondary_group_no"),
            type: "string",
            width: commonWidth.small,
            sort: false,
            hideValue: false,
        },
        "body": {
            name: "secondary_group_number",
            type: "string",
            align: "center",
            width: commonWidth.small,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("reports.dateOfServiceFrom"),
            type: "date",
            width: commonWidth.medium,
            sort: true,
            hideValue: false,
        },
        "body": {
            name: "service_from_date",
            type: "string",
            needDateFormat: "yes",
            align: "center",
            width: commonWidth.medium,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("reports.dateOfServiceTo"),
            type: "date",
            width: commonWidth.medium,
            sort: true,
            hideValue: false,
        },
        "body": {
            name: "service_to_date",
            type: "string",
            needDateFormat: "yes",
            align: "center",
            width: commonWidth.medium,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("reports.cpt_charge_amount"),
            type: "number",
            width: commonWidth.small,
            hideValue: false,
        },
        "body": {
            id: "",
            name: "claim_charge_amount",
            value: "",
            type: "number",
            needLink: false,
            align: "center",
            width: commonWidth.small,
            hideValue: false,
        }
    },
    {
        "head": {
            name: i18n.t("reports.insurancePayment"),
            type: "currency",
            width: commonWidth.small,
            sort: false,
            hideValue: false,
        },
        "body": {
            name: "insurance_payments",
            type: "currency",
            align: "center",
            width: commonWidth.small,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("reports.patientPayments"),
            type: "currency",
            width: commonWidth.small,
            sort: false,
            hideValue: false,
        },
        "body": {
            name: "patient_payments",
            type: "currency",
            align: "center",
            width: commonWidth.small,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("reports.totalPayments"),
            type: "currency",
            width: commonWidth.small,
            sort: false,
            hideValue: false,
        },
        "body": {
            name: "total_payments",
            type: "currency",
            align: "center",
            width: commonWidth.small,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("reports.insuranceAdjustments"),
            type: "currency",
            width: commonWidth.small,
            sort: false,
            hideValue: false,
        },
        "body": {
            name: "insurance_adjustments",
            type: "currency",
            align: "center",
            width: commonWidth.small,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("patientPages.patientLedger.ledgerTab.writeOffs"),
            type: "currency",
            width: commonWidth.small,
            sort: false,
            hideValue: false,
        },
        "body": {
            name: "patient_write_off",
            type: "currency",
            align: "center",
            width: commonWidth.small,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("reports.totalAdjustments"),
            type: "currency",
            width: commonWidth.small,
            sort: false,
            hideValue: false,
        },
        "body": {
            name: "total_adjustments",
            type: "currency",
            align: "center",
            width: commonWidth.small,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("reports.insuranceBalance"),
            type: "currency",
            width: commonWidth.small,
            sort: false,
            hideValue: false,
        },
        "body": {
            name: "insurance_balance",
            type: "currency",
            align: "center",
            width: commonWidth.small,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("reports.patientBalance"),
            type: "currency",
            width: commonWidth.small,
            sort: false,
            hideValue: false,
        },
        "body": {
            name: "patient_balance",
            type: "currency",
            align: "center",
            width: commonWidth.small,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("reports.totalBalance"),
            type: "currency",
            width: commonWidth.small,
            sort: false,
            hideValue: false,
        },
        "body": {
            name: "total_balance",
            type: "currency",
            align: "center",
            width: commonWidth.small,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("reports.current_payer_name"),
            type: "string",
            width: commonWidth.medium,
            sort: false,
            hideValue: false,
        },
        "body": {
            name: "current_payer_name",
            type: "string",
            align: "center",
            width: commonWidth.medium,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("reports.current_payer_id"),
            type: "string",
            width: commonWidth.extraSmall,
            sort: false,
            hideValue: false,
        },
        "body": {
            name: "current_payer_id",
            type: "string",
            align: "center",
            width: commonWidth.extraSmall,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("reports.current_payer_policy_id"),
            type: "number",
            width: commonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "current_policy_id",
            value: "",
            type: "number",
            needLink: false,
            align: "center",
            width: commonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.current_group_no"),
            type: "number",
            width: commonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "current_group_number",
            value: "",
            type: "number",
            needLink: false,
            align: "center",
            width: commonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.last_paid_payer_name"),
            type: "string",
            width: commonWidth.medium,
            sort: false,
            hideValue: false,
        },
        "body": {
            name: "last_paid_payer_name",
            type: "string",
            align: "center",
            width: commonWidth.medium,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("reports.last_paid_payer_id"),
            type: "string",
            width: commonWidth.extraSmall,
            sort: false,
            hideValue: false,
        },
        "body": {
            name: "last_paid_payer_id",
            type: "string",
            align: "center",
            width: commonWidth.extraSmall,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("reports.lab_sales_rep"),
            type: "string",
            width: commonWidth.medium,
            sort: false,
            hideValue: false,
        },
        "body": {
            name: "lab_sales_rep",
            type: "string",
            align: "center",
            width: commonWidth.medium,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("reports.clinic_site_name"),
            type: "string",
            width: commonWidth.medium,
            sort: false,
            hideValue: false,
        },
        "body": {
            name: "clinic_site_name",
            type: "string",
            align: "center",
            width: commonWidth.medium,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("reports.claim_service_type"),
            type: "string",
            width: commonWidth.medium,
            sort: false,
            hideValue: false,
        },
        "body": {
            name: "claim_service_type",
            type: "string",
            align: "center",
            width: commonWidth.medium,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("arClaims.claimEntered"),
            type: "string",
            width: commonWidth.extraSmall,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "claim_entered_date",
            value: "",
            type: "string",
            needLink: false,
            align: "center",
            width: commonWidth.extraSmall,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.firstBilledDate"),
            type: "date",
            width: commonWidth.small,
            sort: false,
            hideValue: false,
        },
        "body": {
            name: "first_billed_date",
            type: "string",
            needDateFormat: "yes",
            align: "center",
            width: commonWidth.small,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("reports.lastBilledDate"),
            type: "date",
            width: commonWidth.small,
            sort: false,
            hideValue: false,
        },
        "body": {
            name: "last_billed_date",
            type: "string",
            needDateFormat: "yes",
            align: "center",
            width: commonWidth.small,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("reports.last_payment_date"),
            type: "date",
            width: commonWidth.small,
            sort: false,
            hideValue: false,
        },
        "body": {
            name: "last_payment_received_date",
            type: "string",
            needDateFormat: "yes",
            align: "center",
            width: commonWidth.small,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("reports.last_payment_entered_date"),
            type: "date",
            width: commonWidth.small,
            sort: false,
            hideValue: false,
        },
        "body": {
            name: "last_payment_entered_date",
            type: "string",
            needDateFormat: "yes",
            align: "center",
            width: commonWidth.small,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("reports.refferingProvider"),
            type: "string",
            width: commonWidth.medium,
            sort: false,
            hideValue: false,
        },
        "body": {
            name: "referring_provider_name",
            type: "string",
            align: "center",
            width: commonWidth.medium,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("reports.refferingProviderNPI"),
            type: "string",
            width: commonWidth.extraSmall,
            sort: false,
            hideValue: false,
        },
        "body": {
            name: "referring_provider_npi",
            type: "string",
            align: "center",
            width: commonWidth.extraSmall,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("reports.renderingProvider"),
            type: "string",
            width: commonWidth.medium,
            sort: false,
            hideValue: false,
        },
        "body": {
            name: "rendering_provider_name",
            type: "string",
            align: "center",
            width: commonWidth.medium,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("reports.renderingProviderNPI"),
            type: "string",
            width: commonWidth.extraSmall,
            sort: false,
            hideValue: false,
        },
        "body": {
            name: "rendering_provider_npi",
            type: "string",
            align: "center",
            width: commonWidth.extraSmall,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("reports.serviceLocation"),
            type: "string",
            width: commonWidth.medium,
            sort: false,
            hideValue: false,
        },
        "body": {
            name: "service_location",
            type: "string",
            align: "center",
            width: commonWidth.medium,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("reports.times_billed"),
            type: "string",
            width: commonWidth.extraSmall,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "number_of_times_billed",
            value: "",
            type: "string",
            needLink: false,
            align: "center",
            width: commonWidth.extraSmall,
            hideValue: true,
        }
    }
];

export const ReportTableData = {
    name: "claim-level-report-table",
    isResize: false,
    isFixedHead: true,
    tableHeadings: tableData.map(item => item.head),
    tableBodyData: [tableData.map(item => item.body)],
};