import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { initialState } from "./initialStatePaperClaim";
import service from "../services/claim_service";
import service3 from '../../dictionaries/revenueCode/service'
import { getStorage } from "../../../utilities/browserStorage";
import providerService from '../../customerSetup/providerManagement/service'
import { PAGING_END_INDEX } from "../../../utilities/staticConfigs";

/*
 * Function getDropDownListData fetches all the drop-down-data required for the page in one shot using promise chaining 
 */
export const getFilterDataSet = createAsyncThunk(
    "paperClaim/getFilterDataSet",
    async (payload, thunkAPI) => {
        const pageSize = 0;
        const page = 0;
        const practicePK = getStorage('practice');

        const promises = [
            providerService.getProvidersData(pageSize, page, practicePK, true).then(response => response.data),
            service3.GetListRevenueCode(pageSize, page).then(response => response.data),
            service.ServiceLocationLists(practicePK, null, null, true).then(response => {
                let locations = []
                response.data.map((item) => {
                    item.id = item.Id + '||' + item.location_type
                    locations.push(item)
                })
                return locations
            }),
            service.ListInsuranceNames(pageSize, 0, practicePK).then(response => response.data),
            service.ListClaimStatus(pageSize, page, practicePK).then(response => response.data),
            service.GetCPTCodeList(pageSize, page, practicePK, 'dropdown', '').then(response => response.data)
        ];
        try {
            const [
                providerList,
                revenueCodeList,
                ServiceLocationList,
                payerList,
                claimStatusList,
                cptCodesList
            ] = await Promise.all(promises);

            return {
                providerList,
                revenueCodeList,
                ServiceLocationList,
                payerList,
                claimStatusList,
                cptCodesList
            };
        } catch (error) {
            return thunkAPI.rejectWithValue({ errorMessage: 'Error getting Drop-down list data!' })
        }
    })

export const getPaperclaimData = createAsyncThunk(
    "paperClaim/paperclaimData",
    async (payload, thunkAPI) => {
        try {
            const result = await service.GetListPatientPaperClaims(payload);
            if (result.data) {
                return result.data;
            } else {
                return thunkAPI.rejectWithValue("An unexpected error occurred.");
            }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message || "An unexpected error occurred.");
        }
    }
);

export const paperclaimSlice = createSlice({
    name: "paperclaim",
    initialState,
    reducers: {
        selectEnteries: (state, actions) => {
            state.selectedEntries = actions.payload;
        },

        setCheckboxResetFlag : (state, actions) => {
            state.checkboxResetflag = actions.payload;
            if (actions.payload) {
                state.selectedEntries = [];
            }
        },

        /**
         * To make checked all claim 
         * @param {*} state 
         * @param {*} actions 
         * actions conatins @true or @false
         */
        hasSelectedAll: (state, actions) => {
            state.hasCheckedAll = actions.payload;
        },

        /**
       * Set state function to update user search query inputs
       */
        updateFilterData: (state, actions) => {
            let data = {
                ...state.searchQuery,
                [actions.payload.name]: actions.payload.value
            }

            state.searchQuery = data;
        },

        setError: (state, action) => {

            if (!state.searchQuery.errors) {
                // Ensure errors object exists
                state.searchQuery.errors = {};
            }
            state.searchQuery.errors[action.payload.name] = action.payload.error;
        },

        updateServiceLocation: (state, actions) => {
            let data = {
                ...state.searchQuery,
                serviceLocation: actions.payload.service_location,
                serviceLocationType: actions.payload.service_location_type
            }

            state.searchQuery = data;
        },

        /**
         * updating sorting function
         * @param {*} state 
         * @param {*} actions 
         */
        updateSorting: (state, actions) => {
            let data;
            if (actions.payload.name == "patient_name") {
                data = {
                    initialOrderingPatientName: false,
                    initialOrderingClaimId: true,
                    initialOrderingDos: true,
                }
            }
            else if (actions.payload.name == "claim_id") {
                data = {
                    initialOrderingPatientName: true,
                    initialOrderingClaimId: false,
                    initialOrderingDos: true,
                }
            }
            else {
                data = {
                    initialOrderingPatientName: true,
                    initialOrderingClaimId: true,
                    initialOrderingDos: false,
                }
            }

            state.sorting = data;
        },

        /**
         * reset sorting of table
         * @param {*} state 
         */
        resetSorting: (state) => {
            let data = {
                initialOrderingPatientName: true,
                initialOrderingClaimId: true,
                initialOrderingDos: true,
            }
            state.sorting = data;
        },

        /**
         * updating order type
         * @param {*} state 
         * @param {*} actions 
         */
        updateOrderType: (state, actions) => {
            state.orderType = actions.payload.order_type
        },

        updateClaimOrderType: (state, actions) => {
            state.claim_order_type = actions.payload.claim_order_type
        },
        /**
                         * pagination active page state handling
                         * @param {*} state 
                         * @param {*} actions 
                         */
        setActivePage: (state, actions) => {
            state.activePage = actions.payload.active
        },
        /**
                 * pagination next state handling
                 * @param {*} state 
                 * @param {*} actions 
                 */
        onPageNext: (state, actions) => {
            state.startIndex = actions.payload.startIndex;
            state.endIndex = actions.payload.endIndex;
        },
        /**
                 * pagination previous state handling
                 * @param {*} state 
                 * @param {*} actions 
                 */
        onPagePrevious: (state, actions) => {
            state.startIndex = actions.payload.startIndex;
            state.endIndex = actions.payload.endIndex;
        },

        /**
         * remove previous query
         * @param {*} state 
         */
        removeQuery: (state) => {
            state.previousQuery = "";
        },
        /**
                       * resetting all data
                       * @param {*} state 
                       * @param {*} actions 
                       */
        resetFiltersAndData: (state, actions) => {
            state.searchQuery = {
                dateOfServiceFrom: "",
                dateOfServiceTo: "",
                claimEnteredFrom: "",
                claimEnteredTo: "",
                claimSubmittedFrom: "",
                claimSubmittedTo: "",
                claimStatus: [],
                serviceLocation: [],
                serviceLocationType: [],
                claimType: 1,
                provider: [],
                selectServiceLocation: [],
                currentPayer: [],
                primaryPayer: [],
                secondaryPayer: [],
                payerType: [],
                selectedCptIDs: [],
                revenueCodes: [],
                icd: [],
                facility: [],
                claimAmountTotalsBetween: "",
                claimAmountTotals: "",
                claimAmountTotalsType: "",
                cpt: "",
                claimPrintType: "",
            }

            state.sorting = {
                initialOrderingPatientName: true,
                initialOrderingClaimId: true,
                initialOrderingDos: true,
            }
            state.selectedEntries = [];
            state.orderType = "";
            state.selectedClaimIDs = [];
            state.allchecked = false;
            state.claimBulkStatusUpdate = "";
            state.startIndex = 0;
            state.endIndex = PAGING_END_INDEX;
            state.activePage = 1;
            state.patientSelectedData = []
            state.patientAdvanceData = [];
            state.patient_PK = ''
            state.reseted = actions.payload.status
            state.searchButtonClicked = false;
            state.previousQuery = "";
        },

        /**
         * make data back to noraml after search action is performed
         * @param {*} state 
         * @param {*} actions 
         */
        statusUpdateSearched: (state, actions) => {
            state.sorting = {
                initialOrderingPatientName: true,
                initialOrderingClaimId: true,
                initialOrderingDos: true,
            }

            state.orderType = "";
            state.selectedClaimIDs = [];
            state.allchecked = false;
            state.claimBulkStatusUpdate = "";
            state.reseted = actions.payload.status
            state.startIndex = 0;
            state.endIndex = PAGING_END_INDEX;
            state.activePage = 1;
            state.searched = actions.payload.searched
        },

        updatePreviousQuery: (state, actions) => {
           state.previousQuery=actions.payload.query 
        },

        updateSearchButtonClicked: (state, actions) => {
            state.searchButtonClicked = actions.payload.clicked;
        },
        /**
        * storing advance search data here
        * @param {*} state 
        * @param {*} actions 
        */
        patientAdvanceSearchData: (state, actions) => {
            state.patientSelectedData = actions.payload.patient_selected;
            state.patientAdvanceData = actions.payload.patient_advance_data;
            state.patient_PK = actions.payload.patient_pk
        },
        // eslint-disable-next-line
        reset: () => initialState,

    },
    extraReducers: (builder) => {
        /**
        * Extra-reducers for api data fetch functions - (getDropDownListData)
        */
        builder.addCase(getFilterDataSet.pending, (state) => {
                state.dropDownListData.isLoading = true;
                state.dropDownListData.isErrorGettingDropdownData = false;
            })
        builder.addCase(getFilterDataSet.fulfilled, (state, action) => {
                const { providerList,
                    revenueCodeList,
                    ServiceLocationList,
                    payerList,
                    claimStatusList,
                    cptCodesList } = action.payload;
                state.dropDownListData.providerList = providerList;
                state.dropDownListData.revenueCodeList = revenueCodeList;
                state.dropDownListData.ServiceLocationList = ServiceLocationList;
                state.dropDownListData.payersList = payerList;
                state.dropDownListData.claimStatusList = claimStatusList;
                state.dropDownListData.cptCodeList = cptCodesList;
                state.dropDownListData.isLoading = false;
                state.dropDownListData.isErrorGettingDropdownData = false;
                state.isDropDownDataReceived = true
            })
        builder.addCase(getFilterDataSet.rejected, (state) => {
                state.dropDownListData.isLoading = false;
                state.dropDownListData.isErrorGettingDropdownData = true;
        });
        
        builder.addCase(getPaperclaimData.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(getPaperclaimData.fulfilled, (state, action) => {
            state.loading = false;
            state.count = action.payload.count;
            state.pageSize = action.payload.page_size;
            state.tableData=action.payload.results
            state.hasCheckedAll = action.payload.results.map(item => item.id).every(item => state.selectedEntries.includes(item));
        })
        builder.addCase(getPaperclaimData.rejected, (state) => {
            state.loading = false;
            state.tableData = []
            state.hasCheckedAll = false
        });
    }
});


export const {
    selectEnteries, hasSelectedAll, updateFilterData, updateServiceLocation, resetFiltersAndData, statusUpdateSearched,
    onPagePrevious, onPageNext, setActivePage, updateClaimOrderType, updateOrderType, updateSorting, resetSorting,
    updateSearchButtonClicked, patientAdvanceSearchData, reset, removeQuery, setCheckboxResetFlag,setError
} = paperclaimSlice.actions
export default paperclaimSlice.reducer;