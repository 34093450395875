import i18n from '../../../utilities/i18n';
import { isValidFilterConfig } from '../../../utilities/commonUtilities';
import { DEFAULT_PAGING_SIZE } from '../../../utilities/staticConfigs';


export const checkLevelReportIntialState = {
    tableData: [],
    loading: false,
    pageSize: DEFAULT_PAGING_SIZE,
    count: 0,
    totalPage: 0,
    activePage: null,
    sortField: null, // Current SortField
    orderType: null, // Current Sort Order Asc or Dsc
    filterConfig: [
        // Payment Check Date pair
        {
            fieldName: "paymentCheckDateFrom",
            type: "dateField",
            label: i18n.t("reports.filters.paymentCheckDateFrom")
        },
        {
            fieldName: "paymentCheckDateTo",
            type: "dateField",
            label: i18n.t("reports.filters.paymentCheckDateTo"),
            minDateField: "paymentCheckDateFrom"
        },

        // Payment Entered Date pair
        {
            fieldName: "paymentEnteredDateFrom",
            type: "dateField",
            label: i18n.t("reports.paymt_entered_dateRange_from")
        },
        {
            fieldName: "paymentEnteredDateTo",
            type: "dateField",
            label: i18n.t("reports.paymt_entered_dateRange_to"),
            minDateField: "paymentEnteredDateFrom"
        },

        // Payment Mode
        {
            fieldName: "paymentMode",
            type: "multiSelect",
            label: i18n.t("reports.payment_mode"),
            dropDownOptionName: "paymentModes"
        },

        // Payment From Insurance
        {
            fieldName: "paymentFromInsurance",
            type: "multiSelect",
            label: i18n.t("reports.payt_from_insurance"),
            dropDownOptionName: "paymentPayerList"
        },

        // Payment Level Adjustment Type Multi Selection
        {
            fieldName: "paymentLevelAdjustmentsList",
            type: "multiSelect",
            label: i18n.t("reports.payment_level_adjustment"),
            dropDownOptionName: "paymentAdjustmentTypesList"
        },

        // Level Adjustment Amount Currency Range Field
        {
            fieldName: 'levelAdjustmentAmount',
            label: 'Level Adjustment Amount',
            type: 'amountFilter',
            fieldPrefix: 'levelAdjustmentAmount'
        },

        // Applied Amount Currency Range Field
        {
            fieldName: 'appliedAmount',
            label: 'Applied Amount',
            type: 'amountFilter',
            fieldPrefix: 'appliedAmount',
        },

        // Un-Applied Amount Currency Range Field
        {
            fieldName: 'unAppliedAmount',
            label: 'Un-Applied Amount',
            type: 'amountFilter',
            fieldPrefix: 'unAppliedAmount',
        }

    ].map(item => {
        if (!isValidFilterConfig(item)) {
            throw new Error(`Invalid filter config for ${item.fieldName}`);
        }
        return item;
    }),
    filters: {
        // Filter input variables
        paymentCheckDateFrom: "",
        paymentCheckDateTo: "",
        paymentEnteredDateFrom: "",
        paymentEnteredDateTo: "",
        paymentMode: [],
        paymentFromInsurance: [],
        paymentLevelAdjustmentsList: [],
        levelAdjustmentAmountType: '',
        levelAdjustmentAmountFrom: '',
        levelAdjustmentAmountTo: '',
        appliedAmountType: '',
        appliedAmountFrom: '',
        appliedAmountTo: '',
        unAppliedAmountType: '',
        unAppliedAmountFrom: '',
        unAppliedAmountTo: ''
    },
    allSortFieldStates: {
        payment_id: true,
        payment_level_adjustment: true,
        payment_check_date: true,
        payment_entered_date: true,
        applied_amount: true
    },
    summaryData: null,
    isError: "not-set",
};