import React, { useEffect, useState } from 'react';
import { Popover, Typography, ListItemText, Skeleton } from '@mui/material';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import { Timeline, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot } from '@mui/lab';
import { styled } from '@mui/system';
import api from '../../service/api';

const StyledPopover = styled(Popover)(({theme}) => ({
    width: 'auto',
    borderRadius: '12px',
    marginRight: theme.spacing(2),
    '& .MuiListItemText-primary': {
        overflow: 'hidden',
    },
}));

const StyledTimelineItem = styled(TimelineItem)(() => ({
    [`&.${timelineItemClasses.root}:before`]: {
        display: 'none',
    },
    marginBottom: 0,
}));

const StyledTimelineDot = styled(TimelineDot)(({ theme }) => ({
    margin: theme.spacing(1),
}));

const StyledHeader = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold',
    position: 'sticky',
    top: 0,
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(2),
    zIndex: 11
}));


const LoadingSkeleton = () => (
    <>
        {[...Array(5)].map((_, index) => (
            <StyledTimelineItem key={index}>
                <TimelineSeparator>
                    <StyledTimelineDot />
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                    <ListItemText>
                        <Skeleton variant="text" width={150} />
                        <Skeleton variant="text" width={160} />
                    </ListItemText>
                </TimelineContent>
            </StyledTimelineItem>
        ))}
    </>
);



function ProcedureActivityView({
    procedurePk,
    openActivityView,
    anchorEl,
    onClose
}) {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const getProcedureActivityData = async () => {
        setIsLoading(true);
        const response = await api.get(`claim/procedure/activities/${procedurePk}`)
        if (response?.data) {
            setData(response.data);
        } else {
            setData([]);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        if (openActivityView)
            getProcedureActivityData();
    }, [openActivityView])

    return (
        <>
            <StyledPopover
                anchorEl={anchorEl}
                open={openActivityView}
                onClose={onClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
            >
                <StyledHeader variant="h6" gutterBottom style={{ fontSize: '14px' }}>
                    Procedure Activity Timeline
                </StyledHeader>
                <Timeline sx={{ zIndex: 10 }}>
                    {
                        isLoading ?
                            <LoadingSkeleton />
                            :
                            data.map((item) => (
                                <StyledTimelineItem key={item.id}>
                                    <TimelineSeparator>
                                        <StyledTimelineDot />
                                        <TimelineConnector />
                                    </TimelineSeparator>
                                    <TimelineContent>
                                        <ListItemText
                                            sx={{ marginTop: '-2px' }}
                                            primary={item.activity}
                                            secondary={item.date}
                                        />
                                    </TimelineContent>
                                </StyledTimelineItem>
                            ))
                    }
                </Timeline>
            </StyledPopover>
        </>
    )
}

export default ProcedureActivityView