import i18n from "../../../utilities/i18n";

const commmonWidth = {
    small: "small",
    extraSmall: "extra-small",
}

const tableData = [
    {
        "head": {
            name: i18n.t("reports.claim_id"),
            type: "number",
            width: commmonWidth.extraSmall,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "custom_claim_id",
            value: "",
            type: "number",
            needLink: true,
            align: "center",
            width: commmonWidth.extraSmall,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.accession_number"),
            type: "number",
            width: commmonWidth.extraSmall,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "accession_number",
            value: "",
            type: "number",
            needLink: true,
            align: "center",
            width: commmonWidth.extraSmall,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.patient_id"),
            type: "number",
            width: commmonWidth.extraSmall,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "custom_patient_id",
            value: "",
            type: "number",
            needLink: true,
            align: "center",
            width: commmonWidth.extraSmall,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.firstBilledDate"),
            type: "number",
            width: commmonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "first_billed_date",
            value: "",
            type: "number",
            needLink: true,
            align: "center",
            width: commmonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.lastBilledDate"),
            type: "number",
            width: commmonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "last_billed_date",
            value: "",
            type: "number",
            needLink: true,
            align: "center",
            width: commmonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.dateOfServiceFrom"),
            type: "number",
            width: commmonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "service_from_date",
            value: "",
            type: "number",
            needLink: true,
            align: "center",
            width: commmonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.dateOfServiceTo"),
            type: "number",
            width: commmonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "service_to_date",
            value: "",
            type: "number",
            needLink: true,
            align: "center",
            width: commmonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.firstName"),
            type: "number",
            width: commmonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "patient_first_name",
            value: "",
            type: "number",
            needLink: true,
            align: "center",
            width: commmonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.lastName"),
            type: "number",
            width: commmonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "patient_last_name",
            value: "",
            type: "number",
            needLink: true,
            align: "center",
            width: commmonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.middleName"),
            type: "number",
            width: commmonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "patient_middle_name",
            value: "",
            type: "number",
            needLink: true,
            align: "center",
            width: commmonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.refferingProvider"),
            type: "number",
            width: commmonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "referring_provider_name",
            value: "",
            type: "number",
            needLink: true,
            align: "center",
            width: commmonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.refferingProviderNPI"),
            type: "number",
            width: commmonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "referring_provider_npi",
            value: "",
            type: "number",
            needLink: true,
            align: "center",
            width: commmonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.renderingProvider"),
            type: "number",
            width: commmonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "rendering_provider_name",
            value: "",
            type: "number",
            needLink: true,
            align: "center",
            width: commmonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.renderingProviderNPI"),
            type: "number",
            width: commmonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "rendering_provider_npi",
            value: "",
            type: "number",
            needLink: true,
            align: "center",
            width: commmonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.billingProvider"),
            type: "number",
            width: commmonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "billing_provider_name",
            value: "",
            type: "number",
            needLink: true,
            align: "center",
            width: commmonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.billingProviderNPI"),
            type: "number",
            width: commmonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "billing_provider_npi",
            value: "",
            type: "number",
            needLink: true,
            align: "center",
            width: commmonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.serviceLocation"),
            type: "number",
            width: commmonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "service_location",
            value: "",
            type: "number",
            needLink: true,
            align: "center",
            width: commmonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.cptcode"),
            type: "number",
            width: commmonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "cpt_name",
            value: "",
            type: "number",
            needLink: true,
            align: "center",
            width: commmonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.units"),
            type: "number",
            width: commmonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "fee_units",
            value: "",
            type: "number",
            needLink: true,
            align: "center",
            width: commmonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.modifier1"),
            type: "number",
            width: commmonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "m1_name",
            value: "",
            type: "number",
            needLink: true,
            align: "center",
            width: commmonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.modifier2"),
            type: "number",
            width: commmonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "m2_name",
            value: "",
            type: "number",
            needLink: true,
            align: "center",
            width: commmonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.modifier3"),
            type: "number",
            width: commmonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "m3_name",
            value: "",
            type: "number",
            needLink: true,
            align: "center",
            width: commmonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.modifier4"),
            type: "number",
            width: commmonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "m4_name",
            value: "",
            type: "number",
            needLink: true,
            align: "center",
            width: commmonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.ICD_list"),
            type: "number",
            width: commmonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "icds",
            value: "",
            type: "number",
            needLink: true,
            align: "center",
            width: commmonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.filters.transaction_type"),
            type: "number",
            width: commmonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "transaction_type",
            value: "",
            type: "number",
            needLink: true,
            align: "center",
            width: commmonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.cpt_charge_amount"),
            type: "number",
            width: commmonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "cpt_charge_amount",
            value: "",
            type: "number",
            needLink: true,
            align: "center",
            width: commmonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("arClaims.payments"),
            type: "number",
            width: commmonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "payments",
            value: "",
            type: "number",
            needLink: true,
            align: "center",
            width: commmonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("arClaims.adjustments"),
            type: "number",
            width: commmonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "adjustments",
            value: "",
            type: "number",
            needLink: true,
            align: "center",
            width: commmonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("arClaims.patbalance"),
            type: "number",
            width: commmonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "patient_balance",
            value: "",
            type: "number",
            needLink: true,
            align: "center",
            width: commmonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.procedure_balance"),
            type: "number",
            width: commmonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "procedure_balance",
            value: "",
            type: "number",
            needLink: true,
            align: "center",
            width: commmonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.adjustment_code"),
            type: "number",
            width: commmonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "adjustment_code",
            value: "",
            type: "number",
            needLink: true,
            align: "center",
            width: commmonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("patientPages.patientLedger.creditBalanceTab.transactionDate"),
            type: "number",
            width: commmonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "transaction_date",
            value: "",
            type: "number",
            needLink: true,
            align: "center",
            width: commmonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.currentPayerType"),
            type: "number",
            width: commmonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "current_resp_payer_type",
            value: "",
            type: "number",
            needLink: true,
            align: "center",
            width: commmonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.current_payer_name"),
            type: "number",
            width: commmonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "current_payer_name",
            value: "",
            type: "number",
            needLink: true,
            align: "center",
            width: commmonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.current_payer_id"),
            type: "number",
            width: commmonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "current_payer_id",
            value: "",
            type: "number",
            needLink: true,
            align: "center",
            width: commmonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.current_payer_policy_id"),
            type: "number",
            width: commmonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "current_policy_id",
            value: "",
            type: "number",
            needLink: true,
            align: "center",
            width: commmonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.current_group_no"),
            type: "number",
            width: commmonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "current_group_number",
            value: "",
            type: "number",
            needLink: true,
            align: "center",
            width: commmonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.paid_payer_name"),
            type: "number",
            width: commmonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "paid_payer_name",
            value: "",
            type: "number",
            needLink: true,
            align: "center",
            width: commmonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.paid_payer_id"),
            type: "number",
            width: commmonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "paid_payer_id",
            value: "",
            type: "number",
            needLink: true,
            align: "center",
            width: commmonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.primary_payer_name"),
            type: "number",
            width: commmonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "primary_payer_name",
            value: "",
            type: "number",
            needLink: true,
            align: "center",
            width: commmonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.primary_payer_id"),
            type: "number",
            width: commmonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "primary_payer_id",
            value: "",
            type: "number",
            needLink: true,
            align: "center",
            width: commmonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.primary_policy_id"),
            type: "number",
            width: commmonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "primary_policy_id",
            value: "",
            type: "number",
            needLink: true,
            align: "center",
            width: commmonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.primary_group_no"),
            type: "number",
            width: commmonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "primary_group_number",
            value: "",
            type: "number",
            needLink: true,
            align: "center",
            width: commmonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.secondary_payer_name"),
            type: "number",
            width: commmonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "secondary_payer_name",
            value: "",
            type: "number",
            needLink: true,
            align: "center",
            width: commmonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.secondary_payer_id"),
            type: "number",
            width: commmonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "secondary_payer_id",
            value: "",
            type: "number",
            needLink: true,
            align: "center",
            width: commmonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.secondary_policy_id"),
            type: "number",
            width: commmonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "secondary_policy_id",
            value: "",
            type: "number",
            needLink: true,
            align: "center",
            width: commmonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.secondary_group_no"),
            type: "number",
            width: commmonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "secondary_group_number",
            value: "",
            type: "number",
            needLink: true,
            align: "center",
            width: commmonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.ageFromDos"),
            type: "number",
            width: commmonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "age_from_dos",
            value: "",
            type: "number",
            needLink: true,
            align: "center",
            width: commmonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.ageFromDateofEntry"),
            type: "number",
            width: commmonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "age_from_date_of_entry",
            value: "",
            type: "number",
            needLink: true,
            align: "center",
            width: commmonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.agefromfirstbillDate"),
            type: "number",
            width: commmonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "age_from_first_billed_date",
            value: "",
            type: "number",
            needLink: true,
            align: "center",
            width: commmonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.agefromlstbillDate"),
            type: "number",
            width: commmonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "age_from_last_billed_date",
            value: "",
            type: "number",
            needLink: true,
            align: "center",
            width: commmonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.claimStatus"),
            type: "number",
            width: commmonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "claim_status",
            value: "",
            type: "number",
            needLink: true,
            align: "center",
            width: commmonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.claimSubStatus"),
            type: "number",
            width: commmonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "claim_sub_status",
            value: "",
            type: "number",
            needLink: true,
            align: "center",
            width: commmonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.entered_by"),
            type: "number",
            width: commmonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "entered_by",
            value: "",
            type: "number",
            needLink: true,
            align: "center",
            width: commmonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.check_number"),
            type: "number",
            width: commmonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "check_number",
            value: "",
            type: "number",
            needLink: true,
            align: "center",
            width: commmonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.lab_sales_rep"),
            type: "number",
            width: commmonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "lab_sales_report",
            value: "",
            type: "number",
            needLink: true,
            align: "center",
            width: commmonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.clinic_site_name"),
            type: "number",
            width: commmonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "clinic_site_name",
            value: "",
            type: "number",
            needLink: true,
            align: "center",
            width: commmonWidth.small,
            hideValue: true,
        }
    },
    {
        "head": {
            name: i18n.t("reports.claim_service_type"),
            type: "number",
            width: commmonWidth.small,
            sort: false,
            hideValue: true,
        },
        "body": {
            id: "",
            name: "claim_service_type",
            value: "",
            type: "number",
            needLink: true,
            align: "center",
            width: commmonWidth.small,
            hideValue: true,
        }
    }
]

export const ReportTableData = {
    "name": "dynamicTable",
    "isResize": false,
    "isReportTable": true,
    "isFixedHead": true,
    "tableHeadings": tableData.map(item => item.head),
    "tableBodyData": [tableData.map(item => item.body)]
}